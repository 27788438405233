import { useEffect, useState } from 'react';
import { useFirestore } from 'reactfire';
import { doc, onSnapshot, Timestamp } from 'firebase/firestore';
import { Patient } from './firebaseModels';
import useUserProvider from './useUserProvider';
import { useFirebaseUser } from './useFirebaseUser';
import { FirebaseError } from 'firebase/app';

type TPatientDocData = {
  status: 'loading' | 'success' | 'error';
  error: Error | null;
  data: Patient | null;
};

/**
 * Custom hook for getting a Patient from Firestore.
 * @param id The ID of the Patient to fetch.
 * @returns TPatientData
 */
export default function usePatientDoc(id: string): TPatientDocData {
  const firestore = useFirestore();

  const { email } = useFirebaseUser();

  const { data: userProviderData } = useUserProvider();

  const [status, setStatus] = useState<TPatientDocData['status']>('loading');
  const [data, setData] = useState<TPatientDocData['data']>(null);
  const [error, setError] = useState<TPatientDocData['error']>(null);
  const [nTimeouts, setNTimeouts] = useState(0);
  useEffect(() => {
    if (!userProviderData?.id || !email) {
      return;
    }

    if (id === 'new') {
      setStatus('success');
      setData({
        licenceCode: null,
        remark: '',
        user: null,
        createdBy: email,
        created: Timestamp.now(),
        modifiedBy: email,
        modified: Timestamp.now(),
        approved: false,
      });
      return;
    }

    const patientRef = doc(
      firestore,
      'ServiceProvider',
      userProviderData.id,
      'UserData',
      email,
      'Patient',
      id
    );
    const unsubscribe = onSnapshot(
      patientRef,
      (doc) => {
        setNTimeouts(0);
        if (doc.exists()) {
          setData({ ...doc.data(), id: doc.id } as Patient);
          setStatus('success');
        } else {
          setStatus('error');
          setError(new Error('Patient not found'));
        }
      },
      (error) => {
        setStatus('error');
        setError(error as FirebaseError);
        setData(null);

        console.error('Error getting Patient snapshot', error);
        // Incremental backoff for retrying
        const retryDelay = 1000 * (nTimeouts + 1);
        setTimeout(() => {
          // console.log('usePatientDoc retrying after delay');
          setNTimeouts((prevTimeouts) => prevTimeouts + 1);
        }, retryDelay);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [email, firestore, id, userProviderData?.id, nTimeouts]);

  return { status, error, data };
}
