import { useTranslation } from 'react-i18next';
import { TaskPoolTask } from '../firebase/firebaseModels';
import { getTranslatableField } from '../services/i18n';

export function useFormatMarking() {
  const { t } = useTranslation();

  const formatMarkingAsText = (marking: any, task: TaskPoolTask | null) => {
    let text = '';

    // Add task title
    if (task) {
      text += `${getTranslatableField(task.title)}\n`;
    }

    // Add total points and secondary points if available
    if (marking.totalPoints !== undefined) {
      text += `${t('Total Points')}: ${marking.totalPoints}/${
        marking.totalMaxPoints
      } ${t('pts')}\n`;

      // Only show average for range questions
      const answers = Object.values(marking.answers || {});
      const calculatableAnswers = answers.filter((answer: any) =>
        ['radio', 'range', 'checkbox'].includes(answer.type)
      );
      const allRangeQuestions = calculatableAnswers.every(
        (answer: any) => answer.type === 'range'
      );

      if (allRangeQuestions && calculatableAnswers.length > 0) {
        text += `${t('Average')}: ${(
          marking.totalPoints / calculatableAnswers.length
        ).toFixed(1)}/${(
          marking.totalMaxPoints / calculatableAnswers.length
        ).toFixed(1)} ${t('pts')}\n`;
      }
    }

    // Add completion date/time
    text += `\n${t(
      'Date'
    )}: ${marking.completedTime.toLocaleDateString()} ${marking.completedTime.toLocaleTimeString()}\n\n`;

    // Process sections in original order to maintain questionnaire flow
    if (marking.answers && task?.sections) {
      task.sections.forEach((section) => {
        // Add section title if it exists
        if (section.title) {
          text += `${getTranslatableField(section.title)}\n`;
        }

        // Handle checkbox sections specially to show both checked and unchecked items
        if (section.type === 'checkbox' && section.questions) {
          section.questions.forEach((question) => {
            const answerId = `${section.id}:${question.id}`;
            const answer = marking.answers[answerId];
            const questionTitle = getTranslatableField(question.title);
            text += `${questionTitle}: ${answer?.value ? t('Yes') : t('No')}\n`;
          });
          text += '\n';
          return;
        }

        // Get section answers
        const sectionAnswers = Object.entries(marking.answers)
          .filter(([key]) => key.startsWith(section.id))
          .map(([key, value]: [string, any]) => ({ ...value, id: key }));

        if (sectionAnswers.length === 0) return;

        // Sort answers appropriately
        const shouldSort =
          sectionAnswers[0]?.type === 'range' ||
          sectionAnswers[0]?.type === 'checkbox';
        const sortedAnswers = shouldSort
          ? [...sectionAnswers].sort((a, b) => (b.value || 0) - (a.value || 0))
          : sectionAnswers.sort((a, b) => {
              const [, aQuestionId] = a.id.split(':');
              const [, bQuestionId] = b.id.split(':');
              return aQuestionId.localeCompare(bQuestionId);
            });

        sortedAnswers.forEach((answer) => {
          const questionTitle = getTranslatableField(answer.title);
          const answerText = answer.answerTitle
            ? getTranslatableField(answer.answerTitle)
            : '';

          switch (answer.type) {
            case 'question':
              text += `${answer.value || t('-')}\n\n`;
              break;
            case 'checkbox':
              text += `${questionTitle}: ${
                answer.value ? t('Yes') : t('No')
              }\n`;
              break;
            case 'radio':
              text += `${questionTitle}\n${answerText} (${answer.value}/${
                answer.maxValue
              } ${t('pts')})\n\n`;
              break;
            case 'range':
              text += `${questionTitle}: ${answer.value}/${answer.maxValue} ${t(
                'pts'
              )}\n`;
              break;
          }
        });

        text += '\n';
      });
    }

    return text;
  };

  return { formatMarkingAsText };
} 