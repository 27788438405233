import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirestore } from 'reactfire';
import {
  doc,
  DocumentData,
  DocumentReference,
  onSnapshot,
} from 'firebase/firestore';
import { PortalUser, ServiceProvider } from '../firebase/firebaseModels';
import { useFirebaseUser } from '../firebase/useFirebaseUser';
import { FirebaseError } from 'firebase/app';

type TUserDataStatus = 'loading' | 'error' | 'success' | 'not-logged-in';

export type TUserData = {
  status: TUserDataStatus;
  error: Error | null;
  data: PortalUser | null;
  providerRef: DocumentReference<ServiceProvider, DocumentData> | null;
  isSuperAdmin: boolean;
  isOrganizationAdmin: boolean;
  isUser: boolean;
  accessAllowed: boolean;
  userRef: DocumentReference<PortalUser, DocumentData> | null;
};

export const PortalUserContext = createContext<TUserData | null>(null);

export function PortalUserProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { i18n } = useTranslation();
  const { email } = useFirebaseUser();

  const firestore = useFirestore();

  const [status, setStatus] = useState<TUserDataStatus>('loading');
  const [data, setData] = useState<PortalUser | null>(null);
  const [error, setError] = useState<FirebaseError | null>(null);
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
  const [isOrganizationAdmin, setIsOrganizationAdmin] =
    useState<boolean>(false);
  const [isUser, setIsUser] = useState<boolean>(false);
  const [userRef, setUserRef] = useState<DocumentReference<
    PortalUser,
    DocumentData
  > | null>(null);
  const [providerRef, setProviderRef] = useState<DocumentReference<
    ServiceProvider,
    DocumentData
  > | null>(null);
  const [nTimeouts, setNTimeouts] = useState(0);

  const currentLanguage = i18n.language;
  const userLanguage = useMemo(() => data?.language || null, [data]);

  useEffect(() => {
    if (userLanguage && userLanguage !== currentLanguage) {
      i18n.changeLanguage(userLanguage);
    }
  }, [currentLanguage, i18n, userLanguage]);

  useEffect(() => {
    if (!email) {
      setData(null);
      setProviderRef(null);
      setIsSuperAdmin(false);
      setIsOrganizationAdmin(false);
      setIsUser(false);
      setUserRef(null);
      setStatus('not-logged-in');
      setError(null);
      return;
    }

    const portalUserRef = doc(firestore, 'PortalUser', email.toLowerCase());

    const unsubscribe = onSnapshot(
      portalUserRef,
      (doc) => {
        setNTimeouts(0);
        if (doc.exists()) {
          const portalUserData = {
            ...doc.data(),
            id: doc.id,
          } as PortalUser;
          const isSuperAdmin = portalUserData.level === 'superadmin';
          const isOrganizationAdmin = portalUserData.level === 'admin';
          const isUser = portalUserData.level === 'user';
          const providerRef = portalUserData.provider;
          setData(portalUserData);
          setProviderRef(providerRef || null);
          setIsSuperAdmin(isSuperAdmin);
          setIsOrganizationAdmin(isOrganizationAdmin);
          setIsUser(isUser);
          setUserRef(
            portalUserRef as DocumentReference<PortalUser, DocumentData>
          );
          setStatus('success');
        } else {
          setData(null);
          setProviderRef(null);
          setIsSuperAdmin(false);
          setIsOrganizationAdmin(false);
          setIsUser(false);
          setUserRef(null);

          setStatus('error');
          setError(new FirebaseError('User not found', 'not-found'));
        }
      },
      (error) => {
        setStatus('error');
        setError(error);
        setData(null);

        console.error('Error getting PortalUser snapshot', error);
        // Incremental backoff for retrying
        const retryDelay = 1000 * (nTimeouts + 1);
        setTimeout(() => {
          // console.log('PortalUserProvider retrying after delay');
          setNTimeouts((prevTimeouts) => prevTimeouts + 1);
        }, retryDelay);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [email, firestore, nTimeouts]);

  const value = {
    status,
    error,
    data,
    providerRef,
    isSuperAdmin,
    isOrganizationAdmin,
    isUser,
    userRef,
    accessAllowed:
      status === 'success' &&
      !!data &&
      (isSuperAdmin || isOrganizationAdmin || isUser),
  };

  return (
    <PortalUserContext.Provider value={value}>
      {children}
    </PortalUserContext.Provider>
  );
}
