import * as React from 'react';
import TocIcon from '@mui/icons-material/Toc';
import SettingsIcon from '@mui/icons-material/Settings';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import GrassIcon from '@mui/icons-material/Grass';
import PersonIcon from '@mui/icons-material/Person';
import SpaIcon from '@mui/icons-material/Spa';
import SpaOutlinedIcon from '@mui/icons-material/SpaOutlined';
import CategoryIcon from '@mui/icons-material/Category';
import FrontPage, { FrontPageWaiting } from '../pages/FrontPage';
import Settings from '../pages/Settings';
import Patients from '../pages/Patients';
import TaskPoolTaskEditor from '../pages/TaskPoolTaskEditor';
import TaskPoolTaskCategoryEditor from '../pages/TaskPoolTaskCategoryEditor';
import PatientEditor from '../pages/PatientEditor';
import TherapyPlanEditor from '../pages/TherapyPlanEditor';
import Statistics from '../pages/Statistics';
import TaskPoolTabs from '../pages/TaskPoolTabs';
import TaskPoolTaskCategoriesTabs from '../pages/TaskPoolTaskCategoriesTabs';
import TherapyCourseOverview from '../pages/TherapyCourseOverview';
import { Trans } from 'react-i18next';
import TherapyPlanTabs from '../pages/TherapyPlanTabs';
import TherapyCoursesAll from '../pages/TherapyCoursesAll';
import TherapyCourseEditor from '../pages/TherapyCourseEditor';
import AddCardIcon from '@mui/icons-material/AddCard';
import GenerateCourse from '../pages/GenerateCourse';
import PortalUsers from '../pages/PortalUsers';
import PortalUserEditor from '../pages/PortalUserEditor';
import usePortalUser from '../firebase/usePortalUser';
import { useMemo } from 'react';
import DeleteAppUser from '../pages/DeleteAppUser';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

export type TabData = { label: string; path: string; type: string };

export const TASKPOOL_TABS: TabData[] = [
  { label: 'Standard', path: '/admin/taskpool/default', type: 'default' },
  {
    label: 'My Clinic',
    path: '/admin/taskpool/organization',
    type: 'organization',
  },
  { label: 'My Own', path: '/admin/taskpool/own', type: 'own' },
];

export const TASKCATEGORIES_TABS: TabData[] = [
  { label: 'Standard', path: '/admin/taskcategories/default', type: 'default' },
  {
    label: 'My Clinic',
    path: '/admin/taskcategories/organization',
    type: 'organization',
  },
  { label: 'My Own', path: '/admin/taskcategories/own', type: 'own' },
];

export const THERAPYPLANS_TABS: TabData[] = [
  { label: 'Standard', path: '/admin/therapyplans/default', type: 'default' },
  {
    label: 'My Clinic',
    path: '/admin/therapyplans/organization',
    type: 'organization',
  },
  { label: 'My Own', path: '/admin/therapyplans/own', type: 'own' },
];
export interface CrumbData {
  therapyCourseId?: string;
  patientId?: string;
  therapyPlanId?: string;
  taskpooltype?: string;
  taskPoolTaskId?: string;
  taskPoolTaskCategoryId?: string;
  taskcategorytype?: string;
  therapyCourseTitle?: string;
  patientTitle?: string;
  taskPoolTaskTitle?: string;
  taskPoolTaskCategoryTitle?: string;
  therapyPlanTitle?: string;
  therapyplantype?: string;
}

export type AppRoute = {
  path?: string;
  icon?: React.ReactNode;
  label?: string;
  Component?: React.ComponentType<any>;
  exact?: boolean;
  tabs?: TabData[];
  children?: AppRoute[];
  crumb?: (data: CrumbData) => React.ReactNode;
  type: 'top-level' | 'sub-level' | 'divider' | 'tabs' | 'collapse';
};

export const appRoutes: AppRoute[] = [
  {
    path: '/',
    type: 'collapse',
    label: 'Therapy Courses',
    children: [
      {
        path: 'therapycourseoverview',
        icon: <NewspaperIcon />,
        label: 'Current Courses',
        Component: FrontPage,
        exact: true,
        type: 'top-level',
        crumb: () => <Trans>Current Courses</Trans>,
        children: [
          {
            path: '/:therapyCourseId',
            Component: TherapyCourseOverview,
            label: 'Session View',
            exact: true,
            type: 'sub-level',
            crumb: (data: CrumbData) => (
              <>
                <Trans>Session View</Trans>
                {` ${data.therapyCourseTitle}`}
              </>
            ),
          },
          {
            path: '/:therapyCourseId/:patientId',
            Component: TherapyCourseOverview,
            label: 'Session View',
            exact: true,
            type: 'sub-level',
            crumb: (data: CrumbData) => (
              <>
                <Trans>Session View</Trans>
                {` ${data.therapyCourseTitle}`}
              </>
            ),
          },
        ],
      },
      {
        path: 'therapycoursenew',
        label: 'Create New Course',
        icon: <AddCardIcon />,
        Component: GenerateCourse,
        exact: true,
        type: 'top-level',
        crumb: () => <Trans>Create New Course</Trans>,
      },
      {
        path: 'therapycoursewaiting',
        icon: <SpaOutlinedIcon />,
        label: 'Waiting to Start',
        Component: FrontPageWaiting,
        exact: true,
        type: 'top-level',
        crumb: () => <Trans>Waiting to Start</Trans>,
        children: [
          {
            path: '/:therapyCourseId',
            Component: TherapyCourseOverview,
            label: 'Session View',
            exact: true,
            type: 'sub-level',
            crumb: (data: CrumbData) => (
              <>
                <Trans>Session View</Trans>
                {` ${data.therapyCourseTitle}`}
              </>
            ),
          },
          {
            path: '/:therapyCourseId/:patientId',
            Component: TherapyCourseOverview,
            label: 'Session View',
            exact: true,
            type: 'sub-level',
            crumb: (data: CrumbData) => (
              <>
                <Trans>View</Trans>
                {` ${data.therapyCourseTitle}`}
              </>
            ),
          },
        ],
      },
      {
        path: 'therapycourses',
        label: 'All Therapies',
        icon: <SpaIcon />,
        Component: TherapyCoursesAll,
        exact: true,
        type: 'top-level',
        crumb: () => <Trans>All Therapies</Trans>,
        children: [
          {
            path: '/:therapyCourseId',
            Component: TherapyCourseEditor,
            label: 'Edit',
            exact: true,
            type: 'sub-level',
            crumb: (data: CrumbData) => (
              <>
                <Trans>Edit Therapy Course</Trans>
                {` ${data.therapyCourseTitle}`}
              </>
            ),
            children: [
              {
                path: '/patients/:patientId/statistics',
                Component: Statistics,
                exact: true,
                label: 'Statistics',
                type: 'sub-level',
                crumb: (data: CrumbData) => (
                  <>
                    <Trans>Statistics</Trans>
                  </>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'collapse',
    path: '/admin',
    label: 'Administration',
    children: [
      {
        path: '/patients',
        label: 'Patients',
        icon: <PersonIcon />,
        Component: Patients,
        exact: true,
        type: 'top-level',
        crumb: () => <Trans>Patients</Trans>,
        children: [
          {
            path: '/:patientId',
            Component: PatientEditor,
            label: 'Edit',
            exact: true,
            type: 'sub-level',
            crumb: (data: CrumbData) => <>{data.patientTitle}</>,
            children: [
              {
                path: '/statistics',
                Component: Statistics,
                label: 'Statistics',
                exact: true,
                type: 'sub-level',
                crumb: () => <Trans>Statistics</Trans>,
              },
            ],
          },
        ],
      },
      {
        type: 'divider',
        Component: () => null,
      },
      {
        path: '/therapyplans',
        label: 'Therapy Plans',
        icon: <GrassIcon />,
        Component: TherapyPlanTabs,
        exact: true,
        type: 'top-level',
        crumb: () => <Trans>Therapy Plans</Trans>,
        children: [
          {
            path: '/:therapyplantype',
            label: 'Therapy Plans',
            icon: <TocIcon />,
            Component: TherapyPlanTabs,
            exact: true,
            tabs: THERAPYPLANS_TABS,
            type: 'tabs',
            crumb: (data: CrumbData) => {
              const tab = THERAPYPLANS_TABS.find(
                (tab) => tab.type === data.therapyplantype
              );
              return <Trans>{tab?.label}</Trans>;
            },
            children: [
              {
                path: '/:therapyPlanId',
                Component: TherapyPlanEditor,
                exact: true,
                label: 'Edit',
                type: 'sub-level',
                crumb: (data: CrumbData) => <>{data.therapyPlanTitle}</>,
                children: [
                  {
                    path: '/:idToClone',
                    Component: TherapyPlanEditor,
                    exact: true,
                    label: 'Duplicate',
                    type: 'sub-level',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: 'divider',
        Component: () => null,
      },
      {
        path: '/taskpool',
        label: 'Task Pool',
        icon: <TocIcon />,
        Component: TaskPoolTabs,
        exact: true,
        type: 'top-level',
        crumb: () => <Trans>Task Pool</Trans>,
        children: [
          {
            path: '/:taskpooltype',
            label: 'Task Pool',
            icon: <TocIcon />,
            Component: TaskPoolTabs,
            exact: true,
            tabs: TASKPOOL_TABS,
            type: 'tabs',
            crumb: (data: CrumbData) => {
              const tab = TASKPOOL_TABS.find(
                (tab) => tab.type === data.taskpooltype
              );
              return <Trans>{tab?.label}</Trans>;
            },
            children: [
              {
                path: '/:taskPoolTaskId',
                Component: TaskPoolTaskEditor,
                label: 'Edit',
                exact: true,
                type: 'sub-level',
                crumb: (data: CrumbData) => <>{data.taskPoolTaskTitle}</>,
                children: [
                  {
                    path: '/:idToClone',
                    Component: TaskPoolTaskEditor,
                    exact: true,
                    label: 'Duplicate',
                    type: 'sub-level',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '/taskcategories',
        label: 'Task Categories',
        icon: <CategoryIcon />,
        Component: TaskPoolTaskCategoriesTabs,
        exact: true,
        type: 'top-level',
        crumb: () => <Trans>Task Categories</Trans>,
        children: [
          {
            path: '/:taskcategorytype',
            label: 'Task Categories',
            icon: <TocIcon />,
            Component: TaskPoolTaskCategoriesTabs,
            exact: true,
            tabs: TASKCATEGORIES_TABS,
            type: 'tabs',
            crumb: (data: CrumbData) => {
              const tab = TASKCATEGORIES_TABS.find(
                (tab) => tab.type === data.taskcategorytype
              );
              return <Trans>{tab?.label}</Trans>;
            },
            children: [
              {
                path: '/:taskPoolTaskCategoryId',
                Component: TaskPoolTaskCategoryEditor,
                exact: true,
                label: 'Edit',
                type: 'sub-level',
                crumb: (data: CrumbData) => (
                  <>{data.taskPoolTaskCategoryTitle}</>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'collapse',
    path: '/settings',
    label: 'Settings',
    children: [
      {
        path: '/userprofile',
        label: 'User Profile',
        icon: <SettingsIcon />,
        Component: Settings,
        exact: true,
        type: 'top-level',
        crumb: () => <Trans>Settings</Trans>,
      },
      {
        path: '/portalusers',
        label: 'Portal Users',
        icon: <PersonIcon />,
        Component: PortalUsers,
        exact: true,
        type: 'top-level',
        crumb: () => <Trans>Portal Users</Trans>,
        children: [
          {
            path: '/:portalUserId',
            Component: PortalUserEditor,
            label: 'Edit',
            exact: true,
            type: 'sub-level',
            crumb: () => <Trans>Edit User</Trans>,
          },
        ],
      },
      {
        path: '/deleteappuser',
        label: 'Delete App User',
        icon: <PersonRemoveIcon />,
        Component: DeleteAppUser,
        exact: true,
        type: 'top-level',
        crumb: () => <Trans>Delete App User</Trans>,
      },
    ],
  },
];

export function useDashboardRoutes() {
  const { isSuperAdmin, isOrganizationAdmin, status } = usePortalUser();

  const showPortalUsers = useMemo(
    () => isSuperAdmin || isOrganizationAdmin,
    [isSuperAdmin, isOrganizationAdmin]
  );

  const routes = useMemo(() => {
    // Return null if status is not 'success'
    if (status !== 'success') {
      return null;
    }

    const baseRoutes = [...appRoutes];

    // Find the settings section
    const settingsSection = baseRoutes.find(
      (route) => route.path === '/settings'
    );

    if (settingsSection && settingsSection.children) {
      // Filter out Portal Users if user doesn't have permission
      settingsSection.children = settingsSection.children.filter(
        (route) =>
          (route.path !== '/portalusers' || showPortalUsers) &&
          (route.path !== '/deleteappuser' || isSuperAdmin)
      );
    }

    return baseRoutes;
  }, [showPortalUsers, status, isSuperAdmin]);

  return routes;
}
