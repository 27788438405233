import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useState, useCallback } from 'react';
import { useStorage } from 'reactfire';
import useUserProvider from '../firebase/useUserProvider';
import usePortalUser from '../firebase/usePortalUser';

export function useUpload(
  file: File | null,
  basePath: string,
  onError?: (error: Error) => void
) {
  const [publicUrl, setPublicUrl] = useState<string | null>(null);

  const storage = useStorage();
  const { data: userProviderData } = useUserProvider();
  const { data: portalUserData } = usePortalUser();

  const uploadFile = useCallback(
    (callback?: (url: string | null) => void) => {
      if (!userProviderData?.id || !portalUserData?.adminUid) {
        const error = new Error('Upload failed: Missing user data');
        onError?.(error);
        console.warn(error.message);
        return;
      }

      if (file) {
        const imageRef = ref(
          storage,
          `${userProviderData.id}/${portalUserData?.adminUid}/${basePath}/${file.name}`
        );
        const metadata = {
          contentType: file.type,
        };
        uploadBytes(imageRef, file, metadata)
          .then((snapshot) => {
            getDownloadURL(snapshot.ref)
              .then((url) => {
                setPublicUrl(url);
                callback && callback(url);
              })
              .catch((err) => {
                onError?.(err);
                console.error('Failed to get download URL:', err);
                callback && callback(null);
              });
          })
          .catch((err) => {
            onError?.(err);
            console.error(`Upload failed for ${file.name}:`, err);
            callback && callback(null);
          });
      } else {
        console.warn('Upload skipped: No file provided');
        callback && callback(null);
      }
    },
    [userProviderData, portalUserData, file, storage, basePath, onError]
  );

  return { publicUrl, uploadFile };
}
