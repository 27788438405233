import { Trans, useTranslation } from 'react-i18next';
import { Box, CircularProgress, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Alert from '@mui/material/Alert';
import ErrorIcon from '@mui/icons-material/Error';
import useRequestToViewPatientData from '../firebase/useRequestToViewUserData';
import { GridActionsCellItem } from '@mui/x-data-grid';
import ChartIcon from '@mui/icons-material/TableChart';
import { GridStateColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import RequestAccessIcon from '@mui/icons-material/Key';
import { useNavigate } from 'react-router-dom';
import { useFirebaseUser } from '../firebase/useFirebaseUser';
import { useRefreshPatientStatistics } from '../hooks/useRefreshPatientStatistics';
import useStatisticsCacheDoc from '../firebase/useStatisticsCacheDoc';
import Tooltip from '@mui/material/Tooltip';
import { usePatient, useAppUser } from '../contexts/PatientContext';

type TherapyCoursePatientColumnProps = {
  colDef?: GridStateColDef;
  therapyCoursePatientId: string | null;
  therapyCourseId: string;
  type: string;
};

export default function TherapyCoursePatientColumn(
  props: TherapyCoursePatientColumnProps
) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { therapyCourseId, therapyCoursePatientId, type, colDef } = props;

  const patient = usePatient(therapyCoursePatientId);
  const appUser = useAppUser(patient);

  const [confirmAccessRequestDialogOpen, setConfirmAccessRequestDialogOpen] =
    useState(false);

  const { email } = useFirebaseUser();

  const { sendRequest, requestSent, requestError, approved, denied } =
    useRequestToViewPatientData(patient, email);

  const startDate = null;
  const endDate = null;

  const {
    refreshPatientStatistics,
    disabled: refreshDisabled,
    isUpdating,
    statisticsHash,
  } = useRefreshPatientStatistics(therapyCoursePatientId, startDate, endDate);

  useEffect(() => {
    if (type === 'sleepEfficiency' && !refreshDisabled && approved) {
      // hack to limit the number of calls to refreshPatientStatistics
      refreshPatientStatistics(false);
    }
  }, [approved, refreshDisabled, refreshPatientStatistics, type]);

  const { data: statisticsCache, error: statisticsCacheError } =
    useStatisticsCacheDoc(statisticsHash, patient?.user?.id || null);

  const { sleepEfficiency, taskCompletionPercentage, statisticsCacheUpdating } =
    useMemo(() => {
      if (statisticsCache?.statistics) {
        const nStatistics = statisticsCache.statistics.length;
        const lastStatisticDate = statisticsCache.statistics[nStatistics - 1];
        const {
          sleep_efficiency_moving_average,
          percantage_completed_tasks_moving_average,
        } = lastStatisticDate;
        const sleepEfficiency = sleep_efficiency_moving_average
          ? sleep_efficiency_moving_average.toFixed(0) + ' %'
          : '';
        const taskCompletionPercentage =
          percantage_completed_tasks_moving_average
            ? percantage_completed_tasks_moving_average.toFixed(0) + ' %'
            : '';
        const statisticsCacheUpdating =
          appUser?.statistics_cache_updating || false;
        return {
          sleepEfficiency,
          taskCompletionPercentage,
          statisticsCacheUpdating,
        };
      }
      return {
        sleepEfficiency: '',
        taskCompletionPercentage: '',
      };
    }, [appUser?.statistics_cache_updating, statisticsCache?.statistics]);

  const handleConfirmAccessRequestDialogClose = useCallback(() => {
    setConfirmAccessRequestDialogOpen(false);
  }, []);

  const handleConfirmAccessRequestDialogOpen = useCallback(() => {
    setConfirmAccessRequestDialogOpen(true);
  }, []);

  const handleConfirmAccessRequest = useCallback(() => {
    sendRequest();
    setConfirmAccessRequestDialogOpen(false);
  }, [sendRequest]);

  const computedWidth = useMemo(() => {
    const { computedWidth } = colDef || {};
    return computedWidth;
  }, [colDef]);

  const notApprovedInfo = useMemo(
    () =>
      !approved ? (
        denied ? (
          <Typography
            variant="body2"
            color="error"
            sx={{ width: computedWidth, display: 'inline-block' }}
          >
            <Trans>Access denied</Trans>
          </Typography>
        ) : requestSent ? (
          <Typography
            variant="body2"
            color={requestError ? 'error' : 'textSecondary'}
            sx={{ width: computedWidth, display: 'inline-block' }}
          >
            <Trans>Pending approval</Trans>
          </Typography>
        ) : (
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ width: computedWidth, display: 'inline-block' }}
          >
            <Trans>No access</Trans>
          </Typography>
        )
      ) : null,
    [approved, computedWidth, denied, requestError, requestSent]
  );

  const dialogTitle = useMemo(
    () => `${patient?.licenceCode} - ${patient?.remark}`,
    [patient?.licenceCode, patient?.remark]
  );

  if (!appUser) {
    return <></>;
  }

  if (!therapyCoursePatientId || statisticsCacheUpdating || isUpdating) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        <CircularProgress size={20} color="info" />
      </Box>
    );
  }

  if (statisticsCacheError) {
    return (
      <Typography
        variant="body2"
        color="error"
        sx={{
          width: computedWidth,
          display: 'inline-block',
          verticalAlign: 'middle',
        }}
      >
        <Tooltip
          color="error"
          title={t(statisticsCacheError?.message || 'Error')}
          arrow
        >
          <ErrorIcon />
        </Tooltip>
      </Typography>
    );
  }

  switch (type) {
    case 'sleepEfficiency':
      return (
        appUser &&
        (notApprovedInfo ? (
          notApprovedInfo
        ) : (
          <Typography
            variant="body2"
            sx={{
              width: computedWidth,
              display: 'inline-block',
              verticalAlign: 'middle',
            }}
          >
            {sleepEfficiency}
          </Typography>
        ))
      );
    case 'taskCompletion':
      return (
        appUser &&
        (notApprovedInfo ? (
          notApprovedInfo
        ) : (
          <Typography
            variant="body2"
            sx={{ width: computedWidth, display: 'inline-block' }}
          >
            {taskCompletionPercentage}
          </Typography>
        ))
      );
    case 'actions':
      return (
        <>
          {appUser && (
            <>
              <Tooltip title={t('View Patient')}>
                <GridActionsCellItem
                  icon={<ChartIcon />}
                  label={t('View Patient')}
                  onClick={() => {
                    navigate(
                      `/therapycourseoverview/${therapyCourseId}/${patient?.id}`
                    );
                  }}
                />
              </Tooltip>
              {!approved && (
                <Tooltip title={t('Request Access')}>
                  <GridActionsCellItem
                    icon={<RequestAccessIcon />}
                    color="primary"
                    label={t('Request Access')}
                    onClick={handleConfirmAccessRequestDialogOpen}
                  />
                </Tooltip>
              )}
            </>
          )}
          <Dialog
            open={confirmAccessRequestDialogOpen}
            onClose={handleConfirmAccessRequestDialogClose}
          >
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Trans>
                  Confirm that you want to request access to this patient's data
                </Trans>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirmAccessRequestDialogClose}>
                <Trans>Cancel</Trans>
              </Button>
              <Button onClick={handleConfirmAccessRequest}>
                <Trans>Request</Trans>
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    case 'accessStatus':
      return (
        appUser && (
          <>
            {approved ? (
              <></>
            ) : denied ? (
              <>
                <Button
                  onClick={handleConfirmAccessRequestDialogOpen}
                  variant="text"
                  size="small"
                >
                  <Trans>Request Access Again</Trans>
                </Button>
                <Alert severity="error">
                  <Trans>Patient has denied access request</Trans>
                </Alert>
              </>
            ) : !requestSent ? (
              <Typography textAlign="right">
                <Button
                  onClick={handleConfirmAccessRequestDialogOpen}
                  variant="contained"
                >
                  <Trans>Request Access to Patient Data</Trans>
                </Button>
              </Typography>
            ) : (
              <Alert severity="info">
                <Trans>Patient data access request pending</Trans>
              </Alert>
            )}
            {requestError && (
              <Alert severity="error">
                <Trans>Error sending request</Trans>
              </Alert>
            )}
            <Dialog
              open={confirmAccessRequestDialogOpen}
              onClose={handleConfirmAccessRequestDialogClose}
            >
              <DialogTitle>{dialogTitle}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <Trans>
                    Confirm that you want to request access to this patient's
                    data
                  </Trans>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleConfirmAccessRequestDialogClose}>
                  <Trans>Cancel</Trans>
                </Button>
                <Button onClick={handleConfirmAccessRequest}>
                  <Trans>Request</Trans>
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )
      );
    default:
      return <></>;
  }
}
