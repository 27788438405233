import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography, Box, useTheme, Grid, Button } from '@mui/material';
import { useMemo } from 'react';
import { StatisticDay } from './types';
import { ChartsXAxisProps, LineChart } from '@mui/x-charts';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Patient } from '../../firebase/firebaseModels';
import { ChartsNoDataOverlay } from '@mui/x-charts/ChartsOverlay';

type ProgressTabProps = {
  statistics: StatisticDay[] | null;
  fromDateString?: string;
  toDateString?: string;
  maxDateAsStr: string;
  patient?: Patient;
};

function formatPercent(value: number) {
  return `${value.toFixed(2)} %`;
}

export default function ProgressTab(props: ProgressTabProps) {
  const { t } = useTranslation();
  const {
    statistics: statisticsSlice,
    fromDateString,
    toDateString,
    patient,
  } = props;
  const theme = useTheme();

  /**
   * Filter statistics to include only entries with valid sleep efficiency data.
   */
  const filteredStatistics = useMemo(
    () =>
      statisticsSlice?.filter(
        (statistic) => statistic.sleep_efficiency_moving_average !== null
      ) ?? [],
    [statisticsSlice]
  );

  /**
   * Extract sleep efficiency data from filtered statistics.
   */
  const sleepEfficiencyData = filteredStatistics.map(
    (statistic) => statistic.sleep_efficiency_moving_average as number
  );

  /**
   * Extract task completion data if the user has homework tasks.
   */
  const taskCompletionData = useMemo(
    () =>
      filteredStatistics.map(
        (statistic) => statistic.percantage_completed_tasks_moving_average || 0
      ) ?? [],
    [filteredStatistics]
  );

  const { xAxis, series, yAxis } = useMemo(() => {
    if (filteredStatistics) {
      const xAxisData = filteredStatistics.map((item, idx) => idx);
      const xAxis = [
        {
          type: t('time'),
          data: xAxisData,
          scaleType: 'point',
          valueFormatter: (value: number) => {
            const date = filteredStatistics
              ? new Date(filteredStatistics[value]?.marking_date)
              : null;
            return date?.toLocaleDateString('fi-FI') || '';
          },
          tickLabelFormatter: (value: number) => {
            const date = filteredStatistics
              ? new Date(filteredStatistics[value]?.marking_date)
              : null;
            return date?.toLocaleDateString('fi-FI') || '';
          },
        } as ChartsXAxisProps,
      ];
      const series = [
        {
          name: t('Sleep Efficiency (Moving Average)'),
          data: sleepEfficiencyData,
          color: theme.palette.primary.main,
          valueFormatter: (value: number | null) =>
            value === null
              ? t('No data')
              : `${t('Sleep Efficiency')} ${formatPercent(value)}`,
        },
        {
          name: t('Task Completion Percentage (Moving Average)'),
          data: taskCompletionData,
          color: theme.palette.secondary.main,
          valueFormatter: (value: number | null) =>
            value === null
              ? t('No data')
              : `${t('Task Completion Percentage')} ${formatPercent(value)}`,
        },
      ];
      const yAxis = [
        {
          min: 0,
          max: 100,
        },
      ];
      return { xAxis, series, yAxis };
    }
    return { xAxis: [], series: [], yAxis: [] };
  }, [
    filteredStatistics,
    sleepEfficiencyData,
    t,
    taskCompletionData,
    theme.palette.primary.main,
    theme.palette.secondary.main,
  ]);

  const legendItems = useMemo(() => {
    return series.map((item) => ({
      label: item.name,
      color: item.color,
    }));
  }, [series]);

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        setDimensions({ width, height: width * 0.75 }); // 4:3 aspect ratio
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const handleExportChart = useCallback(() => {
    const chartDiv = containerRef.current?.querySelector(
      '#progress-tab-line-chart'
    ) as HTMLElement;
    if (!chartDiv) {
      return;
    }

    // Use html2canvas to capture the exact rendered content
    import('html2canvas').then((html2canvas) => {
      // Create a temporary container and add it to the document
      const exportContainer = document.createElement('div');
      exportContainer.style.backgroundColor = '#ffffff';
      exportContainer.style.padding = '0px';
      // Set explicit dimensions for the export container
      exportContainer.style.width = `${dimensions.width}px`;
      document.body.appendChild(exportContainer);

      // Clone the elements
      const chartClone = chartDiv.cloneNode(true) as HTMLElement;
      // Set explicit dimensions for the chart clone
      chartClone.style.width = `${dimensions.width}px`;
      chartClone.style.height = `${dimensions.height}px`;

      const legendDiv = containerRef.current?.querySelector('#chart-legend');
      const legendClone = legendDiv?.cloneNode(true) as HTMLElement;

      // Add header information
      const header = document.createElement('div');
      header.style.paddingTop = '5px';
      header.style.paddingLeft = '20px';
      header.style.fontSize = '12px';
      header.style.fontFamily = 'Arial';

      let headerText = '';
      if (patient) {
        headerText += `${patient.licenceCode}${
          patient.remark ? ` - ${patient.remark}` : ''
        }`;
      }
      if (fromDateString || toDateString) {
        headerText += headerText ? ' | ' : '';
        headerText += `${t('Date Range')}: ${fromDateString || t('Start')} - ${
          toDateString || t('End')
        }`;
      }
      header.textContent = headerText;

      // Assemble the export container
      exportContainer.appendChild(header);
      exportContainer.appendChild(chartClone);
      exportContainer.appendChild(legendClone);

      // Wait for a frame to ensure the chart is properly rendered
      requestAnimationFrame(() => {
        html2canvas
          .default(exportContainer, {
            backgroundColor: '#ffffff',
            scale: 2,
            logging: false,
            width: dimensions.width + 40, // Add padding
            height: dimensions.height + 100, // Add space for header and legend
          })
          .then((canvas) => {
            const link = document.createElement('a');
            // Create filename with patient code and date range
            let filename = t('progress-chart');
            if (patient?.licenceCode) {
              filename = `${patient.licenceCode}-${filename}`;
            }
            if (fromDateString && toDateString) {
              filename += `-${fromDateString}-to-${toDateString}`;
            }
            filename += '.png';

            link.download = filename;
            link.href = canvas.toDataURL('image/png');
            link.click();

            // Clean up
            exportContainer.remove();
          });
      });
    });
  }, [patient, fromDateString, toDateString, t, dimensions]);

  if (!filteredStatistics) {
    return (
      <div>
        <Trans>No data available</Trans>
      </div>
    );
  }

  return (
    <Box ref={containerRef}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3, // Increased margin bottom
        }}
      >
        <Typography
          variant="subtitle1"
          component="h2"
          sx={{ color: 'text.secondary' }}
        >
          {t('Progress Over Time')}
        </Typography>
        <Button
          variant="outlined"
          startIcon={<FileDownloadIcon />}
          onClick={handleExportChart}
          size="small" // Made button more compact
        >
          {t('Export Chart')}
        </Button>
      </Box>
      <Box
        sx={{
          width: '100%',
          position: 'relative',
        }}
      >
        <div id="progress-tab-line-chart">
          <LineChart
            xAxis={xAxis}
            yAxis={yAxis}
            series={series.map((s) => ({
              ...s,
              area: false,
              lineStyle: { strokeWidth: 2 },
              showMark: true,
              markStyle: { strokeWidth: 0 },
            }))}
            width={dimensions.width}
            height={dimensions.height}
            sx={{
              aspectRatio: '4/3',
              width: '100%',
              height: 'auto',
              '& .MuiLineElement-root': {
                strokeWidth: 2,
                fill: 'none',
              },
            }}
            slots={{
              noDataOverlay: () => (
                <ChartsNoDataOverlay message={t('No data available')} />
              ),
            }}
            slotProps={{
              legend: {
                hidden: true,
              },
            }}
          />
        </div>
      </Box>
      <Box sx={{ mt: 2 }} id="chart-legend">
        <Grid container spacing={2} justifyContent="center">
          {legendItems.map((item, index) => (
            <Grid item key={index}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    width: 16,
                    height: 16,
                    backgroundColor: item.color,
                    marginRight: 1,
                  }}
                />
                <Typography variant="body2">{item.label}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
