import { Box, Tooltip, SxProps, Theme } from '@mui/material';

interface TaskCategoryDotProps {
  color: string;
  categoryName: string;
  size?: number;
  sx?: SxProps<Theme>;
}

export default function TaskCategoryDot({
  color,
  categoryName,
  size = 12,
  sx,
}: TaskCategoryDotProps) {
  return (
    <Tooltip title={categoryName}>
      <Box
        sx={{
          width: size,
          height: size,
          borderRadius: '50%',
          backgroundColor: color,
          mr: 1,
          ...sx,
        }}
      />
    </Tooltip>
  );
}
