import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Card, CardHeader, CardContent, Box, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useTranslation } from 'react-i18next';
import { TherapyPlanPhase } from '../../firebase/firebaseModels';
import TherapyPlanEditorPhase from './TherapyPlanEditorPhase';
import { useCallback, useMemo } from 'react';
import { useTaskPoolOptions } from '../../hooks/useTaskPoolOptions';
import useTaskPoolCollection from '../../firebase/useTaskPoolCollection';
import useTaskPoolTaskCategoryCollection from '../../firebase/useTaskPoolTaskCategoryCollection';
import usePortalUser from '../../firebase/usePortalUser';

const grid = 1;

interface TherapyPlanPhasesListProps {
  phases: TherapyPlanPhase[];
  setPhases: React.Dispatch<React.SetStateAction<TherapyPlanPhase[]>>;
  onDragEnd: (result: any) => void;
  canEdit: boolean;
  language: string;
  defaultLanguage: string;
}

export default function TherapyPlanPhasesList({
  phases,
  setPhases,
  onDragEnd,
  canEdit,
  language,
  defaultLanguage,
}: TherapyPlanPhasesListProps) {
  const { t } = useTranslation();
  const { data: portalUserData } = usePortalUser();

  const therapyLanguages = useMemo(() => {
    let therapyLanguagesTmp = [...(portalUserData?.therapyLanguages || [])];
    if (!therapyLanguagesTmp.includes(defaultLanguage)) {
      therapyLanguagesTmp.push(defaultLanguage);
    }
    if (!therapyLanguagesTmp.includes(language)) {
      therapyLanguagesTmp.push(language);
    }
    return therapyLanguagesTmp;
  }, [portalUserData?.therapyLanguages, defaultLanguage, language]);

  const phaseTaskIds = useMemo(() => {
    const ids = new Set<string>();
    phases.forEach((phase) => {
      phase.phaseTasks?.forEach((task) => {
        if (task.id) {
          ids.add(task.id);
        }
      });
    });
    return Array.from(ids);
  }, [
    phases
      .map((phase) =>
        // Only include task-related data in dependency array
        phase.phaseTasks?.map((task) => task.id).join(',')
      )
      .join(','),
  ]);

  const {
    status: taskPoolStatus,
    data: taskPoolData,
    error: taskPoolError,
  } = useTaskPoolCollection(null, therapyLanguages, phaseTaskIds);

  const {
    status: categoryStatus,
    data: categoryData,
    error: collectionError,
  } = useTaskPoolTaskCategoryCollection();

  const taskPoolOptions = useTaskPoolOptions(
    taskPoolData,
    categoryData,
    portalUserData
  );

  const handleDeletePhase = useCallback(
    (index: number) => {
      setPhases((prevPhases) => [
        ...prevPhases.slice(0, index),
        ...prevPhases.slice(index + 1),
      ]);
    },
    [setPhases]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {phases.map((phase, index) => (
              <Draggable
                key={phase.id || `phase-${index}`}
                draggableId={phase.id || `phase-${index}`}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                      marginBottom: grid,
                    }}
                  >
                    <Card variant="outlined">
                      <CardHeader
                        title={
                          <Box display="flex" alignItems="center">
                            <DragIndicatorIcon sx={{ mr: 1 }} />
                            {`${t('Phase')} ${index + 1}`}
                          </Box>
                        }
                        action={
                          index > 0 && (
                            <Button
                              variant="outlined"
                              color="error"
                              startIcon={<DeleteIcon />}
                              onClick={() => handleDeletePhase(index)}
                              disabled={!canEdit}
                            >
                              {t('Delete')}
                            </Button>
                          )
                        }
                      />
                      <CardContent>
                        <TherapyPlanEditorPhase
                          key={`${phase.id}-${index}`}
                          disabled={!canEdit}
                          currentPhase={index}
                          phases={phases}
                          setPhases={setPhases}
                          taskPoolOptions={taskPoolOptions}
                          language={language}
                          defaultLanguage={defaultLanguage}
                        />
                      </CardContent>
                    </Card>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
