import ReactQuill, { UnprivilegedEditor } from 'react-quill';
import { TranslatableField } from '../firebase/firebaseModels';
import {
  CSSProperties,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useTranslationSuggestion } from '../firebase/useTranslationSuggestion';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { stripHtml } from '../utils';
import { Alert, AlertTitle, useTheme } from '@mui/material';

export const REACT_QUILL_MODULES = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
    [{ script: 'sub' }, { script: 'super' }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
};

type TranslatableEditorProps = {
  disabled?: boolean;
  placeholder: string;
  value: TranslatableField;
  language: string;
  setValue: (value: SetStateAction<TranslatableField>) => void;
  style?: CSSProperties | undefined;
  type: 'title' | 'description' | 'content';
  name: string;
};

export default function TranslatableEditor(props: TranslatableEditorProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    disabled,
    placeholder,
    language,
    setValue,
    value,
    type,
    name,
    style = {},
  } = props;

  const editorRef = useRef<ReactQuill | null>(null);

  const [fetchTranslation, setFetchTranslation] = useState(false);

  const {
    loading: trsLoading,
    translationSuggestion,
    canFetch,
    fieldInDefaultLanguage,
    defaultLanguage,
    defaultLanguageName,
    error: trsError,
  } = useTranslationSuggestion(value, language, type, fetchTranslation);

  useEffect(() => {
    if (trsError) {
      setFetchTranslation(false);
    }
  }, [trsError]);

  const translatedValue = value[language] || '';
  const strippedTranslatedValue = stripHtml(translatedValue);
  const hasValue = !!strippedTranslatedValue;

  const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholder);
  const onChange = useCallback(
    (v: string, d: any, s: any, editor: UnprivilegedEditor) => {
      const htmlValue = editor.getHTML();
      const normalizedHtml = htmlValue === '<p><br></p>' ? '' : htmlValue;
      setValue((oldValue) => ({
        ...oldValue,
        [language]: normalizedHtml,
      }));
    },
    [language, setValue]
  );

  useEffect(() => {
    let newPlaceholder = placeholder;
    if (translationSuggestion) {
      const textTranslationSuggestion = stripHtml(translationSuggestion).trim();
      newPlaceholder = `${t(
        'Translation suggestion'
      )}: ${textTranslationSuggestion}`;
    } else {
      const textDefaultLanguage =
        fieldInDefaultLanguage && stripHtml(fieldInDefaultLanguage).trim();
      newPlaceholder =
        textDefaultLanguage && defaultLanguageName
          ? `${t(defaultLanguageName)}: ${textDefaultLanguage}`
          : placeholder;
    }

    if (currentPlaceholder !== newPlaceholder) {
      setCurrentPlaceholder(newPlaceholder);
    }
  }, [
    currentPlaceholder,
    defaultLanguageName,
    fetchTranslation,
    fieldInDefaultLanguage,
    placeholder,
    t,
    translatedValue,
    translationSuggestion,
  ]);

  useEffect(() => {
    if (editorRef.current) {
      // set placeholder on the editor
      editorRef.current.getEditor().root.dataset.placeholder =
        currentPlaceholder;
      if (!strippedTranslatedValue) {
        // resize the editor to accommodate the new placeholder
        // first store the current values
        const currentHeight = editorRef.current.getEditor().root.offsetHeight;
        // then set the text temporary to the placeholder to get the new height
        editorRef.current.getEditor().root.innerHTML = currentPlaceholder;
        // get the computed height
        const newHeight = editorRef.current.getEditor().root.offsetHeight;
        // set the height if it is larger than the current height
        if (newHeight > currentHeight) {
          editorRef.current.getEditor().root.style.height = `${newHeight}px`;
        }
        // reset the text
        editorRef.current.getEditor().root.innerHTML = '';
      } else {
        // automatic height if there is text
        editorRef.current.getEditor().root.style.height = 'auto';
      }
    }
  }, [currentPlaceholder, strippedTranslatedValue, translatedValue]);

  const toolbarActions = useMemo(
    () => (
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 0 }}>
        {!hasValue &&
          !translationSuggestion &&
          canFetch &&
          !disabled &&
          defaultLanguage !== language && (
            <Button
              onClick={() => {
                setFetchTranslation(true);
              }}
            >
              <Trans>Fetch Translation Suggestion</Trans>
            </Button>
          )}
        {fetchTranslation && trsLoading && (
          <CircularProgress size={20} sx={{ mt: 1 }} />
        )}
        {!trsLoading && translationSuggestion && (
          <Button
            onClick={() => {
              setValue((fldVal) => ({
                ...fldVal,
                [language]: translationSuggestion,
              }));
              setFetchTranslation(false);
            }}
          >
            <Trans>Use Suggestion</Trans>
          </Button>
        )}
      </Box>
    ),
    [
      hasValue,
      translationSuggestion,
      canFetch,
      disabled,
      defaultLanguage,
      language,
      fetchTranslation,
      trsLoading,
      setValue,
    ]
  );

  return (
    <Box sx={{ mt: 1, ...style }}>
      {disabled ? (
        <div
          dangerouslySetInnerHTML={{
            __html: translatedValue || `<p>&nbsp;</p>`,
          }}
          style={{
            border: `1px solid ${theme.palette.action.disabled}`,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            borderRadius: theme.shape.borderRadius,
            color: theme.palette.text.disabled,
          }}
        />
      ) : (
        <ReactQuill
          id={name}
          ref={editorRef}
          placeholder={currentPlaceholder}
          value={translatedValue}
          onChange={onChange}
          modules={REACT_QUILL_MODULES}
        />
      )}
      {toolbarActions}
      {trsError && (
        <Alert severity="error" variant="outlined">
          <AlertTitle>{t(trsError.code)}</AlertTitle>
          {t((trsError.customData?.message as string) || trsError.message)}
        </Alert>
      )}
    </Box>
  );
}
