import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AlertTitle, CircularProgress, Typography } from '@mui/material';
import useTaskPoolCollection from '../firebase/useTaskPoolCollection';
import {
  DataGrid,
  GridToolbar,
  GridColDef,
  GridActionsCellItem,
  GridRowModes,
  GridRowModesModel,
  GridEventListener,
  GridRowEditStopReasons,
  GridCellModes,
  GridRowModel,
  GridCellParams,
  GridRowId,
} from '@mui/x-data-grid';
import { getTranslatableField } from '../services/i18n';
import { Link, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';
import LinkIcon from '@mui/icons-material/Link';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import Alert from '@mui/material/Alert';
import { htmlToText } from 'html-to-text';
import useTaskPoolTaskCategoryCollection from '../firebase/useTaskPoolTaskCategoryCollection';
import Tooltip from '@mui/material/Tooltip';
import usePortalUser from '../firebase/usePortalUser';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
import { useTaskPoolStore } from '../stores/taskPoolStore';
import BarChartIcon from '@mui/icons-material/BarChart';
import RepeatIcon from '@mui/icons-material/Repeat';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { TaskTypeIcon } from '../components/TaskTypeIcon';
import { getTaskTypeLabels } from '../constants/taskTypes';
import { TaskFilter } from '../components/TaskFilter';
import { useTaskFilter } from '../hooks/useTaskFilter';
import TaskCategoryDot from '../components/TaskCategoryDot';

type TaskPoolProps = {
  taskpooltype: string | null;
};

export default function TaskPool(props: TaskPoolProps) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const firestore = useFirestore();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [dialogTitle, setDialogTitle] = useState<string | null>(null);
  const [dialogContent, setDialogContent] = useState<string | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const { taskpooltype } = props;

  const {
    data: portalUserData,
    userRef,
    isSuperAdmin,
    isOrganizationAdmin,
    providerRef,
  } = usePortalUser();

  const hiddenTaskPoolTasks = useMemo(() => {
    if (portalUserData?.hiddenTaskPoolTasks) {
      return portalUserData.hiddenTaskPoolTasks;
    }
    return [];
  }, [portalUserData]);

  const therapyLanguages = useMemo(() => {
    return portalUserData?.therapyLanguages || [];
  }, [portalUserData]);

  const {
    status: taskPoolStatus,
    data,
    error: taskPoolError,
  } = useTaskPoolCollection(taskpooltype, therapyLanguages);

  const {
    status: categoryStatus,
    data: categoryData,
    error: categoryError,
  } = useTaskPoolTaskCategoryCollection();

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const {
    selectedTaskType,
    selectedCategory,
    searchQuery,
    sortModel,
    pageSize,
    page,
    setSelectedTaskType,
    setSelectedCategory,
    setSearchQuery,
    setPageSize,
    setPage,
  } = useTaskPoolStore();

  const [localSearchQuery, setLocalSearchQuery] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      setSearchQuery(localSearchQuery);
    }, 300);
    return () => clearTimeout(timer);
  }, [localSearchQuery, setSearchQuery]);

  const taskTypeLabels = useMemo(() => getTaskTypeLabels(t), [t]);

  const taskTypes = useMemo(() => {
    if (!data) {
      return [];
    }
    return Array.from(new Set(data.map((task) => task.type))).filter(
      (type) => !['homework', 'morning', 'evening'].includes(type)
    );
  }, [data]);

  const categories = useMemo(() => {
    if (!categoryData) {
      return [];
    }
    return categoryData
      .filter((category) => category.id)
      .map((category) => ({
        id: category.id as string,
        title: getTranslatableField(category.title),
      }));
  }, [categoryData]);

  const handleTaskTypeChange = (event: SelectChangeEvent) => {
    setSelectedTaskType(event.target.value);
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setSelectedCategory(event.target.value);
  };

  const filteredRows = useTaskFilter({
    tasks: data || [],
    searchQuery,
    selectedTaskType,
    selectedCategory,
    excludeTaskTypes: ['homework', 'morning', 'evening'],
  });

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleCancelClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
    },
    [rowModesModel]
  );

  const handleSaveClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    [rowModesModel]
  );

  const handleCellClick = useCallback(
    (params: GridCellParams, event: React.MouseEvent) => {
      if (!params.isEditable) {
        return;
      }
      if (
        (event.target as any).nodeType === 1 &&
        !(event.currentTarget as Node).contains(event.target as Element)
      ) {
        return;
      }

      const rowId = params.id;
      setRowModesModel((prevModel) => ({
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridRowModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [rowId]: {
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({
              ...acc,
              [field]: { mode: GridCellModes.View },
            }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
          mode: GridRowModes.Edit,
        },
      }));
    },
    []
  );

  const processRowUpdate = useCallback(
    (newRow: GridRowModel) => {
      if (newRow.id) {
        const ref = doc(firestore, 'TaskPool', newRow.id);
        updateDoc(ref, {
          order: newRow.order,
        }).catch((error) => {
          console.error('Error updating document: ', error);
          setError(error);
        });
      }
      return newRow;
    },
    [firestore]
  );

  const handleDelete = useCallback(
    (taskId: string, taskName: string) => {
      setDeleteId(taskId);
      setDialogTitle(t('Delete Task') + ' ' + taskName);
      setDialogContent(t('Are you sure you want to delete this task?'));
      setDialogOpen(true);
    },
    [t]
  );

  const handleCloseDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const confirmDelete = useCallback(() => {
    setDialogOpen(false);
    if (!deleteId) {
      return;
    }
    const ref = doc(firestore, 'TaskPool', deleteId);
    deleteDoc(ref)
      .then(() => {
        console.log('Document successfully deleted!');
      })
      .catch((error) => {
        console.error('Error removing document: ', error);
        setError(error);
      });
  }, [deleteId, firestore]);

  const canAdd = useMemo(() => {
    return (
      (taskpooltype === 'default' && isSuperAdmin) ||
      (taskpooltype === 'organization' && isOrganizationAdmin) ||
      taskpooltype === 'own'
    );
  }, [taskpooltype, isSuperAdmin, isOrganizationAdmin]);

  const columns: GridColDef[] = useMemo(() => {
    if (categoryStatus === 'loading' || taskPoolStatus === 'loading') {
      return [];
    }

    return [
      {
        field: 'type',
        headerName: '',
        width: 40,
        sortable: false,
        renderHeader: () => (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Tooltip title={t('Task Type')}>
              <AssignmentIcon fontSize="small" sx={{ opacity: 0.6 }} />
            </Tooltip>
          </div>
        ),
        renderCell: (params) => {
          return (
            <Typography
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                p: 0,
              }}
            >
              <TaskTypeIcon type={params.row.type} sx={{ mt: 1 }} />
            </Typography>
          );
        },
      },
      {
        field: 'title',
        headerName: t('Title'),
        width: 280,
        valueGetter: (_, row) => getTranslatableField(row.title),
      },
      {
        field: 'includeInStatistics',
        headerName: '',
        width: 40,
        sortable: false,
        renderHeader: () => (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Tooltip title={t('Included in Statistics')}>
              <BarChartIcon fontSize="small" sx={{ opacity: 0.6 }} />
            </Tooltip>
          </div>
        ),
        renderCell: (params) =>
          params.row.includeInStatistics && (
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Tooltip title={t('Included in Statistics')}>
                <BarChartIcon
                  fontSize="inherit"
                  sx={{ color: 'text.secondary' }}
                />
              </Tooltip>
            </div>
          ),
      },
      {
        field: 'frequency',
        headerName: '',
        width: 40,
        sortable: false,
        renderHeader: () => (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Tooltip title={t('Daily Repeating Task')}>
              <RepeatIcon fontSize="small" sx={{ opacity: 0.6 }} />
            </Tooltip>
          </div>
        ),
        renderCell: (params) =>
          params.row.options?.frequency === 'daily' && (
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Tooltip title={t('Daily Repeating Task')}>
                <RepeatIcon
                  fontSize="inherit"
                  sx={{ color: 'text.secondary' }}
                />
              </Tooltip>
            </div>
          ),
      },
      {
        field: 'description',
        headerName: t('Description'),
        flex: 1,
        valueGetter: (_, row) =>
          htmlToText(getTranslatableField(row.description), { wordwrap: 130 }),
      },
      {
        field: 'category',
        headerName: t('Category'),
        width: 200,
        valueGetter: (_, row) => {
          const category = categoryData?.find(
            (category) => category.id === row.category?.id
          );
          return category ? getTranslatableField(category.title) : '';
        },
        renderCell: ({ colDef, row }) => {
          const category = categoryData?.find(
            (category) => category.id === row.category?.id
          );
          const value = category ? getTranslatableField(category.title) : '';

          return (
            <div
              style={{
                display: 'inline-flex',
                width: colDef.computedWidth,
                alignItems: 'center',
                height: '100%',
              }}
            >
              <TaskCategoryDot
                color={category?.color || '#000000'}
                categoryName={value}
                size={12}
              />
              <Typography variant="body2" component="span" noWrap>
                {value}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'order',
        headerName: t('Order #'),
        width: 100,
        type: 'number',
        editable: true,
        cellClassName: 'editable-cell',
        renderCell: (params) => (
          <div className="editable-cell">
            {params.value}
            <EditIcon className="edit-icon" fontSize="small" />
          </div>
        ),
      },
      { field: 'languages', headerName: t('Languages'), width: 150 },
      {
        field: 'actions',
        type: 'actions',
        width: 140,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        getActions: ({ id, row }) => {
          const route = `/admin/taskpool/${taskpooltype}/${row.id}`;
          const { amOwner, provider } = row;
          const canEdit =
            amOwner ||
            isSuperAdmin ||
            (provider?.id === providerRef?.id && isOrganizationAdmin);
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <Tooltip title={t('Save')} key="save">
                <GridActionsCellItem
                  icon={<SaveIcon />}
                  label={t('Save')}
                  sx={{ color: 'primary.main' }}
                  onClick={handleSaveClick(id)}
                />
              </Tooltip>,
              <Tooltip title={t('Cancel')} key="cancel">
                <GridActionsCellItem
                  icon={<CancelIcon />}
                  label={t('Cancel')}
                  className="textPrimary"
                  onClick={handleCancelClick(id)}
                  color="inherit"
                />
              </Tooltip>,
            ];
          }

          return canEdit
            ? [
                <Tooltip title={t('Show/Hide')} key="show-hide">
                  <GridActionsCellItem
                    icon={
                      hiddenTaskPoolTasks.includes(row.id) ? (
                        <VisibilityOffIcon color="primary" />
                      ) : (
                        <VisibilityIcon />
                      )
                    }
                    label={t('Show/Hide')}
                    className="textPrimary"
                    color="inherit"
                    onClick={() => {
                      if (!userRef) {
                        return;
                      }
                      const hidden = hiddenTaskPoolTasks.includes(row.id);
                      const newHiddenTaskPoolTasks = hidden
                        ? hiddenTaskPoolTasks.filter((id) => id !== row.id)
                        : [...hiddenTaskPoolTasks, row.id];
                      updateDoc(userRef, {
                        hiddenTaskPoolTasks: newHiddenTaskPoolTasks,
                      });
                    }}
                  />
                </Tooltip>,
                <Tooltip title={t('Duplicate')} key="duplicate">
                  <GridActionsCellItem
                    icon={<ContentCopyIcon />}
                    label={t('Duplicate')}
                    className="textPrimary"
                    color="inherit"
                    disabled={row.type === 'sleepRestriction'}
                    onClick={() =>
                      navigate(`/admin/taskpool/own/new/${row.id}`)
                    }
                  />
                </Tooltip>,
                <Tooltip title={t('Edit')}>
                  <GridActionsCellItem
                    disabled={!row.id}
                    icon={<EditIcon />}
                    label={t('Edit')}
                    className="textPrimary"
                    color="inherit"
                    onClick={() => navigate(route)}
                  />
                </Tooltip>,
                <Tooltip title={t('Delete')}>
                  <GridActionsCellItem
                    disabled={
                      !row.id ||
                      (row.type !== 'generic' && row.type !== 'questionnaire')
                    }
                    icon={<DeleteIcon />}
                    label={t('Delete')}
                    onClick={() =>
                      handleDelete(
                        row.id || '',
                        getTranslatableField(row.title)
                      )
                    }
                    color="inherit"
                  />
                </Tooltip>,
              ]
            : [
                <Tooltip title={t('Show/Hide')} key="show-hide">
                  <GridActionsCellItem
                    icon={
                      hiddenTaskPoolTasks.includes(row.id) ? (
                        <VisibilityOffIcon color="primary" />
                      ) : (
                        <VisibilityIcon />
                      )
                    }
                    label={t('Show/Hide')}
                    className="textPrimary"
                    onClick={() => {
                      if (!userRef) {
                        return;
                      }
                      const hidden = hiddenTaskPoolTasks.includes(row.id);
                      const newHiddenTaskPoolTasks = hidden
                        ? hiddenTaskPoolTasks.filter((id) => id !== row.id)
                        : [...hiddenTaskPoolTasks, row.id];
                      updateDoc(userRef, {
                        hiddenTaskPoolTasks: newHiddenTaskPoolTasks,
                      });
                    }}
                  />
                </Tooltip>,
                <Tooltip title={t('Duplicate')} key="duplicate">
                  <GridActionsCellItem
                    icon={<ContentCopyIcon />}
                    label={t('Duplicate')}
                    className="textPrimary"
                    color="inherit"
                    disabled={row.type === 'sleepRestriction'}
                    onClick={() =>
                      navigate(`/admin/taskpool/own/new/${row.id}`)
                    }
                  />
                </Tooltip>,
                <Tooltip title={t('View')}>
                  <GridActionsCellItem
                    disabled={!row.id}
                    icon={<LinkIcon />}
                    label={t('View')}
                    className="textPrimary"
                    color="inherit"
                    onClick={() => navigate(route)}
                  />
                </Tooltip>,
                <Tooltip title={t('Delete')}>
                  <GridActionsCellItem
                    disabled
                    icon={<DeleteIcon />}
                    label={t('Delete')}
                    color="inherit"
                  />
                </Tooltip>,
              ];
        },
      },
    ];
  }, [
    categoryStatus,
    taskPoolStatus,
    t,
    categoryData,
    taskpooltype,
    isSuperAdmin,
    providerRef?.id,
    isOrganizationAdmin,
    rowModesModel,
    hiddenTaskPoolTasks,
    handleSaveClick,
    handleCancelClick,
    userRef,
    navigate,
    handleDelete,
  ]);

  if (categoryStatus === 'loading' || taskPoolStatus === 'loading') {
    return <CircularProgress />;
  }

  return (
    <>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error.message}
        </Alert>
      )}
      {taskPoolError && (
        <Alert severity="error" sx={{ mb: 2 }}>
          <AlertTitle>
            <Trans>Error Getting Task Pool</Trans>
          </AlertTitle>
          {taskPoolError.message}
        </Alert>
      )}
      {categoryError && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {categoryError.message}
        </Alert>
      )}
      <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
        <Trans>Task Pool</Trans>
      </Typography>
      <Box sx={{ py: 2 }}>
        <TaskFilter
          searchQuery={localSearchQuery}
          setSearchQuery={setLocalSearchQuery}
          selectedTaskType={selectedTaskType}
          selectedCategory={selectedCategory}
          handleTaskTypeChange={handleTaskTypeChange}
          handleCategoryChange={handleCategoryChange}
          taskTypes={taskTypes}
          categories={categories.map((cat) => ({
            ...cat,
            color: categoryData?.find((c) => c.id === cat.id)?.color,
          }))}
          taskTypeLabels={taskTypeLabels}
        />
      </Box>
      <DataGrid
        slots={{
          toolbar: GridToolbar,
        }}
        initialState={{
          sorting: {
            sortModel: sortModel,
          },
          pagination: {
            paginationModel: {
              pageSize,
              page,
            },
          },
        }}
        paginationModel={{ page, pageSize }}
        onPaginationModelChange={(model) => {
          setPage(model.page);
          setPageSize(model.pageSize);
        }}
        autoHeight
        rows={filteredRows}
        columns={columns}
        rowSelection={false}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        sx={{
          '& .editable-cell': {
            display: 'flex',
            alignItems: 'center',
            '& .edit-icon': {
              opacity: 0,
              marginLeft: 1,
              transition: 'opacity 0.2s',
            },
            '&:hover': {
              backgroundColor: 'action.hover',
              cursor: 'pointer',
              '& .edit-icon': {
                opacity: 1,
              },
            },
          },
        }}
        editMode="cell"
        rowModesModel={rowModesModel}
        onRowModesModelChange={setRowModesModel}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onCellClick={handleCellClick}
      />
      {canAdd && (
        <Button
          component={Link}
          to={`/admin/taskpool/${taskpooltype}/new`}
          variant="contained"
          startIcon={<AddIcon />}
          sx={{ mt: 2 }}
        >
          <Trans>New Task</Trans>
        </Button>
      )}
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogTitle || t('Delete Task')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogContent || t('Are you sure you want to delete this task?')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            <Trans>Cancel</Trans>
          </Button>
          <Button onClick={confirmDelete} color="primary" autoFocus>
            <Trans>Confirm</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
