import { create } from 'zustand';
import { GridSortModel } from '@mui/x-data-grid';

interface TaskPoolState {
  selectedTaskType: string;
  selectedCategory: string;
  searchQuery: string;
  sortModel: GridSortModel;
  pageSize: number;
  page: number;
  setSelectedTaskType: (taskType: string) => void;
  setSelectedCategory: (category: string) => void;
  setSearchQuery: (query: string) => void;
  setSortModel: (model: GridSortModel) => void;
  setPageSize: (size: number) => void;
  setPage: (page: number) => void;
}

export const useTaskPoolStore = create<TaskPoolState>((set) => ({
  selectedTaskType: 'all',
  selectedCategory: 'all',
  searchQuery: '',
  sortModel: [{ field: 'category', sort: 'asc' }],
  pageSize: 25,
  page: 0,
  setSelectedTaskType: (taskType) => set({ selectedTaskType: taskType }),
  setSelectedCategory: (category) => set({ selectedCategory: category }),
  setSearchQuery: (query) => set({ searchQuery: query }),
  setSortModel: (model) => set({ sortModel: model }),
  setPageSize: (size) => set({ pageSize: size }),
  setPage: (page) => set({ page: page }),
}));
