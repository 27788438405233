import { Trans, useTranslation } from 'react-i18next';
import {
  Alert,
  AlertTitle,
  CircularProgress,
  Paper,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridToolbar,
  GridColDef,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { getTranslatableField } from '../services/i18n';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
// import AddBoxIcon from '@mui/icons-material/AddBox';
import AddIcon from '@mui/icons-material/Add';
import LinkIcon from '@mui/icons-material/Link';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useCallback, useMemo, useState } from 'react';
import { deleteDoc, doc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import { TherapyPlan } from '../firebase/firebaseModels';
import { htmlToText } from 'html-to-text';
import useTherapyPlanCollection from '../firebase/useTherapyPlanCollection';
import useUserProvider from '../firebase/useUserProvider';
// import GenerateCourseFromPlanDialog from '../components/GenerateCourseFromPlanDialog';
import { useFirebaseUser } from '../firebase/useFirebaseUser';
import Tooltip from '@mui/material/Tooltip';
import usePortalUser from '../firebase/usePortalUser';

type TherapyPlansProps = {
  therapyplantype: string;
};

export default function TherapyPlans(props: TherapyPlansProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const firestore = useFirestore();
  const { email } = useFirebaseUser();
  const {
    data: portalUserData,
    isSuperAdmin,
    isOrganizationAdmin,
    providerRef,
  } = usePortalUser();

  const { data: userProviderData, status: userProviderStatus } =
    useUserProvider();

  const [dialogOpen, setDialogOpen] = useState(false);
  // const [generateDialogOpen, setGenerateDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [dialogTitle, setDialogTitle] = useState<string | null>(null);
  const [dialogContent, setDialogContent] = useState<string | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const { therapyplantype } = props;

  const therapyLanguages = useMemo(() => {
    return portalUserData?.therapyLanguages || [];
  }, [portalUserData]);

  const canAdd = useMemo(() => {
    return (
      (therapyplantype === 'default' && isSuperAdmin) ||
      (therapyplantype === 'organization' && isOrganizationAdmin) ||
      therapyplantype === 'own'
    );
  }, [therapyplantype, isSuperAdmin, isOrganizationAdmin]);

  const {
    status,
    data,
    error: collectionError,
  } = useTherapyPlanCollection(therapyplantype, therapyLanguages);

  const handleDelete = useCallback(
    (therapyPlanId: string, therapyPlanTitle: string) => {
      setDeleteId(therapyPlanId);
      setDialogTitle(t('Delete Therapy Plan') + ' ' + therapyPlanTitle);
      setDialogContent(t('Are you sure you want to delete this therapy plan?'));
      setDialogOpen(true);
    },
    [t]
  );

  // const handleGenerate = useCallback(
  //   async (therapyPlanId: string) => {
  //     if (!userProviderData?.id || !therapyPlanId) {
  //       return;
  //     }

  //     setGenerateId(therapyPlanId);
  //     setGenerateDialogOpen(true);
  //   },
  //   [userProviderData?.id]
  // );

  const handleCloseDialog = useCallback(() => {
    setDialogOpen(false);
  }, [setDialogOpen]);

  const confirmDelete = useCallback(() => {
    setDialogOpen(false);
    if (!deleteId || !userProviderData?.id || !email) {
      return;
    }
    const ref = doc(firestore, 'TherapyPlan', deleteId);
    deleteDoc(ref)
      .then(() => {
        console.log('Document successfully deleted!');
      })
      .catch((error) => {
        console.error('Error removing document: ', error);
        setError(error);
      });
  }, [deleteId, email, firestore, userProviderData?.id]);

  const columns: GridColDef<TherapyPlan>[] = useMemo(
    () => [
      {
        field: 'title',
        headerName: t('Title'),
        flex: 1,
        valueGetter: (_, row) => getTranslatableField(row.title),
      },
      {
        field: 'description',
        headerName: t('Description'),
        flex: 1,
        valueGetter: (_, row) =>
          htmlToText(getTranslatableField(row.description)),
      },
      { field: 'languages', headerName: t('Languages'), width: 150 },
      {
        field: 'actions',
        type: 'actions',
        width: 100,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        getActions: ({ id, row }) => {
          const route = `/admin/therapyplans/${therapyplantype}/${row.id}`;
          const { amOwner, provider } = row;
          const canEdit =
            amOwner ||
            isSuperAdmin ||
            (provider?.id === providerRef?.id && isOrganizationAdmin);

          return canEdit
            ? [
                <Tooltip title={t('Duplicate')} key="duplicate">
                  <GridActionsCellItem
                    icon={<ContentCopyIcon />}
                    label={t('Duplicate')}
                    className="textPrimary"
                    color="inherit"
                    onClick={() =>
                      navigate(`/admin/therapyplans/own/new/${row.id}`)
                    }
                  />
                </Tooltip>,
                <Tooltip title={t('Edit')} key="edit">
                  <GridActionsCellItem
                    disabled={!row.id}
                    icon={<EditIcon />}
                    label={t('Edit')}
                    className="textPrimary"
                    color="inherit"
                    onClick={() => navigate(route)}
                  />
                </Tooltip>,
                <Tooltip title={t('Delete')} key="delete">
                  <GridActionsCellItem
                    disabled={!row.id}
                    icon={<DeleteIcon />}
                    label={t('Delete')}
                    onClick={() =>
                      handleDelete(
                        row.id || '',
                        getTranslatableField(row.title)
                      )
                    }
                    color="inherit"
                  />
                </Tooltip>,
              ]
            : [
                <Tooltip title={t('Duplicate')} key="duplicate">
                  <GridActionsCellItem
                    icon={<ContentCopyIcon />}
                    label={t('Duplicate')}
                    className="textPrimary"
                    color="inherit"
                    onClick={() =>
                      navigate(`/admin/therapyplans/own/new/${row.id}`)
                    }
                  />
                </Tooltip>,
                <Tooltip title={t('View')} key="view">
                  <GridActionsCellItem
                    disabled={!row.id}
                    icon={<LinkIcon />}
                    label={t('Edit')}
                    className="textPrimary"
                    color="inherit"
                    onClick={() => navigate(route)}
                  />
                </Tooltip>,
                <Tooltip title={t('Delete')} key="tt-delete">
                  <GridActionsCellItem
                    disabled
                    icon={<DeleteIcon />}
                    label={t('Delete')}
                    color="inherit"
                  />
                </Tooltip>,
              ];
        },
      },
    ],
    [
      t,
      therapyplantype,
      isSuperAdmin,
      providerRef?.id,
      isOrganizationAdmin,
      navigate,
      handleDelete,
    ]
  );

  if (status === 'loading' || userProviderStatus === 'loading') {
    return <CircularProgress />;
  }

  return (
    <>
      <Paper variant="outlined" sx={{ my: 2, p: 2 }}>
        {error && (
          <Alert severity="error">
            <AlertTitle>
              <Trans>Error</Trans>
            </AlertTitle>
            {error.message}
          </Alert>
        )}
        {collectionError && (
          <Alert severity="error">
            <AlertTitle>
              <Trans>Error</Trans>
            </AlertTitle>
            {collectionError.message}
          </Alert>
        )}

        <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
          <Trans>Therapy Plans</Trans>
        </Typography>
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: 'title', sort: 'asc' }],
            },
          }}
          autoHeight
          rows={data || []}
          columns={columns}
          rowSelection={false}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
        {canAdd && (
          <Button
            component={Link}
            to={`/admin/therapyplans/${therapyplantype}/new`}
            variant="contained"
            startIcon={<AddIcon />}
            sx={{ mt: 2 }}
          >
            {t('New Therapy Plan')}
          </Button>
        )}
      </Paper>
      {/* <GenerateCourseFromPlanDialog
        therapyPlanId={generateId}
        generateDialogOpen={generateDialogOpen}
        setGenerateDialogOpen={setGenerateDialogOpen}
      /> */}
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogTitle || <Trans>Delete Therapy Plan</Trans>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogContent || (
              <Trans>Are you sure you want to delete this therapy plan?</Trans>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            <Trans>Cancel</Trans>
          </Button>
          <Button onClick={confirmDelete} color="primary" autoFocus>
            <Trans>Confirm</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
