import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';
import { useCallback } from 'react';

/**
 * Hook to delete app users by phone number (superadmin only)
 */
export function useDeleteAppUser() {
  const functions = useFunctions();

  return useCallback(
    /**
     * Deletes a user and all associated data from the app.
     *
     * @param {string} phoneNumber - The user's phone number in E.164 format
     * @param {boolean} deleteLicense - Whether to delete the license
     * @returns {Promise<{
     *   success: boolean,
     *   n_stats_deleted: number,
     *   n_diary_deleted: number,
     *   n_log_deleted: number,
     *   deleted_licence_id: string | null,
     *   deleted_licence_code: string | null,
     *   user_deleted: boolean,
     *   auth_user_deleted: boolean
     * }>} Result containing deletion status for each component
     * @throws {Error} If the deletion fails
     */
    async (phoneNumber: string, deleteLicense: boolean = false) => {
      try {
        const deleteAppUserFunction = httpsCallable(functions, 'appuserdelete');

        const result = await deleteAppUserFunction({
          phone: phoneNumber,
          delete_license: deleteLicense,
        });

        return result.data as {
          success: boolean;
          n_stats_deleted: number;
          n_diary_deleted: number;
          n_log_deleted: number;
          deleted_licence_id: string | null;
          deleted_licence_code: string | null;
          user_deleted: boolean;
          auth_user_deleted: boolean;
        };
      } catch (error: any) {
        console.error('Error deleting user:', error.message);
        throw error;
      }
    },
    [functions]
  );
}
