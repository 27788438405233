import { useTranslation } from 'react-i18next';
import {
  Alert,
  AlertTitle,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';
import { useDeleteAppUser } from '../firebase/useDeleteAppUser';

export default function DeleteAppUser() {
  const { t } = useTranslation();
  const deleteAppUser = useDeleteAppUser();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [result, setResult] = useState<any | null>(null);
  const [deleteLicense, setDeleteLicense] = useState(false);

  const handleDelete = async () => {
    if (!phoneNumber) {
      setError(t('Phone number is required'));
      return;
    }

    try {
      setIsDeleting(true);
      setError(null);
      setResult(null);

      const deleteResult = await deleteAppUser(phoneNumber, deleteLicense);
      setResult(deleteResult);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Card sx={{ mt: 2 }}>
      <CardHeader
        title={t('Delete App User')}
        subheader={t('Warning: This action cannot be undone')}
      />
      <CardContent>
        <Alert severity="warning" sx={{ mb: 2 }}>
          <AlertTitle>{t('Danger Zone')}</AlertTitle>
          {t(
            'This will permanently delete the user and all associated data, including:'
          )}
          <ul>
            <li>{t('User profile and authentication')}</li>
            <li>{t('Statistics and diary entries')}</li>
            <li>{t('Activity logs')}</li>
            <li>{t('License association')}</li>
          </ul>
        </Alert>

        <TextField
          fullWidth
          label={t('Phone Number')}
          placeholder="+358401234567"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          sx={{ mb: 2 }}
          helperText={t(
            'Enter phone number in the international format (e.g., +358401234567)'
          )}
        />

        <FormGroup
          sx={{ mb: 2, bgcolor: 'background.paper', p: 1, borderRadius: 1 }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={deleteLicense}
                onChange={(e) => setDeleteLicense(e.target.checked)}
                color="error"
              />
            }
            label={t(
              'Delete license completely (instead of just releasing it)'
            )}
          />
          <FormHelperText>
            {t(
              'By default, the license will be released and can be reassigned. Check this to permanently delete the license instead.'
            )}
          </FormHelperText>
        </FormGroup>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {result && (
          <Alert severity={result.success ? 'success' : 'error'} sx={{ mb: 2 }}>
            <AlertTitle>
              {result.success
                ? t('User Deleted Successfully')
                : t('Deletion Failed')}
            </AlertTitle>
            <Typography variant="body2">
              {t('Statistics deleted')}: {result.n_stats_deleted}
              <br />
              {t('Diary entries deleted')}: {result.n_diary_deleted}
              <br />
              {t('Logs deleted')}: {result.n_log_deleted}
              <br />
              {t('License released')}:{' '}
              {result.deleted_licence_code || t('None')}
              <br />
              {t('User data deleted')}:{' '}
              {result.user_deleted ? t('Yes') : t('No')}
              <br />
              {t('Auth user deleted')}:{' '}
              {result.auth_user_deleted ? t('Yes') : t('No')}
            </Typography>
          </Alert>
        )}

        <LoadingButton
          variant="contained"
          color="error"
          loading={isDeleting}
          onClick={handleDelete}
          disabled={!phoneNumber}
        >
          {t('Delete User')}
        </LoadingButton>
      </CardContent>
    </Card>
  );
}
