import { Trans, useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import {
  doc,
  collection,
  updateDoc,
  addDoc,
  Timestamp,
} from 'firebase/firestore';
import {
  TherapyPlan,
  TherapyPlanPhase,
  TranslatableField,
} from '../firebase/firebaseModels';
import {
  CardHeader,
  CircularProgress,
  Tooltip,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
  AlertTitle,
  Snackbar,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import LanguageSelector from '../components/LanguageSelector';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useFirestore } from 'reactfire';
import useTherapyPlanDoc from '../firebase/useTherapyPlanDoc';
import useUserProvider from '../firebase/useUserProvider';
import { getCurrentLanguageCode } from '../services/i18n';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFirebaseUser } from '../firebase/useFirebaseUser';
import CreatedModifiedTable from '../components/CreatedModifiedTable';
import usePortalUser from '../firebase/usePortalUser';
import TranslatableTextField from '../components/TranslatableTextField';
import TranslatableEditor from '../components/TranslatableEditor';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import uuid from 'react-native-uuid';
import TherapyPlanPhasesList from '../components/TherapyPlanEditor/TherapyPlanPhasesList';

export default function TherapyPlanEditor() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { therapyPlanId, therapyplantype, idToClone } = useParams<{
    therapyPlanId: string;
    therapyplantype: string;
    idToClone: string;
  }>();
  const firestore = useFirestore();
  const { email } = useFirebaseUser();
  const { isSuperAdmin, isOrganizationAdmin } = usePortalUser();

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogClose = useCallback(() => {
    setDialogOpen(false);
  }, []);
  const [localError, setLocalError] = useState<Error | null>(null);
  const [updateError, setUpdateError] = useState<Error | null>(null);
  const [addError, setAddError] = useState<Error | null>(null);

  const {
    data: userProviderData,
    status: userProviderStatus,
    error: userProviderError,
    providerRef,
  } = useUserProvider();

  const [defaultLanguage, setDefaultLanguage] = useState(
    getCurrentLanguageCode()
  );
  const [language, setLanguage] = useState(defaultLanguage);

  const [title, setTitle] = useState<TranslatableField>({
    [defaultLanguage]: '',
  });
  const [description, setDescription] = useState<TranslatableField>({
    [defaultLanguage]: '',
  });

  const [phases, setPhases] = useState<TherapyPlanPhase[]>([]);

  const [defaultInterval, setDefaultInterval] = useState<number>(7);
  // const [tasks, setTasks] = useState<TaskPoolTask[]>([]);
  const { status, error, data } = useTherapyPlanDoc(
    therapyPlanId as string,
    idToClone
  );

  const [updating, setUpdating] = useState(false);
  const [updateSuccessful, setUpdateSuccessful] = useState(false);

  const [dialogDescription, setDialogDescription] = useState<string>('');
  const [dialogType, setDialogType] = useState<
    'save' | 'promote' | 'demote' | null
  >(null);

  const [amOwner, setAmOwner] = useState<boolean>(false);
  const [isPublic, setIsPublic] = useState<boolean>(false);

  const isGloballyAvailable = isPublic && data?.provider === null;

  const canEdit =
    isSuperAdmin ||
    amOwner ||
    (!amOwner && data?.provider?.id === providerRef?.id && isOrganizationAdmin);

  // const [generateId, setGenerateId] = useState<string | null>(null);

  // const handleGenerate = useCallback(
  //   async (therapyPlanId: string) => {
  //     if (!userProviderData?.id || !therapyPlanId) {
  //       return;
  //     }

  //     setGenerateId(therapyPlanId);
  //     setGenerateDialogOpen(true);
  //   },
  //   [userProviderData?.id]
  // );

  useEffect(() => {
    if (data && status === 'success') {
      // Only set these values if they haven't been initialized yet
      // or if we're loading a different therapy plan
      setTitle(data.title);
      setDescription(data.description);
      const phasesWithIds = (data.phases || []).map((phase) => ({
        ...phase,
        id: phase.id || uuid.v4().toString(),
      }));
      setPhases(phasesWithIds);
      setDefaultInterval(data.defaultInterval || 7);
      setDefaultLanguage(data.defaultLanguage);
      setAmOwner(data.amOwner || false);
      if (therapyPlanId === 'new') {
        setIsPublic(therapyplantype !== 'own');
      } else {
        setIsPublic(data.public || false);
      }
    }
  }, [data, status, therapyPlanId, therapyplantype]);

  const updateTherapyPlan = useCallback(async () => {
    if (!title || !description || !userProviderData?.id || !email) {
      return;
    }
    setUpdating(true);
    setUpdateSuccessful(false);
    let languages = Object.keys(title).filter((lang) => !!title[lang]);
    Object.keys(description).forEach((lang) => {
      if (!!description[lang] && !languages.includes(lang)) {
        languages.push(lang);
      }
    });

    const updateData: Partial<TherapyPlan> = {
      title,
      description,
      phases,
      languages,
      modified: Timestamp.now(),
      modifiedBy: email,
      defaultLanguage,
      defaultInterval,
      public: isPublic,
    };
    if (therapyPlanId && therapyPlanId !== 'new') {
      const therapyPlanRef = doc(firestore, 'TherapyPlan', therapyPlanId);
      updateDoc(therapyPlanRef, updateData)
        .then(async () => {
          setUpdating(false);
          setUpdateSuccessful(true);
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
          setUpdateError(error);
          setUpdating(false);
        });
    } else {
      const TherapyPlanRef = collection(firestore, 'TherapyPlan');
      addDoc(TherapyPlanRef, { ...data, ...updateData })
        .then(async (newDocRef) => {
          setUpdating(false);
          setUpdateSuccessful(true);
          navigate(`/admin/therapyplans/own/${newDocRef.id}`);
        })
        .catch((error) => {
          console.error('Error adding document: ', error);
          setAddError(error);
          setUpdating(false);
        });
    }
  }, [
    title,
    description,
    userProviderData?.id,
    email,
    phases,
    defaultLanguage,
    defaultInterval,
    isPublic,
    therapyPlanId,
    firestore,
    data,
    navigate,
  ]);

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!title || !description || !userProviderData?.id || !email) {
        setLocalError(new Error('All fields are required'));
        return;
      }
      if (!amOwner && !isSuperAdmin) {
        setLocalError(new Error('You are not the owner of this plan'));
        return;
      }
      if (!amOwner) {
        setDialogOpen(true);
        setDialogDescription(
          t(
            'You are not the owner of this plan. Are you sure you want to save changes?'
          )
        );
        setDialogType('save');
      } else {
        updateTherapyPlan();
      }
    },
    [
      amOwner,
      description,
      email,
      isSuperAdmin,
      t,
      title,
      updateTherapyPlan,
      userProviderData?.id,
    ]
  );

  const confirmPromoteToGlobal = useCallback(() => {
    if (!therapyPlanId) {
      return;
    }
    const planRef = doc(firestore, 'TherapyPlan', therapyPlanId);
    updateDoc(planRef, {
      public: true,
      provider: null,
    });
  }, [firestore, therapyPlanId]);

  const confirmDemoteToPrivate = useCallback(() => {
    if (!therapyPlanId || !providerRef) {
      return;
    }
    const planRef = doc(firestore, 'TherapyPlan', therapyPlanId);
    updateDoc(planRef, {
      public: false,
      provider: providerRef,
    });
  }, [firestore, providerRef, therapyPlanId]);

  const promoteToGlobal = useCallback(() => {
    if (!isSuperAdmin) {
      return;
    }
    setDialogDescription(t('Are you sure you want to make this plan global?'));
    setDialogOpen(true);
    setDialogType('promote');
  }, [isSuperAdmin, t]);

  const demoteToPrivate = useCallback(() => {
    if (!isSuperAdmin) {
      return;
    }
    setDialogDescription(t('Are you sure you want to make this plan private?'));
    setDialogOpen(true);
    setDialogType('demote');
  }, [isSuperAdmin, t]);

  const handleConfirm = useCallback(() => {
    setDialogOpen(false);
    switch (dialogType) {
      case 'save':
        updateTherapyPlan();
        break;
      case 'promote':
        confirmPromoteToGlobal();
        break;
      case 'demote':
        confirmDemoteToPrivate();
        break;
      default:
        break;
    }
  }, [
    confirmDemoteToPrivate,
    confirmPromoteToGlobal,
    dialogType,
    updateTherapyPlan,
  ]);

  const onChangeDefaultInterval = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(e.target.value, 10);
      if (defaultInterval !== value) {
        setDefaultInterval(value);
      }
    },
    [defaultInterval]
  );

  const handlePublicChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setIsPublic(event.target.checked),
    []
  );

  const titleExistsInPrimaryLanguage = !!title[defaultLanguage];

  const onDragEnd = useCallback(
    (result: any) => {
      if (!result.destination) {
        return;
      }

      const sourceIndex = result.source.index;
      const destIndex = result.destination.index;

      const newPhases = Array.from(phases);
      const [removed] = newPhases.splice(sourceIndex, 1);
      newPhases.splice(destIndex, 0, removed);

      setPhases(newPhases);

      if (
        therapyPlanId &&
        therapyPlanId !== 'new' &&
        userProviderData?.id &&
        email
      ) {
        setUpdating(true);
        const therapyPlanRef = doc(firestore, 'TherapyPlan', therapyPlanId);
        updateDoc(therapyPlanRef, {
          phases: newPhases,
          modified: Timestamp.now(),
          modifiedBy: email,
        })
          .then(() => {
            setUpdateSuccessful(true);
            setUpdating(false);
          })
          .catch((error) => {
            console.error('Error updating document: ', error);
            setUpdateError(error);
            setUpdating(false);
          });
      }
    },
    [phases, therapyPlanId, userProviderData?.id, email, firestore]
  );

  return (
    <>
      {status === 'loading' && <CircularProgress sx={{ mt: 2 }} />}
      {error && (
        <Alert severity="error">
          <AlertTitle>
            <Trans>Error Getting Therapy Plan</Trans>
          </AlertTitle>
          <Trans>{error.message}</Trans>
        </Alert>
      )}
      {userProviderError && (
        <Alert severity="error">
          <AlertTitle>
            <Trans>Error Getting User</Trans>
          </AlertTitle>
          <Trans>{userProviderError.message}</Trans>
        </Alert>
      )}
      {status === 'success' && (
        <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
          <Card sx={{ mt: 2 }} variant="outlined">
            <CardHeader
              title={t('Basics')}
              subheader={t('Select the language to edit')}
              action={
                <LanguageSelector
                  disabled={!canEdit}
                  language={language}
                  defaultLanguage={defaultLanguage}
                  setLanguage={setLanguage}
                  setDefaultLanguage={setDefaultLanguage}
                />
              }
            />
            <CardContent>
              <TranslatableTextField
                key={`plan-title-${language}`}
                name={`plan-title-${language}`}
                disabled={!canEdit}
                label={t('Therapy Plan Name')}
                helperText={
                  !titleExistsInPrimaryLanguage
                    ? t('Name is required in primary language')
                    : undefined
                }
                error={!titleExistsInPrimaryLanguage}
                language={language}
                type="title"
                setValue={setTitle}
                value={title}
                placeholder={t('Enter Name')}
              />
              <TranslatableEditor
                key={`plan-description-${language}`}
                name={`plan-description-${language}`}
                disabled={!canEdit}
                placeholder={t('Description')}
                value={description}
                setValue={setDescription}
                language={language}
                type="description"
              />
              <br />
              <Tooltip
                title={t(
                  'The number of days that will be automatically added to the start date of each phase.'
                )}
              >
                <TextField
                  label={t('Default Interval (Days)')}
                  name="default-interval"
                  disabled={!canEdit}
                  placeholder={t('Enter Default Interval, e.g. 7')}
                  type="number"
                  value={defaultInterval}
                  onChange={onChangeDefaultInterval}
                  required
                />
              </Tooltip>
            </CardContent>
          </Card>
          <Box>
            <Typography variant="h5" sx={{ mt: 2 }}>
              {t('Phases for this Plan')}
            </Typography>
            <TherapyPlanPhasesList
              key={`phases-${therapyPlanId}`}
              phases={phases}
              setPhases={setPhases}
              onDragEnd={onDragEnd}
              canEdit={canEdit}
              language={language}
              defaultLanguage={defaultLanguage}
            />
            {canEdit && (
              <LoadingButton
                loading={updating}
                variant="contained"
                sx={{ mt: 2 }}
                disabled={updating || !titleExistsInPrimaryLanguage || !canEdit}
                onClick={() => {
                  setPhases([
                    ...phases,
                    {
                      id: uuid.v4().toString(),
                      title: { [defaultLanguage]: '' },
                      order: phases.length,
                      phaseTasks: [],
                    },
                  ]);
                }}
              >
                <AddIcon />
                {t('Add Phase')}
              </LoadingButton>
            )}
          </Box>
          {!isGloballyAvailable && (isOrganizationAdmin || isSuperAdmin) && (
            <Card sx={{ mt: 2 }} variant="outlined">
              <CardHeader
                title={t('Administration')}
                subheader={t('Options')}
              />
              <CardContent>
                <FormGroup>
                  <FormControlLabel
                    disabled={!isOrganizationAdmin && !isSuperAdmin}
                    control={
                      <Checkbox
                        checked={isPublic}
                        onChange={handlePublicChange}
                      />
                    }
                    label={t(
                      'Show this therapy plan to all users of organization'
                    )}
                  />
                </FormGroup>
              </CardContent>
            </Card>
          )}
          {updateSuccessful && (
            <Alert
              severity="success"
              sx={{ mt: 2 }}
              onClose={() => setUpdateSuccessful(false)}
            >
              {t('Save successful')}
            </Alert>
          )}
          {canEdit && (
            <LoadingButton
              loading={updating}
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
              disabled={updating || !titleExistsInPrimaryLanguage}
            >
              {t('Save')}
            </LoadingButton>
          )}
          {isSuperAdmin && isGloballyAvailable && (
            <Button
              color="error"
              variant="contained"
              sx={{ mt: 2, ml: 2 }}
              onClick={demoteToPrivate}
            >
              {t('Make Private')}
            </Button>
          )}
          {isSuperAdmin && !isGloballyAvailable && (
            <Button
              color="error"
              variant="contained"
              sx={{ mt: 2, ml: 2 }}
              onClick={promoteToGlobal}
            >
              {t('Make Default')}
            </Button>
          )}
          {/* <Button
            startIcon={<AddBoxIcon />}
            color="success"
            variant="contained"
            sx={{ mt: 2, ml: 2 }}
            disabled={updating || !therapyPlanId || therapyPlanId === 'new'}
            onClick={() => handleGenerate(therapyPlanId || '')}
          >
            {t('Create Therapy Course From Plan')}
          </Button> */}
          <CreatedModifiedTable isNew={therapyPlanId === 'new'} data={data} />
        </Box>
      )}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Trans>Are you sure?</Trans>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogDescription}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>
            <Trans>Cancel</Trans>
          </Button>
          <Button onClick={handleConfirm} autoFocus>
            <Trans>OK</Trans>
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={!!localError}
        autoHideDuration={6000}
        onClose={() => setLocalError(null)}
      >
        <Alert onClose={() => setLocalError(null)} severity="error">
          <AlertTitle>
            <Trans>Error</Trans>
          </AlertTitle>
          <Trans>{localError?.message}</Trans>
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!updateError}
        autoHideDuration={6000}
        onClose={() => setUpdateError(null)}
      >
        <Alert onClose={() => setUpdateError(null)} severity="error">
          <AlertTitle>
            <Trans>Error Saving Therapy Plan</Trans>
          </AlertTitle>
          <Trans>{updateError?.message}</Trans>
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!addError}
        autoHideDuration={6000}
        onClose={() => setAddError(null)}
      >
        <Alert onClose={() => setAddError(null)} severity="error">
          <AlertTitle>
            <Trans>Error Adding Therapy Plan</Trans>
          </AlertTitle>
          <Trans>{addError?.message}</Trans>
        </Alert>
      </Snackbar>
    </>
  );
}
