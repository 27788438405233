import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TherapyCoursePhase,
  TaskPoolTask,
  TaskPoolTaskCategory,
} from '../../firebase/firebaseModels';
import { getTranslatableField } from '../../services/i18n';
import {
  determineDocType,
  TaskPoolOption,
} from '../../hooks/useTaskPoolOptions';
import { useFirebaseUser } from '../../firebase/useFirebaseUser';

export function usePhaseTasksValue(
  phaseTasks: TherapyCoursePhase['phaseTasks'],
  taskPoolData: TaskPoolTask[] | null,
  categoryData: TaskPoolTaskCategory[] | null
) {
  const { t } = useTranslation();
  const { email } = useFirebaseUser();
  const phaseTasksValue = useMemo(() => {
    if (!email || !taskPoolData || !categoryData || !phaseTasks) {
      return null;
    }
    return phaseTasks
      .map((task) => {
        const tpt = taskPoolData?.find((tpt) => tpt.id === task.id);
        if (!tpt || !task.id) {
          return null;
        }
        const category = categoryData?.find(
          (cat) => cat.id === tpt?.category?.id
        );
        return {
          id: tpt?.id as string,
          title: tpt?.title,
          docType: determineDocType(tpt, email),
          translatedTitle: tpt?.title
            ? getTranslatableField(tpt.title)
            : (tpt?.id as string),
          categoryTitle: category
            ? getTranslatableField(category.title)
            : t('Uncategorized'),
          categoryColor: category?.color || '#000000',
          taskType: tpt?.type || 'generic',
        } as TaskPoolOption;
      })
      .filter((v) => v !== null)
      .sort((a, b) => {
        const sortTitleA = a!.categoryTitle + '/' + a!.translatedTitle;
        const sortTitleB = b!.categoryTitle + '/' + b!.translatedTitle;
        return sortTitleA.localeCompare(sortTitleB);
      });
  }, [phaseTasks, taskPoolData, categoryData, email, t]);
  return phaseTasksValue as TaskPoolOption[] | null;
}
