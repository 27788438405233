import {
  compareTimeOfDay,
  getRelativeTimeAsNumber,
  getRelativeTimeOfDayFromNumber,
  RelativeTimeOfDay,
} from '../../utils';
import { StatisticDay } from './types';
import { Timestamp } from '@firebase/firestore';

/**
 * Calculate the min, max and average values for a row
 * @param type the type of the row
 * @param statisticsSlice the slice of statistics to calculate the min, max and average values for
 * @param key the key of the statistic to calculate the min, max and average values for
 * @param maxDateAsStr the maximum date as a string
 * @param label the label of the statistic to calculate the min, max and average values for
 * @param dismissZero if true, zero values will be ignored
 * @returns StatisticsRowDataType (see below)
 */
export function calculateRowData(
  type: string,
  statisticsSlice: StatisticDay[],
  key: keyof StatisticDay,
  maxDateAsStr: string,
  dismissZero: boolean | undefined
) {
  switch (type) {
    case 'time': {
      return calculateTimeRowData(statisticsSlice, key, maxDateAsStr);
    }
    case 'duration': {
      return calculateDurationRowData(statisticsSlice, key, dismissZero);
    }
    case 'percentage': {
      return calculatePercentageData(statisticsSlice, key, dismissZero);
    }
    default:
      return { min: null, max: null, average: null, nValues: 0 };
  }
}

/**
 * Calculate the min, max and average values for a percentage row
 *
 * @param statisticsSlice the slice of statistics to calculate the min, max and average values for
 * @param key the key of the statistic to calculate the min, max and average values for
 * @param dismissZero if true, zero values will be ignored
 * @param label the label of the statistic to calculate the min, max and average values for
 * @returns the min, max and average values for the percentage row
 */
function calculatePercentageData(
  statisticsSlice: StatisticDay[],
  key: keyof StatisticDay,
  dismissZero: boolean | undefined
) {
  const minPercentage = statisticsSlice.reduce<number | null>(
    (min, statistic) => {
      // console.log('statistic', statistic);
      const value = statistic[key];
      if (value === null) {
        return min;
      }
      if (dismissZero && value === 0) {
        return min;
      }
      return min === null ? (value as number) : Math.min(min, value as number);
    },
    null
  );

  const maxPercentage = statisticsSlice.reduce<number | null>(
    (max, statistic) => {
      const value = statistic[key];
      if (value === null) {
        return max;
      }
      if (dismissZero && value === 0) {
        return max;
      }
      return max === null ? (value as number) : Math.max(max, value as number);
    },
    null
  );
  let nValues = 0;
  const averagePercentage = statisticsSlice.reduce<number>(
    (average, statistic) => {
      const value = statistic[key];
      if (value === null) {
        return average;
      }
      if (dismissZero && value === 0) {
        return average;
      }
      nValues++;
      return average + (value as number);
    },
    0
  );

  return {
    min: minPercentage,
    max: maxPercentage,
    average: nValues ? averagePercentage / nValues : null,
    nValues,
  };
}

/**
 * Calculate the min, max and average values for a duration row
 *
 * @param statisticsSlice the slice of statistics to calculate the min, max and average values for
 * @param key the key of the statistic to calculate the min, max and average values for
 * @param dismissZero if true, zero values will be ignored
 * @param label the label of the statistic to calculate the min, max and average values for
 * @returns the min, max and average values for the duration row
 */
export function calculateDurationRowData(
  statisticsSlice: StatisticDay[],
  key: keyof StatisticDay,
  dismissZero: boolean | undefined
) {
  const minDuration = statisticsSlice.reduce<number | null>(
    (min, statistic) => {
      const value = statistic[key];
      if (value === null) {
        return min;
      }
      if (dismissZero && value === 0) {
        return min;
      }
      return min === null ? (value as number) : Math.min(min, value as number);
    },
    null
  );
  const maxDuration = statisticsSlice.reduce<number | null>(
    (max, statistic) => {
      const value = statistic[key];
      if (value === null) {
        return max;
      }
      if (dismissZero && value === 0) {
        return max;
      }
      return max === null
        ? (value as number)
        : Math.max(max as number, value as number);
    },
    null
  );
  let nValues = 0;
  const averageDuration: number = statisticsSlice.reduce<number>(
    (average, statistic) => {
      const value = statistic[key];
      if (value === null) {
        return average;
      }
      if (dismissZero && value === 0) {
        return average;
      }
      nValues++;
      return average + (value as number);
    },
    0
  );
  return {
    min: minDuration,
    max: maxDuration,
    average: nValues ? averageDuration / nValues : null,
    nValues,
  };
}
/**
 * Calculate the min, max and average values for a time row
 *
 * @param statisticsSlice the slice of statistics to calculate the min, max and average values for
 * @param key the key of the statistic to calculate the min, max and average values for
 * @param maxDateAsStr the maximum date as a string
 * @param label the label of the statistic to calculate the min, max and average values for
 * @returns the min, max and average values for the time row
 */
export function calculateTimeRowData(
  statisticsSlice: StatisticDay[],
  key: keyof StatisticDay,
  maxDateAsStr: string
) {
  const min = statisticsSlice.reduce<RelativeTimeOfDay>(
    (prevMin, statistic) => {
      const timeofday = statistic[key] as Timestamp | null;
      if (!timeofday) {
        return prevMin;
      }
      const rtod: RelativeTimeOfDay = {
        timeofday: timeofday.toDate(),
        marking_date: statistic.marking_date,
      };
      if (!prevMin.timeofday) {
        return rtod;
      }
      return compareTimeOfDay(rtod, prevMin) ? rtod : prevMin;
    },
    { timeofday: null, marking_date: maxDateAsStr }
  );
  const max = statisticsSlice.reduce<RelativeTimeOfDay>(
    (prevMax, statistic) => {
      const timeofday = statistic[key] as Timestamp | null;
      if (!timeofday) {
        return prevMax;
      }
      const rtod: RelativeTimeOfDay = {
        timeofday: timeofday.toDate(),
        marking_date: statistic.marking_date,
      };
      if (!prevMax.timeofday) {
        return rtod;
      }
      return compareTimeOfDay(rtod, prevMax) ? prevMax : rtod;
    },
    { timeofday: null, marking_date: '1970-01-01' }
  );
  let nValues = 0;
  const sumOfAllRelativeTimeOfDay = statisticsSlice.reduce<number>(
    (sum, statistic) => {
      const value = statistic[key] as Timestamp | null;
      if (value === null) {
        return sum;
      }
      nValues++;
      const rtod: RelativeTimeOfDay = {
        timeofday: value.toDate(),
        marking_date: statistic.marking_date,
      };
      const relativeTimeOfDayAsNumber =
        getRelativeTimeAsNumber(rtod)?.timeofdayAsNumber ?? 0;
      return sum + relativeTimeOfDayAsNumber;
    },
    0
  );
  const avg =
    nValues > 0
      ? getRelativeTimeOfDayFromNumber({
          timeofdayAsNumber: sumOfAllRelativeTimeOfDay / nValues,
          marking_date: maxDateAsStr,
        })
      : null;

  return {
    min: min.timeofday,
    max: max.timeofday,
    average: avg?.timeofday,
    nValues,
  };
}
