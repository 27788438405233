import { Timestamp } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';
import { useCallback, useMemo, useState } from 'react';
import { usePatient, useAppUser } from '../contexts/PatientContext';
import { useCalculateStatisticsDataHash } from './useCalculateStatisticsDataHash';
import { debounce } from 'lodash';

const DISABLE_TIMEOUT = 5000; // five seconds
const CACHE_DURATION = 60 * 60 * 1000; // one hour in milliseconds

export function useRefreshPatientStatistics(
  therapyCoursePatientId: string | null,
  startDate: Timestamp | null,
  endDate: Timestamp | null
) {
  const startDateStr = useMemo(
    () => startDate?.toDate().toISOString() ?? null,
    [startDate]
  );
  const endDateStr = useMemo(
    () => endDate?.toDate().toISOString() ?? null,
    [endDate]
  );

  const [lastRefresh, setLastRefresh] = useState<Date | null>(null);
  const [disabled, setDisabled] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const functions = useFunctions();
  const getStatistics = httpsCallable(functions, 'getstatistics');

  const debouncedGetStatistics = useMemo(
    () =>
      debounce(
        (params: any) => getStatistics(params),
        3000, // 3 second delay
        { leading: true, trailing: false }
      ),
    [getStatistics]
  );

  const patient = usePatient(therapyCoursePatientId);
  const appUser = useAppUser(patient);
  const appUserId = appUser?.id;

  const statisticsHash = useCalculateStatisticsDataHash(
    therapyCoursePatientId,
    startDateStr,
    endDateStr
  );

  const lastStatisticsCacheDate = useMemo(
    () =>
      statisticsHash ? appUser?.statisticsCacheDate?.[statisticsHash] : null,
    [appUser, statisticsHash]
  );

  const refreshPatientStatistics = useCallback(
    (forceRefresh = false) => {
      if (
        !therapyCoursePatientId ||
        !statisticsHash ||
        !appUserId ||
        isUpdating
      ) {
        return;
      }

      if (
        !forceRefresh &&
        lastStatisticsCacheDate &&
        Date.now() - lastStatisticsCacheDate.toMillis() < CACHE_DURATION
      ) {
        return;
      }

      setLastRefresh(new Date());
      setDisabled(true);
      setIsUpdating(true);

      setTimeout(() => {
        setDisabled(false);
      }, DISABLE_TIMEOUT);

      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      debouncedGetStatistics({
        userId: appUserId,
        forceRefresh,
        timezone,
        statisticsHash,
        startDate: startDateStr,
        endDate: endDateStr,
      })
        .then((result) => {
          // console.log('result', JSON.parse(result.data as string));
        })
        .catch((error) => {
          console.error('Error refreshing patient statistics', error);
        })
        .finally(() => {
          setIsUpdating(false);
        });
    },
    [
      therapyCoursePatientId,
      statisticsHash,
      appUserId,
      isUpdating,
      lastStatisticsCacheDate,
      debouncedGetStatistics,
      startDateStr,
      endDateStr,
    ]
  );

  return {
    refreshPatientStatistics,
    statisticsHash,
    lastRefresh,
    disabled,
    isUpdating,
  };
}
