import { Trans, useTranslation } from 'react-i18next';
import {
  Box,
  TextField,
  Typography,
  Autocomplete,
  Divider,
  Tooltip,
  CircularProgress,
  AlertTitle,
} from '@mui/material';
import LanguageSelector from '../components/LanguageSelector';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import LoadingButton from '@mui/lab/LoadingButton';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Alert from '@mui/material/Alert';

import { useCallback, useMemo, useState } from 'react';
import { DocumentReference, Timestamp } from 'firebase/firestore';
import { Patient, TranslatableField } from '../firebase/firebaseModels';
import useUserProvider from '../firebase/useUserProvider';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getCurrentLanguageCode, getTranslatableField } from '../services/i18n';
import { useGenerateCourseFromPlan } from '../hooks/useGenerateCourseFromPlan';
import { useFirebaseUser } from '../firebase/useFirebaseUser';
import useTherapyPlanCollection from '../firebase/useTherapyPlanCollection';
import usePatientCollection from '../firebase/usePatientCollection';
import TranslatableTextField from '../components/TranslatableTextField';
import useTaskPoolCollection from '../firebase/useTaskPoolCollection';
import PatientManager from '../components/PatientManager';

/**
 * Component for generating new therapy courses from templates
 * Allows selection of course type, patient, start date, and therapy plan
 * Validates language availability and warns about missing translations
 */
export default function GenerateCourse() {
  const { t } = useTranslation();
  const [therapycoursetype, setTherapyCourseType] = useState<
    'group' | 'individual'
  >('group');
  const { email } = useFirebaseUser();

  const { data: userProviderData, status: userProviderStatus } =
    useUserProvider();

  const { data: patientCollectionData } = usePatientCollection();

  const { data: taskPoolData } = useTaskPoolCollection();

  const [patient, setPatient] = useState<DocumentReference<Patient> | null>(
    null
  );

  const [generatedCourseTitle, setGeneratedCourseTitle] =
    useState<TranslatableField>({});
  const [therapyStartDatetime, setTherapyStartDatetime] =
    useState<Timestamp | null>(null);

  const [defaultLanguage, setDefaultLanguage] = useState(
    getCurrentLanguageCode()
  );
  const [language, setLanguage] = useState(defaultLanguage);

  const { status: planCollectionStatus, data: planData } =
    useTherapyPlanCollection();
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);

  // generate warnings if the plan or any tasks within the plan are not available in the selected language,
  // or the plan doesn't have any phases or tasks
  const warnings = useMemo(() => {
    const warnings = [];
    if (planData) {
      const plan = planData.find((p) => p.id === selectedPlan);
      if (plan) {
        const missingLanguages = !Object.keys(plan.title).includes(language);
        if (missingLanguages) {
          warnings.push('The plan is not available in the selected language.');
        }
        const allPlanTasks = plan.phases
          .flatMap((phase) => phase.phaseTasks)
          .map((tpt) => taskPoolData?.find((tp) => tp.id === tpt.id) || null);
        const missingLanguagesInTasks = allPlanTasks.filter(
          (task) => !!task && !Object.keys(task.title).includes(language)
        );
        if (missingLanguagesInTasks.length > 0) {
          warnings.push(
            'Some tasks in the plan are not available in the selected language.'
          );
        }
        if (plan.phases.length === 0) {
          warnings.push('The plan has no phases.');
        }
        if (allPlanTasks.length === 0) {
          warnings.push('The plan has no tasks.');
        }
      }
    }
    return warnings;
  }, [planData, selectedPlan, language, taskPoolData]);

  const patientCodeAndRemark = useMemo(() => {
    const p = patientCollectionData?.find((pcp) => pcp.id === patient?.id);
    return p ? `${p.licenceCode} / ${p.remark}` : '';
  }, [patientCollectionData, patient]);

  const therapyPlanOptions = useMemo(() => {
    return planData?.map((plan) => ({
      value: plan.id,
      label: getTranslatableField(plan.title),
    }));
  }, [planData]);

  const { generateCourseFromPlan, generateError } = useGenerateCourseFromPlan(
    therapyStartDatetime,
    userProviderData,
    email,
    selectedPlan,
    therapycoursetype === 'individual'
      ? {
          [defaultLanguage]: patientCodeAndRemark,
        }
      : generatedCourseTitle,
    therapycoursetype === 'individual' ? patient : null
  );

  const onChangeStartTime = useCallback((dayjsDate: Dayjs | null) => {
    const date = dayjsDate ? Timestamp.fromDate(dayjsDate.toDate()) : null;
    setTherapyStartDatetime(date);
  }, []);

  const startTimeValue = useMemo(
    () => (therapyStartDatetime ? dayjs(therapyStartDatetime.toDate()) : null),
    [therapyStartDatetime]
  );

  if (
    !userProviderData?.id ||
    !email ||
    planCollectionStatus === 'loading' ||
    userProviderStatus === 'loading'
  ) {
    return <CircularProgress />;
  }
  const titleExistsInPrimaryLanguage = !!generatedCourseTitle[defaultLanguage];

  return (
    <>
      <Card sx={{ mt: 2 }}>
        <CardHeader
          title={t('New Therapy Course')}
          action={
            <LanguageSelector
              language={language}
              defaultLanguage={defaultLanguage}
              setLanguage={setLanguage}
              setDefaultLanguage={setDefaultLanguage}
            />
          }
        />
        <CardContent>
          <ToggleButtonGroup
            value={therapycoursetype || 'group'}
            exclusive
            color="primary"
            onChange={(e, value) => setTherapyCourseType(value)}
          >
            <ToggleButton value="group">
              <Trans>Group Therapy</Trans>
            </ToggleButton>
            <ToggleButton value="individual">
              <Trans>Individual Therapy</Trans>
            </ToggleButton>
          </ToggleButtonGroup>
          <Divider sx={{ mt: 2 }} />
          {therapycoursetype === 'individual' ? (
            <Box>
              <PatientManager
                patients={patient ? [patient] : []}
                setPatients={(newPatients) =>
                  setPatient(newPatients[0] || null)
                }
                email={email}
                isGroup={false}
              />
            </Box>
          ) : (
            <>
              <Tooltip
                title={t(
                  'The name of the group therapy course that will be created. Choose a name which helps you identify the group therapy course.'
                )}
              >
                <Typography variant="body1" sx={{ mt: 2 }}>
                  <Trans>Please enter a name the group therapy course.</Trans>
                </Typography>
              </Tooltip>
              <TranslatableTextField
                key={`group-title-${language}`}
                name={`group-title-${language}`}
                label={t('Group Name')}
                helperText={
                  !titleExistsInPrimaryLanguage
                    ? t('Name is required in primary language')
                    : undefined
                }
                error={!titleExistsInPrimaryLanguage}
                language={language}
                type="title"
                setValue={setGeneratedCourseTitle}
                value={generatedCourseTitle}
                placeholder={t('Enter Name')}
                sx={{ mt: 2 }}
              />
            </>
          )}
          <Divider sx={{ mt: 2 }} />
          <Tooltip
            title={t(
              'The date of the first session of the course. The field can be left empty.'
            )}
          >
            <div style={{ display: 'inline-block' }}>
              <DatePicker
                sx={{ mt: 2 }}
                label={t('Start Date')}
                value={startTimeValue}
                onChange={onChangeStartTime}
              />
            </div>
          </Tooltip>
          <br />
          <Typography variant="caption" sx={{ mt: 2 }}>
            <Trans>An optional start date for the course.</Trans>
          </Typography>
          <Divider sx={{ mt: 2 }} />
          <Tooltip
            title={t(
              'The therapy plan to generate the course from. If left empty, a blank course with no pre-defined tasks and just the first phase will be created.'
            )}
          >
            <Autocomplete
              sx={{ mt: 2 }}
              options={therapyPlanOptions || []}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              blurOnSelect
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="therapy-plan"
                  label={t('Select Therapy Plan...')}
                  placeholder={t('Create blank course')}
                />
              )}
              value={
                therapyPlanOptions?.find(
                  (option) => option.value === selectedPlan
                ) || null
              }
              onChange={(_, newValue) =>
                setSelectedPlan(newValue?.value || null)
              }
            />
          </Tooltip>
          <Typography variant="caption" sx={{ mt: 2 }}>
            <Trans>
              Please select a therapy plan to generate the course from or leave
              empty to create a blank course.
            </Trans>
          </Typography>
          {warnings.length > 0 && (
            <Box sx={{ mt: 2 }}>
              {warnings.map((warning, index) => (
                <Alert key={index} severity="warning" sx={{ mb: 1 }}>
                  <AlertTitle>
                    <Trans>Warning</Trans>
                  </AlertTitle>
                  <Trans>{warning}</Trans>
                </Alert>
              ))}
            </Box>
          )}
          {generateError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {generateError.message}
            </Alert>
          )}
        </CardContent>
      </Card>
      <LoadingButton
        type="submit"
        variant="contained"
        sx={{ mt: 2 }}
        disabled={
          therapycoursetype === 'group'
            ? !generatedCourseTitle[language]
            : !patient
        }
        onClick={() =>
          generateCourseFromPlan(therapycoursetype, defaultLanguage)
        }
        color="primary"
        autoFocus
      >
        <Trans>Create</Trans>
      </LoadingButton>
    </>
  );
}
