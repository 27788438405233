import { Trans, useTranslation } from 'react-i18next';
import {
  Alert,
  AlertColor,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  Snackbar,
  AlertTitle,
} from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import {
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Patient,
  TherapyCourse,
  TherapyCoursePhase,
} from '../../firebase/firebaseModels';
import { useFirebaseUser } from '../../firebase/useFirebaseUser';
import { useRefreshPatientStatistics } from '../../hooks/useRefreshPatientStatistics';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { LoadingButton } from '@mui/lab';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs, { Dayjs } from 'dayjs';
import RefreshIcon from '@mui/icons-material/Refresh';
import useRequestToViewPatientData from '../../firebase/useRequestToViewUserData';
import AddIcon from '@mui/icons-material/Add';
import useStatisticsCacheDoc from '../../firebase/useStatisticsCacheDoc';
import { useMaxMarkingDate } from './useMaxMarkingDate';
import {
  customTabA11yProps,
  CustomTabPanel,
} from '../../components/CustomTabPanel';
import ProgressTab from './ProgressTab';
import StatisticsTableTab from './StatisticsTableTab';
import PatientPhaseTasks from './PatientPhaseTasks';
import DayGraphTab from './DayGraphTab';
import { useAppUser } from '../../contexts/PatientContext';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import UpdateIcon from '@mui/icons-material/Update';
import { ButtonGroup, Tooltip } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import PendingIcon from '@mui/icons-material/Pending';

type PatientViewProps = {
  therapyCourseId: string;
  therapyCoursePatientId: string;
  course: TherapyCourse;
  patient: Patient;
  phase: TherapyCoursePhase;
  currentStartDate: Timestamp | null;
  currentEndDate: Timestamp | null;
  setCurrentStartDate: (value: SetStateAction<Timestamp | null>) => void;
  setCurrentEndDate: (value: SetStateAction<Timestamp | null>) => void;
  phaseIndex: number;
};

export default function PatientView(props: PatientViewProps) {
  const { t } = useTranslation();
  const {
    patient,
    therapyCourseId,
    therapyCoursePatientId,
    currentStartDate,
    setCurrentStartDate,
    currentEndDate,
    setCurrentEndDate,
    course,
    phase,
    phaseIndex,
  } = props;

  const { email } = useFirebaseUser();

  // const patientData = patientCollectionData?.find((p) => p.id === patient.id);
  const appUser = useAppUser(patient);

  const { sendRequest, requestSent, requestError, approved, denied } =
    useRequestToViewPatientData(patient, email);

  const {
    refreshPatientStatistics,
    disabled: refreshDisabled,
    isUpdating,
    statisticsHash,
  } = useRefreshPatientStatistics(
    therapyCoursePatientId || null,
    currentStartDate || null,
    currentEndDate || null
  );

  const { data: statisticsCache } = useStatisticsCacheDoc(
    statisticsHash,
    patient?.user?.id || null
  );

  const onClickRefreshStatistics = useCallback<
    MouseEventHandler<HTMLButtonElement>
  >(() => {
    if (
      !patient.id ||
      refreshDisabled ||
      isUpdating ||
      !refreshPatientStatistics ||
      !approved
    ) {
      return;
    }
    refreshPatientStatistics(true);
  }, [
    patient.id,
    refreshDisabled,
    isUpdating,
    refreshPatientStatistics,
    approved,
  ]);

  useEffect(() => {
    refreshPatientStatistics(false);
  }, [refreshPatientStatistics]);

  const dateRange: [Dayjs | null, Dayjs | null] = useMemo(
    () => [
      currentStartDate ? dayjs(currentStartDate.toDate()) : null,
      currentEndDate ? dayjs(currentEndDate?.toDate()) : null,
    ],
    [currentStartDate, currentEndDate]
  );

  const statistics = useMemo(
    () => statisticsCache?.statistics || null,
    [statisticsCache?.statistics]
  );

  let fromDateStr = '';
  try {
    fromDateStr = currentStartDate?.toDate().toISOString().substr(0, 10) || '';
  } catch (e) {
    console.error(e);
  }
  let toDateStr = '';
  try {
    toDateStr = currentEndDate?.toDate().toISOString().substr(0, 10) || '';
  } catch (e) {
    console.error(e);
  }

  const statisticsSlice = useMemo(
    () =>
      statistics?.filter(
        (statistic) =>
          (!fromDateStr ||
            new Date(statistic.marking_date) >= new Date(fromDateStr)) &&
          (!toDateStr ||
            new Date(statistic.marking_date) <= new Date(toDateStr))
      ) || [],
    [statistics, fromDateStr, toDateStr]
  );
  const maxMarkingDate = useMaxMarkingDate();

  let maxDateAsStr = '';
  try {
    maxDateAsStr = maxMarkingDate.toISOString().substr(0, 10);
  } catch (e) {
    console.error(e);
  }

  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  let responseMessage: JSX.Element | null = null;
  let responseSeverity: AlertColor = 'info';

  if (!approved && !denied) {
    responseMessage = requestSent ? (
      <Trans>
        Your request to view this patient data has been sent. Please wait for
        approval.
      </Trans>
    ) : (
      <Trans>You do not have permission to view this patient's data</Trans>
    );
  } else if (denied) {
    responseSeverity = 'error';
    responseMessage = (
      <Trans>Your request to view this patient's data has been denied.</Trans>
    );
  }

  const handleQuickSelect = useCallback(
    (period: 'week' | 'twoWeeks' | 'allTime') => {
      const now = new Date();
      const endOfDay = new Date(now.setHours(23, 59, 59, 999));

      switch (period) {
        case 'week':
          setCurrentStartDate(
            Timestamp.fromDate(
              new Date(
                new Date().setHours(0, 0, 0, 0) - 6 * 24 * 60 * 60 * 1000
              )
            )
          );
          setCurrentEndDate(Timestamp.fromDate(endOfDay));
          break;
        case 'twoWeeks':
          setCurrentStartDate(
            Timestamp.fromDate(
              new Date(
                new Date().setHours(0, 0, 0, 0) - 13 * 24 * 60 * 60 * 1000
              )
            )
          );
          setCurrentEndDate(Timestamp.fromDate(endOfDay));
          break;
        case 'allTime':
          setCurrentStartDate(null);
          setCurrentEndDate(null);
          break;
      }
    },
    [setCurrentStartDate, setCurrentEndDate]
  );

  const handleDateChange = useCallback(
    (days: number) => {
      setCurrentStartDate((prevDate) =>
        prevDate
          ? Timestamp.fromDate(
              new Date(
                new Date(
                  prevDate.toDate().getTime() + days * 24 * 60 * 60 * 1000
                ).setHours(0, 0, 0, 0)
              )
            )
          : null
      );
      setCurrentEndDate((prevDate) =>
        prevDate
          ? Timestamp.fromDate(
              new Date(
                new Date(
                  prevDate.toDate().getTime() + days * 24 * 60 * 60 * 1000
                ).setHours(23, 59, 59, 999)
              )
            )
          : null
      );
    },
    [setCurrentStartDate, setCurrentEndDate]
  );

  const isDateRangeActive = useCallback(
    (period: 'week' | 'twoWeeks' | 'allTime') => {
      if (period === 'allTime') {
        return currentStartDate === null && currentEndDate === null;
      }

      if (!currentStartDate || !currentEndDate) {
        return false;
      }

      // Reset time components to compare dates only
      const normalizedEndDate = new Date(
        currentEndDate.toDate().toISOString().split('T')[0]
      );
      const normalizedStartDate = new Date(
        currentStartDate.toDate().toISOString().split('T')[0]
      );
      const today = new Date(new Date().toISOString().split('T')[0]);

      // Calculate difference in days
      const diffDays = Math.round(
        (normalizedEndDate.getTime() - normalizedStartDate.getTime()) /
          (1000 * 60 * 60 * 24)
      );

      // Check if end date is today
      const isToday = normalizedEndDate.getTime() === today.getTime();

      switch (period) {
        case 'week':
          return diffDays === 7 && isToday;
        case 'twoWeeks':
          return diffDays === 14 && isToday;
        default:
          return false;
      }
    },
    [currentStartDate, currentEndDate]
  );

  if (patient.pending) {
    return (
      <Alert
        severity="warning"
        icon={<PendingIcon />}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography>
          {t('This patient has not yet registered in the app')}
        </Typography>
      </Alert>
    );
  }

  if (!appUser) {
    return <CircularProgress />;
  }

  return (
    <>
      <Grid container sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Typography variant="h6" color={'textSecondary'}>
            {patient.remark} - {patient.licenceCode}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <PatientPhaseTasks
            email={email}
            course={course}
            phase={phase}
            phaseIndex={phaseIndex}
            therapyCourseId={therapyCourseId}
            therapyCoursePatientId={therapyCoursePatientId}
            appUser={appUser}
            approved={approved}
            patient={patient}
          />
        </Grid>
      </Grid>
      {responseMessage ? (
        <Alert severity={responseSeverity}>
          <Typography gutterBottom>{responseMessage}</Typography>
          {(!requestSent || denied) && (
            <LoadingButton
              onClick={() => sendRequest()}
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
            >
              {t('Request Access')}
            </LoadingButton>
          )}
        </Alert>
      ) : (
        <>
          <Divider sx={{ mt: 2, mb: 4 }} />
          <DateRangePicker
            value={dateRange}
            closeOnSelect
            onChange={(date) => {
              setCurrentStartDate(
                date[0]
                  ? Timestamp.fromDate(
                      new Date(date[0].toDate().setHours(0, 0, 0, 0))
                    )
                  : null
              );
              setCurrentEndDate(
                date[1]
                  ? Timestamp.fromDate(
                      new Date(date[1].toDate().setHours(23, 59, 59, 999))
                    )
                  : null
              );
            }}
          />
          <Box sx={{ mt: 1, mb: 2 }}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <ButtonGroup aria-label="date navigation">
                <Tooltip title={t('Move back 1 week')}>
                  <Button onClick={() => handleDateChange(-7)}>
                    <KeyboardDoubleArrowLeftIcon />
                  </Button>
                </Tooltip>
                <Tooltip title={t('Move back 1 day')}>
                  <Button onClick={() => handleDateChange(-1)}>
                    <KeyboardArrowLeftIcon />
                  </Button>
                </Tooltip>
              </ButtonGroup>

              <ButtonGroup aria-label="quick select" fullWidth>
                <Tooltip title={t('View data for the past week')}>
                  <Button
                    onClick={() => handleQuickSelect('week')}
                    startIcon={<CalendarTodayIcon />}
                    variant={
                      isDateRangeActive('week') ? 'contained' : 'outlined'
                    }
                  >
                    {t('1 Week')}
                  </Button>
                </Tooltip>
                <Tooltip title={t('View data for the past two weeks')}>
                  <Button
                    onClick={() => handleQuickSelect('twoWeeks')}
                    startIcon={<DateRangeIcon />}
                    variant={
                      isDateRangeActive('twoWeeks') ? 'contained' : 'outlined'
                    }
                  >
                    {t('2 Weeks')}
                  </Button>
                </Tooltip>
                <Tooltip title={t('View all available data')}>
                  <Button
                    onClick={() => handleQuickSelect('allTime')}
                    startIcon={<AllInclusiveIcon />}
                    variant={
                      isDateRangeActive('allTime') ? 'contained' : 'outlined'
                    }
                  >
                    {t('All')}
                  </Button>
                </Tooltip>
              </ButtonGroup>

              <ButtonGroup aria-label="date navigation">
                <Tooltip title={t('Move forward 1 day')}>
                  <Button onClick={() => handleDateChange(1)}>
                    <KeyboardArrowRightIcon />
                  </Button>
                </Tooltip>
                <Tooltip title={t('Move forward 1 week')}>
                  <Button onClick={() => handleDateChange(7)}>
                    <KeyboardDoubleArrowRightIcon />
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </Stack>
          </Box>

          {(appUser?.statistics_cache_updating || isUpdating) && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                bgcolor: 'info.light',
                color: 'info.contrastText',
                p: 2,
                borderRadius: 1,
                mt: 2,
              }}
            >
              <CircularProgress
                size={24}
                thickness={4}
                sx={{ mr: 2, color: 'info.contrastText' }}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="subtitle1" fontWeight="bold">
                  {t('Updating Statistics')}
                </Typography>
                <Typography variant="body2">
                  {t('Please wait while we refresh the data...')}
                </Typography>
              </Box>
              <UpdateIcon sx={{ ml: 'auto', fontSize: 32 }} />
            </Box>
          )}
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              mt: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              aria-label="statistics tabs"
            >
              <Tab label={t('Statistics Table')} {...customTabA11yProps(0)} />
              <Tab label={t('Progress Over Time')} {...customTabA11yProps(1)} />
              <Tab label={t('Day Graph')} {...customTabA11yProps(2)} />
            </Tabs>
            <LoadingButton
              variant="text"
              color="primary"
              loading={!!appUser?.statistics_cache_updating || isUpdating}
              disabled={
                !!appUser?.statistics_cache_updating ||
                isUpdating ||
                !approved ||
                refreshDisabled
              }
              onClick={onClickRefreshStatistics}
              startIcon={<RefreshIcon />}
            >
              {t('Refresh patient statistics')}
            </LoadingButton>
          </Box>
          <Box sx={{ minHeight: '60vh' }}>
            <CustomTabPanel value={currentTab} index={0}>
              <StatisticsTableTab
                statisticsSlice={statisticsSlice}
                fromDateString={fromDateStr}
                toDateString={toDateStr}
                maxDateAsStr={maxDateAsStr}
                patient={patient}
              />
            </CustomTabPanel>
            <CustomTabPanel value={currentTab} index={1}>
              <ProgressTab
                key={statisticsHash}
                statistics={statisticsSlice}
                fromDateString={fromDateStr}
                toDateString={toDateStr}
                maxDateAsStr={maxDateAsStr}
                patient={patient}
              />
            </CustomTabPanel>
            <CustomTabPanel value={currentTab} index={2}>
              <DayGraphTab
                isUpdating={!!appUser?.statistics_cache_updating}
                statisticsSlice={statisticsSlice}
                patient={patient}
                fromDateString={fromDateStr}
                toDateString={toDateStr}
              />
            </CustomTabPanel>
          </Box>
        </>
      )}
      <Snackbar open={!!requestError}>
        <Alert severity="error">
          <AlertTitle>
            <Trans>Error requesting access to patient data</Trans>
          </AlertTitle>
          <Trans>{requestError}</Trans>
        </Alert>
      </Snackbar>
    </>
  );
}
