import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Grid,
  Divider,
  CircularProgress,
  Alert,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { localeFormatTime, minutesToHoursAndMinutes } from '../../utils';
import { TherapyCoursePhasePatient, User } from '../../firebase/firebaseModels';
import useStatisticsCacheDoc from '../../firebase/useStatisticsCacheDoc';
import {
  calculateDurationRowData,
  calculateTimeRowData,
} from './calculateRowData';
import { Timestamp } from 'firebase/firestore';
import { useRefreshPatientStatistics } from '../../hooks/useRefreshPatientStatistics';
import { useUpdateAppUser } from '../../firebase/useUpdateAppUser';
import { AverageDayView } from '../DayStatistics/AverageDayView';
interface SleepRestrictionDialogProps {
  open: boolean;
  onClose: () => void;
  appUser: User | null;
  therapyCoursePatientId: string | null;
  phasePatient: TherapyCoursePhasePatient | null;
}

export default function SleepRestrictionDialog({
  open,
  onClose,
  appUser,
  therapyCoursePatientId,
}: SleepRestrictionDialogProps) {
  const { t, i18n } = useTranslation();

  const { updateUser, status: updateUserStatus } = useUpdateAppUser(
    appUser?.id || ''
  );

  const [targetLayDownTime, setTargetLayDownTime] = useState<string>('');
  const [targetWakeupTime, setTargetWakeupTime] = useState<string>('');

  const userTargetLayDownTime =
    appUser?.sleepRestriction?.targetLayDownTime || '';
  const userTargetWakeupTime =
    appUser?.sleepRestriction?.targetWakeupTime || '';

  useEffect(() => {
    if (appUser) {
      setTargetLayDownTime(userTargetLayDownTime);
      setTargetWakeupTime(userTargetWakeupTime);
    }
  }, [appUser, userTargetLayDownTime, userTargetWakeupTime]);

  // Calculate statistics for the last 7 days
  const toDate = new Date();
  toDate.setHours(23, 59, 59, 999);
  const toDateStr = toDate.toISOString();

  const fromDate = new Date(toDate);
  fromDate.setDate(fromDate.getDate() - 6);
  fromDate.setHours(0, 0, 0, 0);
  const fromDateStr = fromDate.toISOString();

  const fromTimestamp = Timestamp.fromDate(fromDate);
  const toTimestamp = Timestamp.fromDate(toDate);

  const { refreshPatientStatistics, isUpdating, statisticsHash } =
    useRefreshPatientStatistics(
      therapyCoursePatientId || null,
      fromTimestamp,
      toTimestamp
    );

  const { data: statisticsCache } = useStatisticsCacheDoc(
    statisticsHash,
    appUser?.id || null
  );
  useEffect(() => {
    refreshPatientStatistics(false);
  }, [refreshPatientStatistics]);

  const statisticsSlice =
    statisticsCache?.statistics.filter(
      (statistic) =>
        new Date(statistic.marking_date) >= new Date(fromDateStr) &&
        new Date(statistic.marking_date) <= new Date(toDateStr)
    ) || [];

  const maxMarkingDate = new Date();
  maxMarkingDate.setHours(23, 59, 59, 999);
  const maxDateAsStr = maxMarkingDate.toISOString().substr(0, 10);

  const layDownTimeData = calculateTimeRowData(
    statisticsSlice,
    'laydowntime',
    maxDateAsStr
  );

  const bedtimeData = calculateTimeRowData(
    statisticsSlice,
    'bedtime',
    maxDateAsStr
  );

  const wakeuptimeData = calculateTimeRowData(
    statisticsSlice,
    'wakeuptime',
    maxDateAsStr
  );

  const timeAsleepData = calculateDurationRowData(
    statisticsSlice,
    'time_asleep',
    false
  );

  const timeInBedData = calculateDurationRowData(
    statisticsSlice,
    'time_in_bed',
    false
  );

  const timeAsleepAverage = timeAsleepData.average || 0;
  const timeInBedAverage = timeInBedData.average || 0;

  let averageLayDownTime = layDownTimeData.average;
  let averageBedTime = bedtimeData.average;
  let averageWakeupTime = wakeuptimeData.average;

  // round to nearest 15 min
  if (averageLayDownTime) {
    averageLayDownTime.setMinutes(
      Math.round(averageLayDownTime.getMinutes() / 15) * 15
    );
  }

  if (averageBedTime) {
    averageBedTime.setMinutes(
      Math.round(averageBedTime.getMinutes() / 15) * 15
    );
  }

  if (averageWakeupTime) {
    averageWakeupTime.setMinutes(
      Math.round(averageWakeupTime.getMinutes() / 15) * 15
    );
  }

  // Calculate sleep efficiency
  const sleepEfficiency = (timeAsleepAverage / timeInBedAverage) * 100.0;

  //   Jos unitehokkuus on
  // • yli 90 %, lisää vuoteessaoloaikaa varttitunnin verran
  // • 85−90 %, pidä vuoteessaoloaika samana
  // • alle 85 %, vähennä vuoteessaoloaikaa varttitunnin verran (ei alle 6 tunnin).

  let suggestedTargetLayDownTime = averageLayDownTime;

  if (suggestedTargetLayDownTime) {
    if (sleepEfficiency > 90) {
      // If sleep efficiency is over 90%, increase time in bed by 15 minutes
      suggestedTargetLayDownTime = new Date(
        suggestedTargetLayDownTime.getTime() - 15 * 60 * 1000
      );
    } else if (sleepEfficiency < 85) {
      // If sleep efficiency is under 85%, decrease time in bed by 15 minutes
      suggestedTargetLayDownTime = new Date(
        suggestedTargetLayDownTime.getTime() + 15 * 60 * 1000
      );
    }
    // If sleep efficiency is between 85-90%, keep the same time in bed
  }

  // Validate input times
  const isValidTime = (time: string) => /^([01]\d|2[0-3]):[0-5]\d$/.test(time);
  const targetLayDownTimeIsValid =
    targetLayDownTime === '' || isValidTime(targetLayDownTime);
  const targetWakeupTimeIsValid =
    targetWakeupTime === '' || isValidTime(targetWakeupTime);
  const valuesHaveChanged =
    targetLayDownTime !== userTargetLayDownTime ||
    targetWakeupTime !== userTargetWakeupTime;

  const canSave = useMemo(() => {
    return (
      targetLayDownTimeIsValid && targetWakeupTimeIsValid && valuesHaveChanged
    );
  }, [targetLayDownTimeIsValid, targetWakeupTimeIsValid, valuesHaveChanged]);

  const handleSave = useCallback(() => {
    updateUser({
      sleepRestriction: {
        targetLayDownTime,
        targetWakeupTime,
      },
    });
    onClose();
  }, [onClose, targetLayDownTime, targetWakeupTime, updateUser]);

  if (!appUser || isUpdating) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }
  const hasStatistics =
    !!averageLayDownTime || !!averageBedTime || !!averageWakeupTime;

  const dayWidth = 100; // Adjust as needed
  const hourWidth = dayWidth / 24;
  const dayHeight = 30; // Adjust as needed
  const fullWidth = dayWidth;
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {t('Sleep Restriction')}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">{t('Last 7 Days')}</Typography>
          </Grid>
          {hasStatistics ? (
            <>
              <Grid item xs={12}>
                <AverageDayView
                  fullWidth={fullWidth}
                  dayWidth={dayWidth}
                  hourWidth={hourWidth}
                  dayHeight={dayHeight}
                  averageLayDownTime={averageLayDownTime || null}
                  averageWakeupTime={averageWakeupTime || null}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {t('Average lay down time: {{laydowntime}}', {
                    laydowntime: localeFormatTime(
                      i18n.language,
                      averageLayDownTime
                    ),
                  })}
                </Typography>
                <Typography>
                  {t('Average bedtime: {{bedtime}}', {
                    bedtime: localeFormatTime(i18n.language, averageBedTime),
                  })}
                </Typography>
                <Typography>
                  {t('Average wake up time: {{wakeuptime}}', {
                    wakeuptime: localeFormatTime(
                      i18n.language,
                      averageWakeupTime
                    ),
                  })}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {t('Average time asleep: {{timeAsleep}}', {
                    timeAsleep: minutesToHoursAndMinutes(
                      timeAsleepAverage as number
                    ),
                  })}
                </Typography>
                <Typography>
                  {t('Average time in bed: {{timeInBed}}', {
                    timeInBed: minutesToHoursAndMinutes(
                      timeInBedAverage as number
                    ),
                  })}
                </Typography>
                <Typography>
                  {t("Whole week's sleep efficiency: {{efficiency}}%", {
                    efficiency: sleepEfficiency.toFixed(0),
                  })}
                </Typography>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Alert severity="info">
                {t('No sleep statistics available for the last 7 days.')}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="h6">
              {t('Plan Until Next Therapy Session')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="target-laydown-time"
              label={t('I will go to bed at')}
              value={targetLayDownTime}
              onChange={(e) => setTargetLayDownTime(e.target.value)}
              placeholder={t('HH:MM')}
              error={!targetLayDownTimeIsValid && targetLayDownTime !== ''}
              helperText={
                !targetLayDownTimeIsValid && targetLayDownTime !== ''
                  ? t('Please enter a valid time (HH:MM)')
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="target-wakeup-time"
              fullWidth
              label={t('I will get up from bed at')}
              value={targetWakeupTime}
              onChange={(e) => setTargetWakeupTime(e.target.value)}
              placeholder={t('HH:MM')}
              error={!targetWakeupTimeIsValid && targetWakeupTime !== ''}
              helperText={
                !targetWakeupTimeIsValid && targetWakeupTime !== ''
                  ? t('Please enter a valid time (HH:MM)')
                  : ''
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('Cancel')}</Button>
        <Button
          onClick={handleSave}
          disabled={!canSave || updateUserStatus === 'loading'}
        >
          {t('Save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
