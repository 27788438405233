import { TaskPoolTask } from './firebaseModels';
import useTypedCollection from './useTypedCollection';

export default function useTaskPoolTaskCategoryCollection(
  taskpooltype: string | null = null,
  languages?: string[],
  existingTaskIds?: string[]
) {
  return useTypedCollection<TaskPoolTask>(
    taskpooltype,
    'TaskPool',
    languages,
    existingTaskIds
  );
}
