import { useFirestore, useFunctions } from 'reactfire';
import { Licence, Patient, User } from '../firebase/firebaseModels';
import { httpsCallable } from '@firebase/functions';
import {
  doc,
  getDoc,
  DocumentReference,
  setDoc,
  Timestamp,
} from 'firebase/firestore';
import { useCallback, useState } from 'react';
import useUserProvider from './useUserProvider';

/**
 * Parameters for the admin licence lookup function
 */
type AdminFindLicenceParams = {
  licenceCode: string;
};

/**
 * Response from the admin licence lookup function
 */
type AdminFindLicenceResult = {
  success: boolean;
  message?: string;
  licenceId?: string;
};

/**
 * Hook to manage adding patients to Firestore
 * @param email - Email of the current user performing the action
 * @returns Object containing addPatient function, error state, and loading state
 */
export function useAddPatient(email: string | null) {
  const firestore = useFirestore();
  const [addPatientError, setAddPatientError] = useState<Error | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const { data: userProviderData } = useUserProvider();
  const functions = useFunctions();
  const adminFindLicence = httpsCallable<
    AdminFindLicenceParams,
    AdminFindLicenceResult
  >(functions, 'adminfindlicence');

  /**
   * Saves a patient document to Firestore
   * @param patient - Patient object to save
   * @param onSuccess - Optional callback function called after successful save
   */
  const addPatientToFirestore = useCallback(
    (
      patient: Patient,
      onSuccess?: (ref: DocumentReference<Patient>) => void
    ) => {
      if (!patient.licenceCode || !userProviderData?.id || !email) {
        setAddPatientError(new Error('Missing required data'));
        setIsUpdating(false);
        return;
      }

      const patientRef = doc(
        firestore,
        'ServiceProvider',
        userProviderData.id,
        'UserData',
        email,
        'Patient',
        patient.licenceCode
      ) as DocumentReference<Patient>;

      setDoc(patientRef, patient)
        .then(() => {
          // console.log('patient added');
          setAddPatientError(null);
          onSuccess && onSuccess(patientRef);
          setIsUpdating(false);
        })
        .catch(() => {
          setAddPatientError(new Error('Error adding patient'));
          setIsUpdating(false);
        });
    },
    [firestore, userProviderData?.id, email]
  );

  /**
   * Adds a new patient by validating their licence code and creating appropriate records
   * @param licenceCode - The licence code to validate
   * @param remark - Additional notes about the patient
   * @param onSuccess - Optional callback function called after successful patient creation
   */
  const addPatient = useCallback(
    (
      licenceCode: string,
      remark: string,
      onSuccess?: (newPatient: DocumentReference<Patient>) => void
    ) => {
      if (!userProviderData?.id || !licenceCode || !email) {
        return;
      }
      setIsUpdating(true);

      // Step 1: Validate licence code using admin function
      setAddPatientError(null);
      adminFindLicence({ licenceCode })
        .then((result) => {
          const { data } = result;
          const { success, message, licenceId } = data;

          // Step 2: Get licence details if validation successful
          if (!success || !licenceId) {
            setAddPatientError(new Error(message || 'Licence ID not valid'));
            setIsUpdating(false);
            return;
          }

          const licenceRef = doc(
            firestore,
            'Licences',
            licenceId
          ) as DocumentReference<Licence>;

          // Step 3: Fetch and validate licence data
          let licenceData: Licence | null = null;
          getDoc(licenceRef)
            .then((licenceDoc) => {
              if (!licenceDoc.exists()) {
                setAddPatientError(new Error('Licence not found'));
                setIsUpdating(false);
                return;
              }
              licenceData = licenceDoc.data();
              // console.log('licenceData', licenceData);
              if (!licenceData) {
                setAddPatientError(new Error('Error getting license'));
                setIsUpdating(false);
                return;
              }
              const userId = licenceData.user;

              // Create pending patient if no userId exists
              if (!userId) {
                const patient: Patient = {
                  licenceCode,
                  remark,
                  user: null,
                  modifiedBy: email,
                  createdBy: email,
                  modified: Timestamp.now(),
                  created: Timestamp.now(),
                  approved: false,
                  pending: true,
                };
                addPatientToFirestore(patient);
                return;
              }

              // Step 4: Get associated user data
              const userRef = doc(
                firestore,
                'Users',
                userId
              ) as DocumentReference<User>;

              // Step 5: Create patient record based on user existence
              getDoc(userRef)
                .then((userDoc) => {
                  // console.log('userDoc', userDoc);
                  const userData = userDoc.data();
                  if (userData) {
                    // Create patient record for existing user
                    if (userData.licenceCode !== licenceCode) {
                      throw new Error(
                        "User's current license code doesn't match license"
                      );
                    }
                    const patient: Patient = {
                      licenceCode,
                      remark,
                      user: userRef as DocumentReference<User>,
                      modifiedBy: email,
                      createdBy: email,
                      modified: Timestamp.now(),
                      created: Timestamp.now(),
                      approved: false,
                    };
                    addPatientToFirestore(patient);
                  } else {
                    // Create pending patient record for non-existent user
                    const patient: Patient = {
                      licenceCode,
                      remark,
                      user: null,
                      modifiedBy: email,
                      createdBy: email,
                      modified: Timestamp.now(),
                      created: Timestamp.now(),
                      approved: false,
                      pending: true,
                    };
                    addPatientToFirestore(patient);
                  }
                })
                .catch((error) => {
                  console.error('Error getting user', error);
                  setAddPatientError(new Error('Error getting user'));
                  setIsUpdating(false);
                });
            })
            .catch((error) => {
              console.error('Error getting license', error);
              setAddPatientError(new Error('Error getting license'));
              setIsUpdating(false);
            });
        })
        .catch((error) => {
          console.error('Error finding licence', error);
          setAddPatientError(new Error('Licence not found'));
          setIsUpdating(false);
        });
    },
    [
      userProviderData?.id,
      email,
      adminFindLicence,
      firestore,
      addPatientToFirestore,
    ]
  );

  return { addPatient, addPatientError, isUpdating };
}
