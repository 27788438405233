import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface UserStore {
  selectedProvider: string | null;
  setSelectedProvider: (provider: string) => void;
}

export const useUserStore = create<UserStore>()(
  persist(
    (set) => ({
      selectedProvider: null,
      setSelectedProvider: (provider) => set({ selectedProvider: provider }),
    }),
    {
      name: 'user-store',
    }
  )
);
