import { getFormattedDate, timestampPropertyAsDate } from '../../utils';
import {
  SleepEfficiencyData,
  SleepEfficiencyEveningData,
  StatisticDay,
  TimestampProperty,
} from '../TherapyCourseOverview/types';

/**
 * Converts a given datetime to a decimal value representing the time of day.
 * @param timestamp - The datetime to convert to a decimal value.
 * @param cutDateAsStr - The cut-off date as a string in the format 'YYYY-MM-DD'.
 * @param defaultDecimal - The default decimal value to return if the date does not match the cut-off date.
 * @returns The decimal value representing the time of day, or null if the input datetime is null.
 *
 * If the date matches the given cut-off date, the decimal value is calculated based on the hours and minutes.
 * For example, 14:30 (2:30 PM) would return 14.5.
 * If the date doesn't match the cut-off date, the function returns the defaultDecimal value.
 */
export function getTimeAsDecimal(
  timestamp: TimestampProperty,
  cutDateAsStr: string,
  defaultDecimal: number
) {
  const date = timestampPropertyAsDate(timestamp);
  if (!date) {
    return null;
  }
  return getFormattedDate(date) === cutDateAsStr
    ? date.getHours() + date.getMinutes() / 60
    : defaultDecimal;
}

/**
 * Retrieves the diary data for a given date from an array of statistics.
 * @param statisticDate - The date to retrieve diary data for.
 * @param statistics - The array of statistics to search for the given date.
 * @returns An object containing sleep-related data for the given date.
 *
 * The returned object includes:
 * - laydowntime: Time the user laid down in bed
 * - bedtime: Time the user intended to fall asleep
 * - sleeptime: Time the user actually fell asleep
 * - wakeuptime: Time the user woke up
 * - gettinguptime: Time the user got out of bed
 * - eveningData: Evening sleep efficiency data (if available)
 * - night_nonsleep: Duration of time awake during the night (in minutes)
 * - wakeups: Number of times the user woke up during the night
 *
 * All time values are returned as Date objects or null if not available.
 */
export function getDateDiaryData(
  statisticDate: Date,
  statistics: StatisticDay[]
) {
  const dateStr = getFormattedDate(statisticDate);

  // find the day's statistics
  const dayStatistics = statistics.find((s) => s.marking_date === dateStr);

  // find the morning and evening data for this day
  const sleep_efficiency_data =
    dayStatistics?.sleep_efficiency_data as SleepEfficiencyData[];
  const eveningData = sleep_efficiency_data
    ? (sleep_efficiency_data?.find(
        (d) => d.type === 'evening'
      ) as SleepEfficiencyEveningData | null)
    : null;

  // Reactotron.log(`dayStatistics ${dateStr}`, dayStatistics);
  // Reactotron.log(`morningData ${dateStr}`, morningData);
  // Reactotron.log(`eveningData ${dateStr}`, eveningData);

  // extract the relevant data from the morning entry
  const laydowntime = timestampPropertyAsDate(
    dayStatistics?.laydowntime ?? null
  );
  const bedtime = timestampPropertyAsDate(dayStatistics?.bedtime ?? null);
  const sleeptime = timestampPropertyAsDate(dayStatistics?.sleeptime ?? null);
  const wakeuptime = timestampPropertyAsDate(dayStatistics?.wakeuptime ?? null);
  const gettinguptime = timestampPropertyAsDate(
    dayStatistics?.gettinguptime ?? null
  );
  const night_nonsleep =
    dayStatistics?.night_nonsleep !== null
      ? (dayStatistics?.night_nonsleep as number)
      : null;
  const wakeups =
    dayStatistics?.wakeups !== null ? (dayStatistics?.wakeups as number) : null;
  return {
    laydowntime,
    bedtime,
    sleeptime,
    wakeuptime,
    gettinguptime,
    eveningData,
    night_nonsleep,
    wakeups,
  };
}
