import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';
import { useCallback, useState } from 'react';

/**
 * Custom hook to invite users to the app via phone number.
 *
 * @returns {Object} An object containing:
 *   - inviteUser: Function to send invitations with parameters:
 *     - phoneNumber: Phone number in E.164 format (e.g., '+1234567890')
 *     - language: Optional ISO language code (e.g., 'en', 'es')
 *     - timezone: Optional IANA timezone (e.g., 'America/New_York')
 *     - remark: Optional additional note about the invitation
 *   - isInviting: Boolean indicating if an invitation is in progress
 *   - inviteError: String containing error message if invitation failed, null otherwise
 */
export function useInviteAppUser() {
  const functions = useFunctions();
  const [isInviting, setIsInviting] = useState(false);
  const [inviteError, setInviteError] = useState<string | null>(null);
  const inviteUser = useCallback(
    (
      phoneNumber: string,
      language?: string,
      timezone?: string,
      remark?: string
    ) => {
      setIsInviting(true);
      setInviteError(null);

      const inviteUser = async () => {
        try {
          const inviteAppUserFunction = httpsCallable(
            functions,
            'inviteappuser'
          );
          const result = await inviteAppUserFunction({
            phone_number: phoneNumber,
            language: language,
            timezone: timezone,
            remark: remark,
          });

          setIsInviting(false);
          return result.data as {
            success: boolean;
            licence_code: string;
            user_id?: string;
            message?: string;
          };
        } catch (error: any) {
          setIsInviting(false);
          setInviteError(error.message);
          console.error('Error inviting user:', error.message);
        }
      };

      return inviteUser();
    },
    [functions]
  );
  return { isInviting, inviteError, inviteUser };
}
