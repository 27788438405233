/**
 * @file TherapyPlanEditorPhase.tsx
 * Component to edit a single phase of a therapy plan
 * @module TherapyPlanEditor/TherapyPlanEditorPhase
 */
import { Trans, useTranslation } from 'react-i18next';
import { AutocompleteValue } from '@mui/material/Autocomplete';
import { doc, DocumentReference } from 'firebase/firestore';
import { TaskPoolTask, TherapyPlanPhase } from '../../firebase/firebaseModels';
import {
  Alert,
  AlertTitle,
  CircularProgress,
  Typography,
  Box,
  Paper,
} from '@mui/material';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useFirestore } from 'reactfire';
import useTaskPoolCollection from '../../firebase/useTaskPoolCollection';
import useTaskPoolTaskCategoryCollection from '../../firebase/useTaskPoolTaskCategoryCollection';
import { styled } from '@mui/system';
import TranslatableTextField from '../TranslatableTextField';
import { TaskPoolOption } from '../../hooks/useTaskPoolOptions';
import { TaskPoolSelector } from '../TherapyCourseEditor/TaskPoolSelector';
import { usePhaseTasksValue } from '../TherapyCourseEditor/usePhaseTasksValue';
import { TaskTypeIcon } from '../TaskTypeIcon';
import TaskCategoryDot from '../TaskCategoryDot';

type TherapyPlanEditorPhaseProps = {
  disabled: boolean;
  phases: TherapyPlanPhase[];
  currentPhase: number;
  language: string;
  defaultLanguage: string;
  setPhases: Dispatch<SetStateAction<TherapyPlanPhase[]>>;
  taskPoolOptions: TaskPoolOption[] | null;
};

export default function TherapyPlanEditorPhase(
  props: TherapyPlanEditorPhaseProps
) {
  const { t } = useTranslation();
  const firestore = useFirestore();
  const { status: taskPoolStatus, data: taskPoolData } =
    useTaskPoolCollection();
  const {
    disabled,
    phases,
    currentPhase,
    language,
    setPhases,
    taskPoolOptions,
    defaultLanguage,
  } = props;

  const phaseTasks = useMemo(
    () => phases[currentPhase]?.phaseTasks || [],
    [phases, currentPhase]
  );

  const {
    status: categoryStatus,
    data: categoryData,
    error: collectionError,
  } = useTaskPoolTaskCategoryCollection();

  const phaseTasksValue = usePhaseTasksValue(
    phaseTasks,
    taskPoolData,
    categoryData
  );

  const onChangeTitle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPhases((prevPhases) => {
        const newPhases = [...prevPhases];
        newPhases[currentPhase] = {
          ...newPhases[currentPhase],
          title: {
            ...newPhases[currentPhase].title,
            [language]: event.target.value,
          },
        };
        return newPhases;
      });
    },
    [currentPhase, language, setPhases]
  );

  const onChangeDefaultTasks = useCallback(
    (
      _: any,
      newValue: AutocompleteValue<TaskPoolOption, true, false, false>
    ) => {
      setPhases((prevPhases) => {
        const newPhases = [...prevPhases];
        newPhases[currentPhase] = {
          ...newPhases[currentPhase],
          phaseTasks: newValue.map(
            (v) =>
              doc(
                firestore,
                'TaskPool',
                v.id
              ) as DocumentReference<TaskPoolTask>
          ),
        };
        return newPhases;
      });
    },
    [currentPhase, firestore, setPhases]
  );
  const titleExistsInPrimaryLanguage =
    !!phases[currentPhase].title[defaultLanguage];

  if (categoryStatus === 'loading' || taskPoolStatus === 'loading') {
    return <CircularProgress />;
  }
  return (
    <>
      {collectionError && (
        <Alert severity="error">
          <AlertTitle>{t('Error')}</AlertTitle>
          <Trans>{collectionError.message}</Trans>
        </Alert>
      )}
      <TranslatableTextField
        key={`phase-title-${language}-${currentPhase}`}
        name={`phase-title-${language}-${currentPhase}`}
        disabled={disabled}
        label={t('Phase Title')}
        helperText={
          !titleExistsInPrimaryLanguage
            ? t('Title is required in primary language') +
              ' ' +
              `(${defaultLanguage})`
            : undefined
        }
        error={!titleExistsInPrimaryLanguage}
        language={language}
        type="title"
        onChange={onChangeTitle}
        value={phases[currentPhase].title}
        placeholder={t('Enter Title')}
      />
      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        {t('Default Tasks for this Phase')}
      </Typography>
      <TaskPoolSelector
        taskPoolOptions={taskPoolOptions || []}
        phaseTasksValue={phaseTasksValue!}
        onChangePhaseTasks={onChangeDefaultTasks}
      />

      <Box sx={{ mt: 2 }}>
        {phaseTasksValue?.map((task) => (
          <Paper
            key={task.id}
            sx={{
              p: 1.5,
              mb: 0.5,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <TaskCategoryDot
              color={task.categoryColor}
              categoryName={task.categoryTitle}
            />
            <Typography variant="body2" sx={{ flex: 1 }}>
              {task.translatedTitle}
            </Typography>
            <TaskTypeIcon type={task.taskType} />
          </Paper>
        ))}
      </Box>
    </>
  );
}

export const CategoryGroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
}));

export const CategoryGroupItems = styled('ul')({
  padding: 0,
});
