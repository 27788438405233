import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { TaskTypeIcon } from './TaskTypeIcon';
import TaskCategoryDot from './TaskCategoryDot';

type TaskFilterProps = {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  selectedTaskType: string;
  selectedCategory: string;
  handleTaskTypeChange: (event: SelectChangeEvent) => void;
  handleCategoryChange: (event: SelectChangeEvent) => void;
  taskTypes: string[];
  categories: Array<{ id: string; title: string; color?: string }>;
  taskTypeLabels: Record<string, string>;
};

export function TaskFilter({
  searchQuery,
  setSearchQuery,
  selectedTaskType,
  selectedCategory,
  handleTaskTypeChange,
  handleCategoryChange,
  taskTypes,
  categories,
  taskTypeLabels,
}: TaskFilterProps) {
  const { t } = useTranslation();

  const handleReset = () => {
    setSearchQuery('');
    handleTaskTypeChange({ target: { value: 'all' } } as SelectChangeEvent);
    handleCategoryChange({ target: { value: 'all' } } as SelectChangeEvent);
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
      <TextField
        size="small"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder={t('Search tasks...')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{ minWidth: 200 }}
      />
      <FormControl size="small" sx={{ minWidth: 120 }}>
        <InputLabel>{t('Task Type')}</InputLabel>
        <Select
          value={selectedTaskType}
          onChange={handleTaskTypeChange}
          label={t('Task Type')}
        >
          <MenuItem value="all">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {t('All Types')}
            </Box>
          </MenuItem>
          {taskTypes.map((type) => (
            <MenuItem key={type} value={type}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TaskTypeIcon type={type} sx={{ mr: 1 }} />
                {taskTypeLabels[type] || t(type)}
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl size="small" sx={{ minWidth: 120 }}>
        <InputLabel>{t('Category')}</InputLabel>
        <Select
          value={selectedCategory}
          onChange={handleCategoryChange}
          label={t('Category')}
        >
          <MenuItem value="all">{t('All Categories')}</MenuItem>
          {categories.map((category) => (
            <MenuItem key={category.id} value={category.id}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TaskCategoryDot
                  color={category.color || '#000000'}
                  categoryName={category.title}
                  size={12}
                />
                {category.title}
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Tooltip title={t('Reset Filters')}>
        <Button
          size="small"
          startIcon={<ClearAllIcon />}
          onClick={handleReset}
          sx={{ minWidth: 'auto' }}
        >
          {t('Clear')}
        </Button>
      </Tooltip>
    </Box>
  );
}
