import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TaskPoolTask,
  TaskPoolTaskCategory,
  PortalUser,
  TypedDoc,
  TranslatableField,
} from '../firebase/firebaseModels';
import { getTranslatableField } from '../services/i18n';
import { useFirebaseUser } from '../firebase/useFirebaseUser';

export type TaskPoolOption = {
  id: string;
  title: TranslatableField;
  docType: string;
  translatedTitle: string;
  categoryTitle: string;
  categoryColor: string;
  categoryId: string | null;
  taskType: string;
};

export function useTaskPoolOptions(
  taskPoolData: TaskPoolTask[] | null,
  categoryData: TaskPoolTaskCategory[] | null,
  portalUserData: PortalUser | null
) {
  const { t } = useTranslation();
  const { email } = useFirebaseUser();

  const hiddenTaskPoolTasks = useMemo(() => {
    if (portalUserData?.hiddenTaskPoolTasks) {
      return portalUserData.hiddenTaskPoolTasks;
    }
    return [];
  }, [portalUserData]);

  return useMemo(() => {
    if (!email || !taskPoolData || !categoryData) {
      return null;
    }
    return (
      taskPoolData?.filter((tpt) =>
        ['generic', 'sleepRestriction', 'questionnaire'].includes(tpt.type)
      ) || []
    )
      .filter((tpt) => tpt.id && !hiddenTaskPoolTasks.includes(tpt.id))
      .map((tpt) => {
        const category = categoryData?.find(
          (cat) => cat.id === tpt?.category?.id
        );

        return {
          id: tpt.id as string,
          title: tpt.title,
          docType: determineDocType(tpt, email),
          translatedTitle: getTranslatableField(tpt.title),
          categoryTitle: category
            ? getTranslatableField(category.title)
            : t('Uncategorized'),
          categoryColor: category?.color || '#000000',
          taskType: tpt.type,
          categoryId: category?.id || null,
        } as TaskPoolOption;
      })
      .sort((a, b) => {
        const sortTitleA = a.categoryTitle + '/' + a.translatedTitle;
        const sortTitleB = b.categoryTitle + '/' + b.translatedTitle;
        return sortTitleA.localeCompare(sortTitleB);
      });
  }, [categoryData, hiddenTaskPoolTasks, email, t, taskPoolData]);
}

export function determineDocType(doc: TypedDoc, email: string | null) {
  // is doc a global public doc?
  if (doc.public) {
    return 'Default';
  }
  // is doc an own doc?
  if (doc.createdBy === email) {
    return 'Own';
  }
  return 'Organization';
}
