import { useTranslation } from 'react-i18next';
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
  Alert,
  IconButton,
  Collapse,
  Paper,
  Divider,
  Button,
} from '@mui/material';
import { Patient, TaskPoolTask } from '../../firebase/firebaseModels';
import { useTaskMarkingHistory } from '../../hooks/useTaskMarkingHistory';
import { getTranslatableField } from '../../services/i18n';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState, useMemo, useCallback } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useFormatMarking } from '../../hooks/useFormatMarking';

type TaskMarkingHistoryProps = {
  userId: string | null;
  task: TaskPoolTask | null;
  patient: Patient | null;
};

type MarkingDetailsProps = {
  marking: any;
  task: TaskPoolTask | null;
};

function MarkingDetails({ marking, task }: MarkingDetailsProps) {
  const { t } = useTranslation();

  const answers = useMemo(
    () => Object.values(marking.answers || {}),
    [marking.answers]
  );

  const calculatableAnswers = useMemo(
    () =>
      answers.filter((answer: any) =>
        ['radio', 'range', 'checkbox'].includes(answer.type)
      ),
    [answers]
  );

  if (!marking.answers || !task?.sections) {
    return null;
  }

  const allRangeQuestions = calculatableAnswers.every(
    (answer: any) => answer.type === 'range'
  );
  const shouldShowAverage = allRangeQuestions && calculatableAnswers.length > 0;

  return (
    <Box sx={{ p: 2, bgcolor: 'background.default' }}>
      {marking.totalPoints !== undefined && marking.totalPoints > 0 && (
        <Paper
          elevation={0}
          sx={{
            p: 2,
            mb: 3,
            bgcolor: 'primary.light',
            color: 'primary.contrastText',
          }}
        >
          <Typography variant="h6" sx={{ mb: 1 }}>
            {t('Summary')}
          </Typography>
          <Box sx={{ display: 'flex', gap: 4 }}>
            <Box>
              <Typography variant="subtitle2">{t('Total Points')}</Typography>
              <Typography variant="h5">
                {marking.totalPoints} / {marking.totalMaxPoints}
              </Typography>
            </Box>
            {shouldShowAverage && (
              <Box>
                <Typography variant="subtitle2">{t('Average')}</Typography>
                <Typography variant="h5">
                  {(marking.totalPoints / calculatableAnswers.length).toFixed(
                    1
                  )}{' '}
                  /{' '}
                  {(
                    marking.totalMaxPoints / calculatableAnswers.length
                  ).toFixed(1)}
                </Typography>
              </Box>
            )}
          </Box>
        </Paper>
      )}

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        {task.sections.map((section) => {
          if (section.type === 'checkbox' && section.questions) {
            return (
              <Box key={section.id}>
                {section.title && (
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    {getTranslatableField(section.title)}
                  </Typography>
                )}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  {section.questions.map((question) => {
                    const answerId = `${section.id}:${question.id}`;
                    const answer = marking.answers[answerId];
                    const value = answer?.value ? 1 : 0;
                    return (
                      <Paper
                        key={answerId}
                        elevation={0}
                        sx={{ p: 2, bgcolor: 'background.paper' }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: 2,
                          }}
                        >
                          <Box sx={{ flex: 1 }}>
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: 500 }}
                            >
                              {getTranslatableField(question.title)}
                            </Typography>
                            <Typography variant="body1">
                              {answer?.value ? t('Yes') : t('No')}
                            </Typography>
                          </Box>
                          <Typography
                            variant="body2"
                            sx={{
                              color: 'primary.main',
                              px: 2,
                              py: 0.5,
                              borderRadius: 1,
                              fontWeight: 500,
                              textAlign: 'right',
                              minWidth: 100,
                            }}
                          >
                            {value} / 1
                          </Typography>
                        </Box>
                      </Paper>
                    );
                  })}
                </Box>
              </Box>
            );
          }

          const sectionAnswers = Object.entries(marking.answers)
            .filter(([key]) => key.startsWith(section.id))
            .map(([key, value]: [string, any]) => ({ ...value, id: key }));

          return (
            <Box key={section.id}>
              {section.title && (
                <Typography variant="h6" sx={{ mb: 2 }}>
                  {getTranslatableField(section.title)}
                </Typography>
              )}
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                {sectionAnswers.map((answer) => (
                  <Paper
                    key={answer.id}
                    elevation={0}
                    sx={{ p: 2, bgcolor: 'background.paper' }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: 2,
                      }}
                    >
                      <Box sx={{ flex: 1 }}>
                        {answer.type !== 'question' && (
                          <Typography variant="body1" sx={{ fontWeight: 500 }}>
                            {typeof answer.title === 'object'
                              ? getTranslatableField(answer.title)
                              : answer.title}
                          </Typography>
                        )}
                        {answer.type === 'question' && (
                          <Typography variant="body1" sx={{ mt: 1 }}>
                            {answer.value || t('-')}
                          </Typography>
                        )}
                      </Box>
                      {answer.type !== 'question' &&
                        answer.maxValue &&
                        ['radio', 'range', 'checkbox'].includes(
                          answer.type
                        ) && (
                          <Typography
                            variant="body2"
                            sx={{
                              color: 'primary.main',
                              px: 2,
                              py: 0.5,
                              borderRadius: 1,
                              fontWeight: 500,
                              textAlign: 'right',
                              minWidth: 100,
                            }}
                          >
                            {answer.value} / {answer.maxValue}
                          </Typography>
                        )}
                    </Box>
                  </Paper>
                ))}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

function TaskMarkingHistory({
  userId,
  task,
  patient,
}: TaskMarkingHistoryProps) {
  const { t } = useTranslation();
  const { markings, loading, error } = useTaskMarkingHistory(userId, task);
  const { formatMarkingAsText } = useFormatMarking();
  const [expandedItem, setExpandedItem] = useState<number | null>(null);
  const [copiedIndex, setCopiedIndex] = useState<number | null>(null);
  const [copiedAll, setCopiedAll] = useState(false);

  const getAnswerKeys = useMemo(() => {
    return (marking: any) =>
      marking.answers ? Object.keys(marking.answers) : [];
  }, []);

  const handleToggleExpand = useCallback(
    (index: number) => {
      setExpandedItem(expandedItem === index ? null : index);
    },
    [expandedItem]
  );

  const handleCopyMarking = useCallback(
    (marking: any, index: number) => {
      const text = formatMarkingAsText(marking, task);
      navigator.clipboard.writeText(text).then(() => {
        setCopiedIndex(index);
        setTimeout(() => setCopiedIndex(null), 2000);
      });
    },
    [formatMarkingAsText, task]
  );

  const handleCopyAll = useCallback(() => {
    const text = markings
      .map((marking) => formatMarkingAsText(marking, task))
      .join('\n\n');
    navigator.clipboard.writeText(text).then(() => {
      setCopiedAll(true);
      setTimeout(() => setCopiedAll(false), 2000);
    });
  }, [markings, formatMarkingAsText, task]);

  const hasAnswers = useCallback(
    (marking: any) => {
      return marking.answers && getAnswerKeys(marking).length > 0;
    },
    [getAnswerKeys]
  );

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" p={2}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        {t('Error loading task history')}: {error.message}
      </Alert>
    );
  }

  if (!task) {
    return <Alert severity="info">{t('No task selected')}</Alert>;
  }

  if (markings.length === 0) {
    return (
      <Alert severity="info">{t('No markings found for this task')}</Alert>
    );
  }

  return (
    <Box>
      {markings.some(hasAnswers) && (
        <Box sx={{ mb: 2 }}>
          <Button
            startIcon={copiedAll ? '✓' : <ContentCopyIcon />}
            onClick={handleCopyAll}
            disabled={markings.length === 0}
            variant="outlined"
            size="small"
            color={copiedAll ? 'success' : 'primary'}
          >
            {copiedAll ? t('Copied!') : t('Copy All')}
          </Button>
        </Box>
      )}
      <Paper variant="outlined">
        <List disablePadding>
          {markings.map((marking, index) => (
            <Box key={index}>
              <ListItem
                sx={{
                  '&:hover': { bgcolor: 'action.hover' },
                  cursor: 'pointer',
                  py: 2,
                  px: 3,
                  pr: 12,
                }}
                onClick={() => handleToggleExpand(index)}
                secondaryAction={
                  <Box sx={{ display: 'flex', gap: 1, mr: 1 }}>
                    {hasAnswers(marking) && (
                      <IconButton
                        edge="end"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCopyMarking(marking, index);
                        }}
                        aria-label="copy"
                        size="small"
                        sx={{
                          color:
                            copiedIndex === index
                              ? 'success.main'
                              : 'action.active',
                        }}
                      >
                        {copiedIndex === index ? (
                          '✓'
                        ) : (
                          <ContentCopyIcon fontSize="small" />
                        )}
                      </IconButton>
                    )}
                    <IconButton
                      edge="end"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleToggleExpand(index);
                      }}
                      aria-label="expand"
                      size="small"
                      sx={{ color: 'action.active' }}
                    >
                      {expandedItem === index ? (
                        <KeyboardArrowUpIcon fontSize="small" />
                      ) : (
                        <KeyboardArrowDownIcon fontSize="small" />
                      )}
                    </IconButton>
                  </Box>
                }
              >
                <ListItemText
                  primary={
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: 2,
                      }}
                    >
                      <Box>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 500, mb: 0.5 }}
                        >
                          {marking.completedTime.toLocaleDateString()}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {marking.completedTime.toLocaleTimeString()}
                        </Typography>
                      </Box>
                      {marking.totalPoints !== undefined &&
                        hasAnswers(marking) && (
                          <Typography
                            variant="h6"
                            color="primary"
                            sx={{
                              fontWeight: 600,
                              bgcolor: 'primary.50',
                              px: 2,
                              py: 1,
                              borderRadius: 1,
                            }}
                          >
                            {marking.totalPoints} / {marking.totalMaxPoints}{' '}
                            {t('pts')}
                          </Typography>
                        )}
                    </Box>
                  }
                />
              </ListItem>
              <Collapse
                in={expandedItem === index}
                timeout="auto"
                unmountOnExit
              >
                <MarkingDetails marking={marking} task={task} />
              </Collapse>
              {index !== markings.length - 1 && <Divider />}
            </Box>
          ))}
        </List>
      </Paper>
    </Box>
  );
}

export default TaskMarkingHistory;
