import { useTranslation, Trans } from 'react-i18next';
import {
  Box,
  TextField,
  Typography,
  Autocomplete,
  Alert,
  Tooltip,
  CircularProgress,
  Tabs,
  Tab,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { doc, DocumentReference } from 'firebase/firestore';
import { Patient } from '../firebase/firebaseModels';
import { useAddPatient } from '../firebase/useAddPatient';
import usePatientCollection from '../firebase/usePatientCollection';
import { useFirestore } from 'reactfire';
import useUserProvider from '../firebase/useUserProvider';
import { useInviteAppUser } from '../firebase/useInviteAppUser';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { styled } from '@mui/material/styles';
import { getCurrentLanguageCode } from '../services/i18n';
import PendingIcon from '@mui/icons-material/Pending';
import Grid from '@mui/material/Grid2';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

interface PatientManagerProps {
  patients: DocumentReference<Patient>[];
  setPatients: (patients: DocumentReference<Patient>[]) => void;
  email: string | null;
  isGroup?: boolean;
  onPatientChange?: (patients: DocumentReference<Patient>[]) => void;
  disabled?: boolean;
}

interface PatientOption {
  id: string;
  label: string;
  pending?: boolean;
}

const StyledPhoneInput = styled(PhoneInput)`
  .PhoneInput {
    width: 100%;
  }

  .PhoneInputInput {
    height: 56px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    padding: 16.5px 14px;
    font-family: inherit;
    font-size: 1rem;
    width: 100%;
    background: none;

    &:hover {
      border-color: rgba(0, 0, 0, 0.87);
    }

    &:focus {
      outline: none;
      border-width: 2px;
      border-color: ${(props) => props.theme.palette.primary.main};
      padding: 15.5px 13px;
    }

    &:disabled {
      background-color: rgba(0, 0, 0, 0.12);
      border-color: rgba(0, 0, 0, 0.38);
    }
  }
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`patient-tabpanel-${index}`}
      aria-labelledby={`patient-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `patient-tab-${index}`,
    'aria-controls': `patient-tabpanel-${index}`,
  };
}

export default function PatientManager({
  patients,
  setPatients,
  email,
  isGroup = false,
  onPatientChange,
  disabled = false,
}: PatientManagerProps) {
  const { t } = useTranslation();
  const firestore = useFirestore();
  const { data: userProviderData } = useUserProvider();
  const [licenceCode, setLicenceCode] = useState('');
  const [remark, setRemark] = useState('');
  const [addMethod, setAddMethod] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>('');
  const [inviteWarning, setInviteWarning] = useState<string | null>(null);
  const [pendingLicenceCode, setPendingLicenceCode] = useState<string | null>(
    null
  );
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [pendingAction, setPendingAction] = useState<{
    type: 'invite' | 'add';
    licenceCode?: string;
  } | null>(null);

  const { isInviting, inviteError, inviteUser } = useInviteAppUser();

  const { status: patientCollectionStatus, data: patientCollectionData } =
    usePatientCollection();

  // console.log('patientCollectionData', patientCollectionData);
  const {
    addPatient,
    addPatientError,
    isUpdating: isAddingPatient,
  } = useAddPatient(email);

  const phoneUtil = useMemo(() => PhoneNumberUtil.getInstance(), []);
  const [phoneError, setPhoneError] = useState<string | null>(null);

  const validatePhoneNumber = useCallback(
    (phoneNumber: string) => {
      try {
        const parsedNumber = phoneUtil.parse(phoneNumber);
        if (!phoneUtil.isValidNumber(parsedNumber)) {
          setPhoneError(t('Invalid phone number format'));
          return false;
        }
        setPhoneError(null);
        return true;
      } catch (e) {
        setPhoneError(t('Invalid phone number'));
        console.error('Phone validation failed:', e);
        return false;
      }
    },
    [phoneUtil, t]
  );

  const onAddPatient = useCallback(
    async (licenceCode: string, remark: string) => {
      addPatient(licenceCode.toUpperCase(), remark, (newPatient) => {
        setLicenceCode('');
        setRemark('');
        setPatients([...patients, newPatient]);
        if (onPatientChange) {
          onPatientChange([...patients, newPatient]);
        }
      });
    },
    [addPatient, patients, setPatients, onPatientChange]
  );

  const patientOptions = useMemo(
    () =>
      patientCollectionData
        ?.filter((p) => p.id)
        .map((p) => ({
          id: p.id as string,
          label: `${p.licenceCode} / ${p.remark}`,
          pending: p.pending,
        })) || [],
    [patientCollectionData]
  );

  const patientValues = useMemo(() => {
    if (!patientCollectionData) {
      return isGroup ? [] : { id: '', label: '' };
    }

    if (!isGroup) {
      const p = patientCollectionData.find((pcp) => pcp.id === patients[0]?.id);
      return {
        id: p?.id || '',
        label: p?.id ? `${p?.licenceCode} / ${p?.remark}` : '',
        pending: p?.pending,
      };
    }

    return patients
      .map((patient) => {
        const p = patientCollectionData.find((pcp) => pcp.id === patient.id);
        if (!p) {
          return { id: '', label: '' };
        }
        return {
          id: p.id as string,
          label: `${p.licenceCode} / ${p.remark}`,
          pending: p.pending,
        };
      })
      .filter((p) => p.id !== '');
  }, [patientCollectionData, patients, isGroup]);

  const handlePatientChange = useCallback(
    (event: React.SyntheticEvent, newValue: any) => {
      if (!userProviderData || !email) {
        return;
      }
      const newPatients = isGroup
        ? newValue.map(
            (v: { id: string }) =>
              doc(
                firestore,
                'ServiceProvider',
                userProviderData.id,
                'UserData',
                email,
                'Patient',
                v.id
              ) as DocumentReference<Patient>
          )
        : newValue?.id
        ? [
            doc(
              firestore,
              'ServiceProvider',
              userProviderData.id,
              'UserData',
              email,
              'Patient',
              newValue.id
            ) as DocumentReference<Patient>,
          ]
        : [];

      setPatients(newPatients);
      if (onPatientChange) {
        onPatientChange(newPatients);
      }
    },
    [userProviderData, isGroup, firestore, email, setPatients, onPatientChange]
  );

  const handleInviteUser = useCallback(async () => {
    if (!phoneNumber || !email || !userProviderData) {
      return;
    }

    if (!validatePhoneNumber(phoneNumber)) {
      return;
    }

    setInviteWarning(null);
    try {
      const parsedNumber = phoneUtil.parse(phoneNumber);
      const formattedNumber = phoneUtil.format(
        parsedNumber,
        PhoneNumberFormat.E164
      );

      const result = await inviteUser(
        formattedNumber,
        getCurrentLanguageCode(),
        Intl.DateTimeFormat().resolvedOptions().timeZone,
        remark
      );

      if (result?.success && result?.licence_code) {
        setPendingLicenceCode(result.licence_code);
        setPhoneNumber('');
        setRemark('');
        if (result.message) {
          setInviteWarning(result.message);
        }
      }
    } catch (error) {
      console.error('Failed to invite user:', error);
      setInviteWarning(t('Failed to invite user'));
    }
  }, [
    phoneNumber,
    email,
    userProviderData,
    inviteUser,
    remark,
    phoneUtil,
    validatePhoneNumber,
    t,
  ]);

  const defaultCountry = useMemo(() => {
    const langCode = getCurrentLanguageCode();
    switch (langCode) {
      case 'fi':
        return 'FI';
      case 'sv':
        return 'SE';
      default:
        return 'US';
    }
  }, []);

  const hasPendingPatients = useMemo(() => {
    if (!patientCollectionData) {
      return false;
    }
    return patients.some((patient) => {
      const patientData = patientCollectionData.find(
        (p) => p.id === patient.id
      );
      return patientData?.pending;
    });
  }, [patients, patientCollectionData]);

  useEffect(() => {
    if (
      pendingLicenceCode &&
      patientCollectionData &&
      userProviderData &&
      email
    ) {
      const newPatient = patientCollectionData.find(
        (p) => p.licenceCode === pendingLicenceCode
      );
      if (newPatient?.id) {
        const patientRef = doc(
          firestore,
          'ServiceProvider',
          userProviderData.id,
          'UserData',
          email,
          'Patient',
          newPatient.id
        ) as DocumentReference<Patient>;

        const newPatients = [...patients, patientRef];
        setPatients(newPatients);
        if (onPatientChange) {
          onPatientChange(newPatients);
        }
        setPendingLicenceCode(null);
      }
    }
  }, [
    patientCollectionData,
    pendingLicenceCode,
    firestore,
    userProviderData,
    email,
    patients,
    setPatients,
    onPatientChange,
    patientOptions,
  ]);

  const handleAddPatientClick = useCallback(
    (licenceCode: string, remark: string) => {
      if (!remark.trim()) {
        setOpenConfirmDialog(true);
        setPendingAction({
          type: 'add',
          licenceCode: licenceCode.toUpperCase(),
        });
        return;
      }
      onAddPatient(licenceCode.toUpperCase(), remark);
    },
    [onAddPatient]
  );

  const handleInviteClick = useCallback(() => {
    if (!phoneNumber || !validatePhoneNumber(phoneNumber)) {
      return;
    }

    if (!remark.trim()) {
      setOpenConfirmDialog(true);
      setPendingAction({ type: 'invite' });
      return;
    }
    handleInviteUser();
  }, [remark, handleInviteUser, phoneNumber, validatePhoneNumber]);

  const handleConfirmAction = useCallback(() => {
    if (!pendingAction) return;

    if (pendingAction.type === 'add' && pendingAction.licenceCode) {
      onAddPatient(pendingAction.licenceCode, remark);
    } else if (pendingAction.type === 'invite') {
      handleInviteUser();
    }

    setOpenConfirmDialog(false);
    setPendingAction(null);
  }, [pendingAction, onAddPatient, remark, handleInviteUser]);

  if (!userProviderData) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Typography variant="body1" sx={{ mb: 2 }}>
        {t(
          'Add a patient to the course either by inviting them or by selecting an existing patient.'
        )}
      </Typography>

      <Autocomplete<PatientOption, boolean>
        multiple={isGroup}
        id="patients-outlined"
        loading={patientCollectionStatus === 'loading'}
        options={patientOptions}
        value={patientValues}
        onChange={handlePatientChange}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        disabled={disabled}
        renderOption={(props, option) => (
          <li {...props}>
            {option.pending && (
              <Tooltip title={t('Patient has not yet registered in the app')}>
                <PendingIcon
                  sx={{
                    mr: 1,
                    color: 'warning.main',
                    fontSize: '1.2rem',
                  }}
                />
              </Tooltip>
            )}
            {option.label}
          </li>
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            const { key, ...otherProps } = getTagProps({ index });
            return (
              <Chip
                key={key}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {option.pending && (
                      <Tooltip
                        title={t('Patient has not yet registered in the app')}
                      >
                        <PendingIcon
                          sx={{
                            mr: 0.5,
                            color: 'warning.main',
                            fontSize: '1rem',
                          }}
                        />
                      </Tooltip>
                    )}
                    {option.label}
                  </Box>
                }
                {...otherProps}
              />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            name="patients"
            variant="outlined"
            label={t('Patients')}
            placeholder={t('Add...')}
          />
        )}
      />

      {hasPendingPatients && (
        <Alert severity="warning" sx={{ mt: 2 }}>
          {t(
            'One or more selected patients have not yet registered in the app.'
          )}
        </Alert>
      )}

      <Tabs
        value={addMethod}
        onChange={(_, newValue) => setAddMethod(newValue)}
        variant="fullWidth"
        sx={{
          mt: 3,
          bgcolor: 'background.paper',
          borderRadius: 1,
          '& .MuiTab-root': {
            color: 'text.secondary',
            '&.Mui-selected': {
              color: 'primary.contrastText',
              bgcolor: 'primary.main',
            },
          },
        }}
      >
        <Tab label={t('Invite New Patient')} {...a11yProps(0)} />
        <Tab label={t('Add Existing Patient')} {...a11yProps(1)} />
      </Tabs>

      <CustomTabPanel value={addMethod} index={0}>
        <Box>
          <Grid container spacing={2}>
            <Grid size={3}>
              <StyledPhoneInput
                international
                defaultCountry={defaultCountry}
                value={phoneNumber}
                onChange={(value: string | undefined) => {
                  setPhoneNumber(value || '');
                  setPhoneError(null);
                }}
                disabled={disabled}
                error={!!phoneError}
              />
              {phoneError && (
                <Typography color="error" variant="caption" sx={{ mt: 1 }}>
                  {phoneError}
                </Typography>
              )}
            </Grid>
            <Grid size={9}>
              <TextField
                name="personal-identifier"
                label={t('Personal Identifier')}
                value={remark}
                onChange={(event) => setRemark(event.target.value)}
                fullWidth
                disabled={disabled}
              />
            </Grid>
          </Grid>
          <LoadingButton
            disabled={!phoneNumber || disabled}
            loading={isInviting || isAddingPatient}
            variant="contained"
            startIcon={<AddIcon />}
            sx={{ mt: 2 }}
            onClick={handleInviteClick}
          >
            {t('Invite Patient')}
          </LoadingButton>
          {inviteError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              <Trans>{inviteError}</Trans>
            </Alert>
          )}
          {inviteWarning && (
            <Alert severity="info" sx={{ mt: 2 }}>
              <Trans>{inviteWarning}</Trans>
            </Alert>
          )}
        </Box>
      </CustomTabPanel>

      <CustomTabPanel value={addMethod} index={1}>
        <Grid container spacing={2}>
          <Grid size={3}>
            <Tooltip
              title={t('The license code that has given to the patient')}
            >
              <TextField
                name="licence-code"
                label={t('License Code to Add')}
                value={licenceCode}
                onChange={(event) => setLicenceCode(event.target.value)}
                fullWidth
                disabled={disabled}
              />
            </Tooltip>
          </Grid>
          <Grid size={9}>
            <Tooltip
              title={t(
                'Information used to distinguish or identify an individual, without necessarily being as specific or formal as full legal names or government-issued identification numbers.'
              )}
            >
              <TextField
                name="personal-identifier"
                label={t('Personal Identifier')}
                value={remark}
                onChange={(event) => setRemark(event.target.value)}
                fullWidth
                disabled={disabled}
              />
            </Tooltip>
          </Grid>
        </Grid>
        <LoadingButton
          disabled={!licenceCode || disabled}
          loading={isAddingPatient}
          variant="contained"
          startIcon={<AddIcon />}
          sx={{ mt: 2 }}
          onClick={() => handleAddPatientClick(licenceCode, remark)}
        >
          {t('Add Patient')}
        </LoadingButton>
      </CustomTabPanel>

      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle>{t('Missing Personal Identifier')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t(
              'Are you sure you want to continue without adding a personal identifier?'
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)}>
            {t('Cancel')}
          </Button>
          <Button onClick={handleConfirmAction} autoFocus>
            {t('Continue')}
          </Button>
        </DialogActions>
      </Dialog>

      {addPatientError && (
        <Alert severity="error" sx={{ mt: 2 }}>
          <Trans>{addPatientError.message}</Trans>
        </Alert>
      )}
    </Box>
  );
}
