export type TaskType = 'generic' | 'questionnaire' | 'homework' | 'morning' | 'evening' | 'sleepRestriction';

export const taskTypeColors = {
  questionnaire: '#11CDEF', // Info color from theme
  sleepRestriction: '#F5365C', // Error color from theme
  generic: '#2DCE89', // Success color from theme
} as const;

export const getTaskTypeLabels = (t: (key: string) => string): Record<TaskType, string> => ({
  generic: t('Home Tasks'),
  questionnaire: t('Questionnaires'),
  homework: t('Homework Tasks'),
  morning: t('Morning Tasks'),
  evening: t('Evening Tasks'),
  sleepRestriction: t('Sleep Diary'),
}); 