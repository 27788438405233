import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import RestrictedIcon from '@mui/icons-material/NoAdultContent';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { SxProps } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { taskTypeColors } from '../constants/taskTypes';

export const getTaskTypeLabels = (t: (key: string) => string) => ({
  generic: t('Home Task'),
  questionnaire: t('Questionnaire'),
  homework: t('Homework'),
  morning: t('Morning Tasks'),
  evening: t('Evening Tasks'),
  sleepRestriction: t('Sleep Diary'),
});

export const useTaskTypeLabels = () => {
  const { t } = useTranslation();
  return getTaskTypeLabels(t);
};

type TaskTypeIconProps = {
  type: string;
  sx?: SxProps;
};

export function TaskTypeIcon({ type, sx = {} }: TaskTypeIconProps) {
  const taskTypeLabels = useTaskTypeLabels();
  const color =
    taskTypeColors[type as keyof typeof taskTypeColors] || '#757575';
  const tooltip = taskTypeLabels[type as keyof typeof taskTypeLabels] || type;

  const icon = (() => {
    switch (type) {
      case 'questionnaire':
        return (
          <FormatListBulletedIcon fontSize="small" sx={{ color, ...sx }} />
        );
      case 'sleepRestriction':
        return <RestrictedIcon fontSize="small" sx={{ color, ...sx }} />;
      case 'generic':
      default:
        return <AssignmentIcon fontSize="small" sx={{ color, ...sx }} />;
    }
  })();

  return <Tooltip title={tooltip}>{icon}</Tooltip>;
}
