import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { useTranslation, Trans } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Alert, { AlertColor } from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  CardHeader,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { getTranslatableField } from '../services/i18n';
import LoadingButton from '@mui/lab/LoadingButton';
import useTherapyCourseDoc from '../firebase/useTherapyCourseDoc';
import {
  StatisticsRowDataType,
  statisticTableLabelsAndKeys,
} from '../components/TherapyCourseOverview/types';
import { calculateRowData } from '../components/TherapyCourseOverview/calculateRowData';
import { StatisticsTableCell } from '../components/TherapyCourseOverview/StatisticsTableCell';
import useRequestToViewPatientData from '../firebase/useRequestToViewUserData';
import { useRefreshPatientStatistics } from '../hooks/useRefreshPatientStatistics';
import { useFirebaseUser } from '../firebase/useFirebaseUser';
import { Timestamp } from 'firebase/firestore';
import useStatisticsCacheDoc from '../firebase/useStatisticsCacheDoc';
import { useMaxMarkingDate } from '../components/TherapyCourseOverview/useMaxMarkingDate';
import {
  customTabA11yProps,
  CustomTabPanel,
} from '../components/CustomTabPanel';
import { usePatient, useAppUser } from '../contexts/PatientContext';

export default function Statistics() {
  const { t } = useTranslation();
  const { therapyCourseId, patientId: therapyCoursePatientId } = useParams<{
    patientId: string;
    therapyCourseId: string;
  }>();

  const [value, setValue] = React.useState(0);
  const {
    status: therapyCourseStatus,
    error: therapyCourseError,
    data: therapyCourseData,
  } = useTherapyCourseDoc(therapyCourseId as string);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const rangeLength = 'all';
  const fromDateStr = '2024-01-01';
  const toDateStr = '2024-12-31';

  const startDate = Timestamp.fromDate(new Date(fromDateStr + 'T00:00:00'));
  const endDate = Timestamp.fromDate(new Date(toDateStr + 'T23:59:59'));

  const {
    refreshPatientStatistics,
    disabled: refreshDisabled,
    isUpdating,
    statisticsHash,
  } = useRefreshPatientStatistics(
    therapyCoursePatientId || null,
    startDate || null,
    endDate || null
  );

  const patient = usePatient(therapyCoursePatientId || null);

  const appUser = useAppUser(patient);

  const { email } = useFirebaseUser();

  const { sendRequest, requestSent, requestError, approved, denied } =
    useRequestToViewPatientData(patient, email);

  useEffect(() => {
    if (
      !appUser ||
      !therapyCoursePatientId ||
      !approved ||
      refreshDisabled ||
      !statisticsHash
    ) {
      return;
    }

    const lastStatisticsCacheDate =
      appUser.statisticsCacheDate?.[statisticsHash as string];
    const needsRefresh =
      !lastStatisticsCacheDate ||
      Date.now() - lastStatisticsCacheDate.toMillis() >= 60 * 60 * 1000;

    if (needsRefresh) {
      refreshPatientStatistics(false);
    }
  }, [
    therapyCoursePatientId,
    refreshPatientStatistics,
    approved,
    denied,
    refreshDisabled,
    appUser,
    statisticsHash,
  ]);

  const therapyCoursePhases = useMemo(
    () => therapyCourseData?.phases || [],
    [therapyCourseData]
  );

  const currentPhase = useMemo(
    () =>
      therapyCoursePhases.find((phase, idx) => {
        const hasNextPhase = idx < therapyCoursePhases.length - 1;
        const nextPhaseStartDate = hasNextPhase
          ? therapyCoursePhases[idx + 1].startDate
          : null;
        const phaseStartDate = phase.startDate
          ? phase.startDate.toDate()
          : null;
        if (hasNextPhase && !nextPhaseStartDate) {
          return false;
        }
        const phaseEndDate = hasNextPhase
          ? nextPhaseStartDate
          : phase.endDate
          ? phase.endDate.toDate()
          : null;
        const now = new Date();
        return (
          !phaseStartDate ||
          (phaseStartDate <= now && (!phaseEndDate || phaseEndDate >= now))
        );
      }),
    [therapyCoursePhases]
  );

  const currentPhaseIndex = useMemo(
    () => (currentPhase ? therapyCoursePhases.indexOf(currentPhase) : null),
    [currentPhase, therapyCoursePhases]
  );

  const { data: statisticsCache } = useStatisticsCacheDoc(
    statisticsHash,
    patient?.user?.id || null
  );

  const statistics = useMemo(
    () => statisticsCache?.statistics || null,
    [statisticsCache?.statistics]
  );

  const { xAxis, series } = useMemo(() => {
    if (statistics) {
      const xAxisData = statistics.map((item, idx) => idx);
      const xAxis = [
        {
          type: t('time'),
          data: xAxisData,
        },
      ];
      const series = [
        {
          name: t('Sleep Efficiency'),
          data: statistics.map((item) => item.sleep_efficiency_moving_average),
        },
        {
          name: t('Task Completion Percentage'),
          data: statistics.map((item) => item.completed_tasks_moving_average),
        },
      ];
      return { xAxis, series };
    }
    return { xAxis: [], series: [] };
  }, [statistics, t]);

  const statisticsSlice = useMemo(
    () =>
      (rangeLength === 'all'
        ? statistics
        : statistics?.filter(
            (statistic) =>
              new Date(statistic.marking_date) >= new Date(fromDateStr) &&
              new Date(statistic.marking_date) <= new Date(toDateStr)
          )) || [],
    [statistics, rangeLength, fromDateStr, toDateStr]
  );

  const maxMarkingDate = useMaxMarkingDate();
  let maxDateAsStr = '';
  try {
    maxDateAsStr = maxMarkingDate.toISOString().substr(0, 10);
  } catch (e) {
    console.error(e);
  }

  const statisticTableData: StatisticsRowDataType[] = useMemo(() => {
    return statisticTableLabelsAndKeys.map(
      ({ label, key, type, dismissZero, statisticsGroup }, idx) => {
        return {
          id: `statistic-${idx}`,
          label: t(label),
          statisticsGroup,
          type,
          rowNr: idx + 1,
          ...calculateRowData(
            type,
            statisticsSlice,
            key,
            maxDateAsStr,
            dismissZero
          ),
        };
      }
    );
  }, [maxDateAsStr, statisticsSlice, t]);

  const columns: GridColDef<StatisticsRowDataType>[] = useMemo(
    () =>
      [
        {
          field: 'rowNr',
          width: 70,
          headerName: t('Row #'),
        },
        {
          field: 'label',
          headerName: t('Label'),
          flex: 1,
        },
        {
          field: 'min',
          headerName: t('Min'),
          width: 90,
          renderCell: ({ row, colDef }) => {
            const { label, type, min, max, average, nValues } = row;
            return (
              <StatisticsTableCell
                key={`${row.id}-min`}
                type={type}
                label={label}
                index={0}
                min={min}
                max={max}
                average={average}
                nValues={nValues}
                colType="min"
                colDef={colDef}
              />
            );
          },
        },
        {
          field: 'max',
          headerName: t('Max'),
          width: 90,
          renderCell: ({ row, colDef }) => {
            const { label, type, min, max, average, nValues } = row;
            return (
              <StatisticsTableCell
                key={`${row.id}-max`}
                type={type}
                label={label}
                index={0}
                min={min}
                max={max}
                average={average}
                nValues={nValues}
                colType="max"
                colDef={colDef}
              />
            );
          },
        },
        {
          field: 'average',
          headerName: t('Average'),
          width: 90,
          renderCell: ({ row, colDef }) => {
            const { label, type, min, max, average, nValues } = row;
            return (
              <StatisticsTableCell
                key={`${row.id}-average`}
                type={type}
                label={label}
                index={0}
                min={min}
                max={max}
                average={average}
                nValues={nValues}
                colType="average"
                colDef={colDef}
              />
            );
          },
        },
        {
          field: 'nValues',
          headerName: t('N Values'),
          width: 90,
          renderCell: ({ value, colDef }) => (
            <Typography
              sx={{ width: colDef.computedWidth, display: 'inline-block' }}
            >
              {value ? `${value}` : t('No data')}
            </Typography>
          ),
        },
      ] as GridColDef<StatisticsRowDataType>[],
    [t]
  );

  if (
    therapyCourseStatus === 'loading' ||
    !!appUser?.statistics_cache_updating ||
    isUpdating
  ) {
    return <CircularProgress />;
  }

  if (requestError) {
    return <Alert severity="error">{requestError}</Alert>;
  }

  if (therapyCourseStatus === 'error') {
    return <Alert severity="error">{therapyCourseError?.message}</Alert>;
  }

  if (!appUser) {
    return <Alert severity="error">{t('Patient not found')}</Alert>;
  }

  let responseMessage: JSX.Element | null = null;
  let responseSeverity: AlertColor = 'info';

  if (!approved && !denied) {
    responseMessage = requestSent ? (
      <Trans>
        Your request to view this patient data has been sent. Please wait for
        approval.
      </Trans>
    ) : (
      <Trans>You do not have permission to view this patient's data</Trans>
    );
  } else if (denied) {
    responseSeverity = 'error';
    responseMessage = (
      <Trans>Your request to view this patient's data has been denied.</Trans>
    );
  }

  if (responseMessage) {
    return (
      <Alert severity={responseSeverity}>
        <Typography gutterBottom>{responseMessage}</Typography>
        {(!requestSent || denied) && (
          <LoadingButton
            onClick={() => sendRequest()}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
          >
            {t('Request Access')}
          </LoadingButton>
        )}
      </Alert>
    );
  }

  return (
    <>
      <Typography variant="h4" gutterBottom sx={{ mt: 2 }}>
        {`${patient?.licenceCode} - ${patient?.remark}`}
      </Typography>
      <Paper sx={{ mt: 2, width: '100%' }}>
        <Card sx={{ mt: 2 }}>
          <CardHeader
            title={
              currentPhase
                ? `${t('Current Phase')}: ${
                    getTranslatableField(currentPhase.title) ||
                    `${t('Phase')} ${
                      currentPhaseIndex ? currentPhaseIndex + 1 : ''
                    }`
                  }`
                : t('No phase')
            }
          />
          <CardContent>
            {currentPhase ? (
              <>
                <Typography variant="body1">
                  {t('Start Date')}:{' '}
                  {currentPhase.startDate?.toDate().toLocaleDateString()}
                </Typography>
                <Typography variant="body1">
                  {t('End Date')}:{' '}
                  {currentPhase.endDate?.toDate().toLocaleDateString()}
                </Typography>
              </>
            ) : (
              <Alert severity="info">{t('No phase active')}</Alert>
            )}
          </CardContent>
        </Card>
      </Paper>

      <Paper sx={{ mt: 2, width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label={t('Statistics Table')} {...customTabA11yProps(0)} />
            <Tab label={t('Progress Over Time')} {...customTabA11yProps(1)} />
            <Tab label={t('Day Graph')} {...customTabA11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={1}>
          <Typography variant="h5" component="h1" gutterBottom>
            {t('Progress Over Time')}
          </Typography>
          <LineChart
            xAxis={xAxis}
            series={series}
            width={600}
            height={400}
            grid={{ vertical: true, horizontal: true }}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={0}>
          <Typography variant="h5" component="h1" gutterBottom>
            {t('Statistics Table')}
          </Typography>
          <DataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: 'rowNr', sort: 'asc' }],
              },
            }}
            autoHeight
            columns={columns}
            rows={statisticTableData}
            hideFooter
            rowSelection={false}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Trans>Coming soon</Trans>
        </CustomTabPanel>
      </Paper>
    </>
  );
}
