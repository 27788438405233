import { Trans, useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import {
  doc,
  collection,
  updateDoc,
  addDoc,
  Timestamp,
} from 'firebase/firestore';
import {
  TaskPoolTaskCategory,
  TranslatableField,
} from '../firebase/firebaseModels';
import {
  CardHeader,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  useTheme,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import LanguageSelector from '../components/LanguageSelector';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useFirestore } from 'reactfire';
import useTaskPoolTaskCategoryDoc from '../firebase/useTaskPoolTaskCategoryDoc';
import useUserProvider from '../firebase/useUserProvider';
import useTaskPoolCollection from '../firebase/useTaskPoolCollection';
import { getCurrentLanguageCode } from '../services/i18n';
import LoadingButton from '@mui/lab/LoadingButton';
import { MuiColorInput } from 'mui-color-input';
import { useFirebaseUser } from '../firebase/useFirebaseUser';
import CreatedModifiedTable from '../components/CreatedModifiedTable';
import TranslatableTextField from '../components/TranslatableTextField';
import TranslatableEditor from '../components/TranslatableEditor';
import usePortalUser from '../firebase/usePortalUser';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function TaskPoolTaskCategoryEditor() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams<{
    taskPoolTaskCategoryId: string;
    taskcategorytype: string;
  }>();
  const { taskPoolTaskCategoryId, taskcategorytype } = params;

  const firestore = useFirestore();
  const { email } = useFirebaseUser();
  const { isSuperAdmin, isOrganizationAdmin } = usePortalUser();

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogClose = useCallback(() => {
    setDialogOpen(false);
  }, []);
  const [dialogDescription, setDialogDescription] = useState<string>('');
  const [dialogType, setDialogType] = useState<
    'save' | 'promote' | 'demote' | null
  >(null);

  const {
    data: userProviderData,
    status: userProviderStatus,
    providerRef,
  } = useUserProvider();
  const theme = useTheme();

  const { status: taskPoolStatus, data: taskPoolData } =
    useTaskPoolCollection();

  const [defaultLanguage, setDefaultLanguage] = useState(
    getCurrentLanguageCode()
  );
  const [language, setLanguage] = useState(defaultLanguage);
  const [title, setTitle] = useState<TranslatableField>({
    [defaultLanguage]: '',
  });
  const [description, setDescription] = useState<TranslatableField>({
    [defaultLanguage]: '',
  });
  const [color, setColor] = useState<string>(theme.palette.text.primary);
  const [amOwner, setAmOwner] = useState<boolean>(false);
  const [isPublic, setIsPublic] = useState<boolean>(false);

  // const [tasks, setTasks] = useState<TaskPoolTask[]>([]);
  const { status, error, data } = useTaskPoolTaskCategoryDoc(
    taskPoolTaskCategoryId as string
  );

  const [updating, setUpdating] = useState(false);
  const [updateSuccessful, setUpdateSuccessful] = useState(false);
  const isGloballyAvailable = isPublic && data?.provider === null;

  const canEdit =
    isSuperAdmin ||
    amOwner ||
    (!amOwner && data?.provider?.id === providerRef?.id && isOrganizationAdmin);

  useEffect(() => {
    if (data && taskPoolData) {
      setTitle(data.title);
      setDescription(data.description);
      setColor(data.color);
      setLanguage(data.defaultLanguage);
      setDefaultLanguage(data.defaultLanguage);
      setAmOwner(data.amOwner || false);
      if (taskPoolTaskCategoryId === 'new') {
        setIsPublic(taskcategorytype !== 'own');
      } else {
        setIsPublic(data.public || false);
      }
    }
  }, [data, taskPoolTaskCategoryId, taskPoolData, taskcategorytype]);

  const updateTaskPoolTaskCategory = useCallback(async () => {
    if (
      !data ||
      !title ||
      !description ||
      !userProviderData?.id ||
      !email ||
      !color ||
      !providerRef ||
      !defaultLanguage ||
      updating
    ) {
      return;
    }
    setUpdating(true);
    setUpdateSuccessful(false);
    let languages = Object.keys(title).filter((lang) => !!title[lang]);
    Object.keys(description).forEach((lang) => {
      if (!!description[lang] && !languages.includes(lang)) {
        languages.push(lang);
      }
    });

    const updateData: Partial<TaskPoolTaskCategory> = {
      title,
      description,
      color,
      languages,
      modified: Timestamp.now(),
      modifiedBy: email,
      defaultLanguage,
      public: isPublic,
    };
    if (taskPoolTaskCategoryId && taskPoolTaskCategoryId !== 'new') {
      const taskPoolTaskCategoryRef = doc(
        firestore,
        'TaskPoolTaskCategories',
        taskPoolTaskCategoryId
      );
      updateDoc(taskPoolTaskCategoryRef, updateData)
        .then(async () => {
          setUpdating(false);
          setUpdateSuccessful(true);
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
          setUpdating(false);
        });
    } else {
      const taskPoolTaskCategoryRef = collection(
        firestore,
        'TaskPoolTaskCategories'
      );
      const docData: TaskPoolTaskCategory = {
        ...data,
        ...updateData,
        created: Timestamp.now(),
        createdBy: email,
      };
      delete docData.id;
      delete docData.amOwner;
      addDoc(taskPoolTaskCategoryRef, docData)
        .then(async (newDocRef) => {
          setUpdating(false);
          setUpdateSuccessful(true);
          navigate(`/admin/taskcategories/${newDocRef.id}`);
        })
        .catch((error) => {
          console.error('Error adding document: ', error);
          setUpdating(false);
        });
    }
  }, [
    data,
    title,
    description,
    userProviderData?.id,
    email,
    color,
    providerRef,
    defaultLanguage,
    updating,
    isPublic,
    taskPoolTaskCategoryId,
    firestore,
    navigate,
  ]);

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (
        !data ||
        !title ||
        !description ||
        !userProviderData?.id ||
        !email ||
        !color ||
        !providerRef ||
        !defaultLanguage ||
        updating
      ) {
        return;
      }
      if (!amOwner && !isSuperAdmin) {
        return;
      }
      if (!amOwner) {
        setDialogOpen(true);
        setDialogDescription(
          t(
            'You are not the owner of this task category. Are you sure you want to save changes?'
          )
        );
        setDialogType('save');
      } else {
        updateTaskPoolTaskCategory();
      }
    },
    [
      data,
      title,
      description,
      userProviderData?.id,
      email,
      color,
      providerRef,
      defaultLanguage,
      updating,
      amOwner,
      isSuperAdmin,
      t,
      updateTaskPoolTaskCategory,
    ]
  );

  const confirmPromoteToGlobal = useCallback(() => {
    if (!taskPoolTaskCategoryId) {
      return;
    }
    const taskCategoryRef = doc(
      firestore,
      'TaskPoolTaskCategories',
      taskPoolTaskCategoryId
    );
    updateDoc(taskCategoryRef, {
      public: true,
      provider: null,
    });
  }, [firestore, taskPoolTaskCategoryId]);

  const confirmDemoteToPrivate = useCallback(() => {
    if (!taskPoolTaskCategoryId || !providerRef) {
      return;
    }
    const taskCategoryRef = doc(
      firestore,
      'TaskPoolTaskCategories',
      taskPoolTaskCategoryId
    );
    updateDoc(taskCategoryRef, {
      public: false,
      provider: providerRef,
    });
  }, [firestore, providerRef, taskPoolTaskCategoryId]);

  const promoteToGlobal = useCallback(() => {
    if (!isSuperAdmin) {
      return;
    }
    setDialogDescription(
      t('Are you sure you want to make this category public?')
    );
    setDialogOpen(true);
    setDialogType('promote');
  }, [isSuperAdmin, t]);

  const demoteToPrivate = useCallback(() => {
    if (!isSuperAdmin) {
      return;
    }
    setDialogDescription(
      t('Are you sure you want to make this category private?')
    );
    setDialogOpen(true);
    setDialogType('demote');
  }, [isSuperAdmin, t]);

  const handleConfirm = useCallback(() => {
    setDialogOpen(false);
    switch (dialogType) {
      case 'save':
        updateTaskPoolTaskCategory();
        break;
      case 'promote':
        confirmPromoteToGlobal();
        break;
      case 'demote':
        confirmDemoteToPrivate();
        break;
    }
  }, [
    confirmDemoteToPrivate,
    confirmPromoteToGlobal,
    dialogType,
    updateTaskPoolTaskCategory,
  ]);

  const onChangeColor = useCallback(
    (color: string) => {
      // console.log('onChangeColor', color);
      setColor(color);
    },
    [setColor]
  );

  const handlePublicChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setIsPublic(event.target.checked),
    []
  );

  const titleExistsInPrimaryLanguage = !!title[defaultLanguage];

  if (userProviderStatus === 'loading' || taskPoolStatus === 'loading') {
    return <CircularProgress />;
  }

  return (
    <>
      {status === 'loading' && <CircularProgress sx={{ mt: 2 }} />}
      {error && <Alert severity="error">{error.message}</Alert>}
      {status === 'success' && (
        <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
          <Card sx={{ mt: 2 }} variant="outlined">
            <CardHeader
              title={t('Basics')}
              subheader={t('Select the language to edit')}
              action={
                <LanguageSelector
                  disabled={!canEdit}
                  language={language}
                  defaultLanguage={defaultLanguage}
                  setLanguage={setLanguage}
                  setDefaultLanguage={setDefaultLanguage}
                />
              }
            />
            <CardContent>
              <TranslatableTextField
                key={`task-category-title-${language}`}
                name={`task-category-title-${language}`}
                disabled={!canEdit}
                label={t('Task Category Name')}
                helperText={
                  !titleExistsInPrimaryLanguage
                    ? t('Name is required in primary language')
                    : undefined
                }
                error={!titleExistsInPrimaryLanguage}
                language={language}
                type="title"
                setValue={setTitle}
                value={title}
                placeholder={t('Enter Name')}
              />
              <TranslatableEditor
                key={`task-category-description-${language}`}
                name={`task-category-description-${language}`}
                disabled={!canEdit}
                placeholder={t('Description')}
                value={description}
                setValue={setDescription}
                language={language}
                type="description"
              />
              <br />
              <MuiColorInput
                disabled={!canEdit}
                format="hex"
                value={color}
                onChange={onChangeColor}
                label={t('Color')}
              />
            </CardContent>
          </Card>
          {!isGloballyAvailable && (isOrganizationAdmin || isSuperAdmin) && (
            <Card sx={{ mt: 2 }} variant="outlined">
              <CardHeader
                title={t('Administration')}
                subheader={t('Options')}
              />
              <CardContent>
                <FormGroup>
                  <FormControlLabel
                    disabled={!isOrganizationAdmin && !isSuperAdmin}
                    control={
                      <Checkbox
                        checked={isPublic}
                        onChange={handlePublicChange}
                      />
                    }
                    label={t('Show this category to all users of organization')}
                  />
                </FormGroup>
              </CardContent>
            </Card>
          )}

          {updateSuccessful && (
            <Alert
              severity="success"
              sx={{ mt: 2 }}
              onClose={() => setUpdateSuccessful(false)}
            >
              {t('Save successful')}
            </Alert>
          )}
          {canEdit && (
            <LoadingButton
              loading={updating}
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
              disabled={updating || !titleExistsInPrimaryLanguage}
            >
              {t('Save')}
            </LoadingButton>
          )}
          {isSuperAdmin && isGloballyAvailable && (
            <Button
              color="error"
              variant="contained"
              sx={{ mt: 2, ml: 2 }}
              onClick={demoteToPrivate}
            >
              {t('Make Private')}
            </Button>
          )}
          {isSuperAdmin && !isGloballyAvailable && (
            <Button
              color="error"
              variant="contained"
              sx={{ mt: 2, ml: 2 }}
              onClick={promoteToGlobal}
            >
              {t('Make Default')}
            </Button>
          )}
          <CreatedModifiedTable
            isNew={taskPoolTaskCategoryId === 'new'}
            data={data}
          />
        </Box>
      )}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Trans>Are you sure?</Trans>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogDescription}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>
            <Trans>Cancel</Trans>
          </Button>
          <Button onClick={handleConfirm} autoFocus>
            <Trans>OK</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
