import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Collapse from '@mui/material/Collapse';
import MuiDrawer from '@mui/material/Drawer';
import { UIMatch, useMatches, useNavigate } from 'react-router-dom';
import {
  CircularProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  useTheme,
} from '@mui/material';
import { drawerWidth } from '../consts';
import { useTranslation } from 'react-i18next';
import { AppRoute, useDashboardRoutes } from '../hooks/useDashboardRoutes';
import Divider from '@mui/material/Divider';
import React, {
  MouseEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { matchPath } from 'react-router-dom';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

// const DrawerMenuNavLink = forwardRef<HTMLAnchorElement, any>((props, ref) => (
//   <NavLink
//     ref={ref}
//     {...props}
//     // className={({ isActive }) =>
//     //   isActive ? props.className + ' Mui-selected' : props.className
//     // }
//     // end
//   />
// ));

/**
 * Props of the {@link DrawerMenu} component.
 * @category Props
 */
type DrawerMenuProps = {
  /**
   * Whether the drawer is open.
   * @see {@link Dashboard}
   */
  open: boolean;
  /**
   * Function to toggle the drawer.
   * @see {@link Dashboard}
   */
  toggleDrawer: () => void;
};

/**
 * Styled Drawer component. Extends MUI's {@link https://mui.com/api/drawer/ | Drawer}.
 *
 * @component Component for the drawer menu.
 * @subcategory Custom
 */
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(8),
      },
    }),
  },
}));

/**
 * Custom DrawerMenu component. Extends MUI's {@link https://mui.com/api/drawer/ | Drawer}.
 * Uses {@link useDashboardRoutes} hook.
 *
 * @param props  Props of the {@link DrawerMenu} component.
 * @returns      JSX.Element
 */
export default function DrawerMenu(props: DrawerMenuProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { open, toggleDrawer } = props;
  const routes = useDashboardRoutes();
  const matches = useMatches();
  const navigate = useNavigate();
  const handleNavigate = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      const path = event.currentTarget.getAttribute('data-path');
      if (path) {
        navigate(path);
      }
    },
    [navigate]
  );
  const [openCollapses, setOpenCollapses] = useState<string[]>([]);

  useEffect(() => {
    if (!routes) {
      return;
    }
    const currentCollapses = routes.filter((route) => {
      return (
        route.type === 'collapse' &&
        window.location.pathname.startsWith(route.path!)
      );
    });
    if (currentCollapses.length > 0) {
      setOpenCollapses(currentCollapses.map((collapse) => collapse.path!));
    } else {
      setOpenCollapses(['/']);
    }
  }, [routes]);

  const handleToggleCollapse = useCallback(
    (path: string) => {
      if (openCollapses.includes(path)) {
        setOpenCollapses(openCollapses.filter((collapse) => collapse !== path));
      } else {
        setOpenCollapses([...openCollapses, path]);
      }
    },
    [openCollapses]
  );

  const renderNavigation = (
    appRoute: AppRoute,
    idx: number,
    parentPath?: string
  ): ReactNode => {
    let { path, icon, label, type, tabs, children } = appRoute;
    const fullPath = parentPath ? `${parentPath}${path}` : `${path}`;
    switch (type) {
      case 'divider':
        return <Divider key={`divider-${idx}`} />;
      case 'tabs': {
        const pathMatched = tabs!.some(
          (tab) =>
            matchPath(tab.path, window.location.pathname) ||
            children?.some(
              (child) =>
                !!child.path &&
                matchPath(tab.path + child.path, window.location.pathname)
            )
        );
        return pathMatched ? (
          <List
            key={fullPath}
            component="nav"
            aria-label="sub-navigation"
            dense
            sx={{ pl: 1, py: 0 }}
          >
            {tabs!.map((tab) => {
              const selected = matchPath(tab.path, window.location.pathname);
              const parentIsMatched = matches.some(
                (match: UIMatch<any, any>) => match.pathname === tab.path
              );
              return (
                <React.Fragment key={`${tab.path}-frg`}>
                  <ListItemButton
                    key={`tab-${tab.path}`}
                    data-path={tab.path}
                    onClick={handleNavigate}
                    selected={!!selected}
                  >
                    <ListItemIcon>
                      <SubdirectoryArrowRightIcon />
                    </ListItemIcon>
                    <ListItemText primary={t(tab.label)} />
                  </ListItemButton>
                  {parentIsMatched &&
                    children?.map((child, idx) =>
                      renderNavigation(child, idx, tab.path)
                    )}
                </React.Fragment>
              );
            })}
          </List>
        ) : null;
      }
      case 'sub-level': {
        const selected = matchPath(fullPath!, window.location.pathname);
        if (window.location.pathname.endsWith('/new')) {
          label = 'New';
        }
        return selected ? (
          <ListItemButton
            selected={!!selected}
            key={fullPath}
            data-path={fullPath}
            dense
            sx={{ pl: 4, py: 0 }}
          >
            <ListItemIcon>
              <SubdirectoryArrowRightIcon />
            </ListItemIcon>
            <ListItemText primary={t(label!)} />
          </ListItemButton>
        ) : null;
      }
      case 'collapse': {
        const collapseOpen = openCollapses.includes(fullPath!);
        return (
          <React.Fragment key={`${fullPath}-frg`}>
            <ListItemButton
              key={`${fullPath}-btn`}
              data-path={fullPath}
              onClick={() => handleToggleCollapse(fullPath!)}
              sx={{
                py: 0.5,
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                },
              }}
            >
              <ListItemText primary={t(label!)} />
              {collapseOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding sx={{ pl: 2 }} dense>
                {children?.map((child, idx) =>
                  renderNavigation(child, idx, fullPath)
                )}
              </List>
            </Collapse>
          </React.Fragment>
        );
      }
      default: {
        const selected = matchPath(fullPath!, window.location.pathname);

        return (
          <React.Fragment key={`${fullPath}-frg`}>
            <ListItemButton
              selected={!!selected}
              key={fullPath}
              data-path={fullPath}
              onClick={handleNavigate}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={t(label!)} />
            </ListItemButton>
            {children?.map((child, idx) =>
              renderNavigation(child, idx, fullPath)
            )}
          </React.Fragment>
        );
      }
    }
  };

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider key="divider" />
      {routes ? (
        <List component="nav" aria-label="main navigation">
          {routes.map((route, idx) => renderNavigation(route, idx))}
        </List>
      ) : (
        <CircularProgress />
      )}
    </Drawer>
  );
}
