import { useTranslation, Trans } from 'react-i18next';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import dayjs from 'dayjs';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LinkIcon from '@mui/icons-material/Link';
import { Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import {
  doc,
  collection,
  updateDoc,
  addDoc,
  DocumentReference,
  Timestamp,
  DocumentData,
} from 'firebase/firestore';
import {
  Patient,
  TaskPoolTask,
  TherapyCourse,
  TherapyCoursePhasePatient,
  TherapyCoursePhase,
  TranslatableField,
} from '../firebase/firebaseModels';
import {
  CardHeader,
  CircularProgress,
  FormGroup,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import LanguageSelector from '../components/LanguageSelector';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFirestore } from 'reactfire';
import useTherapyCourseDoc from '../firebase/useTherapyCourseDoc';
import useUserProvider from '../firebase/useUserProvider';
import useTaskPoolCollection from '../firebase/useTaskPoolCollection';
import { getCurrentLanguageCode } from '../services/i18n';
import LoadingButton from '@mui/lab/LoadingButton';
import usePatientCollection from '../firebase/usePatientCollection';
import TherapyCourseEditorPhase from '../components/TherapyCourseEditor/TherapyCourseEditorPhase';
import { useFirebaseUser } from '../firebase/useFirebaseUser';
// import { useUpdatePatientCourses } from '../firebase/useUpdatePatientCourses';
import useTaskPoolTaskCategoryCollection from '../firebase/useTaskPoolTaskCategoryCollection';
import TranslatableTextField from '../components/TranslatableTextField';
import TranslatableEditor from '../components/TranslatableEditor';
import usePortalUser from '../firebase/usePortalUser';
// import { useCleanPatientCourses } from '../firebase/useCleanPatientCourses';
import uuid from 'react-native-uuid';
import { useTaskPoolOptions } from '../hooks/useTaskPoolOptions';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useStrictDroppable } from '../hooks/useStrictDroppable';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import PatientManager from '../components/PatientManager';
import WarningIcon from '@mui/icons-material/Warning';

// Helper function for accessibility props on tabs
function a11yProps(index: number) {
  return {
    id: `phase-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// Ensures all phases have unique IDs by adding UUIDs where missing
function addMissingIdsToPhases(phases: TherapyCoursePhase[]) {
  // if no ids are missing, return same object
  if (phases.every((phase) => phase.id)) {
    return phases;
  }
  // otherwise add missing ids
  return phases.map((phase, index) => {
    if (!phase.id) {
      return { ...phase, id: uuid.v4() } as TherapyCoursePhase;
    }
    return phase;
  });
}

function validatePhaseDates(phases: TherapyCoursePhase[]): {
  hasError: boolean;
  errorPhases: number[];
} {
  const errorPhases: number[] = [];

  for (let i = 0; i < phases.length - 1; i++) {
    const currentPhase = phases[i];
    const nextPhase = phases[i + 1];

    // Only validate if both phases have start dates
    if (currentPhase.startDate && nextPhase.startDate) {
      const currentStartDate = currentPhase.startDate.toDate();
      const nextStartDate = nextPhase.startDate.toDate();

      // Compare dates and mark both phases if current start date is after next start date
      if (currentStartDate >= nextStartDate) {
        errorPhases.push(i, i + 1);
      }
    }
  }

  return {
    hasError: errorPhases.length > 0,
    errorPhases: Array.from(new Set(errorPhases)), // Remove duplicates
  };
}

// Add new validation function
function validatePhases(phases: TherapyCoursePhase[]): {
  hasDateError: boolean;
  dateErrorPhases: number[];
  hasTitleError: boolean;
  titleErrorPhases: number[];
  hasTaskError: boolean;
  taskErrorPhases: number[];
} {
  const dateValidation = validatePhaseDates(phases);
  const titleErrorPhases: number[] = [];
  const taskErrorPhases: number[] = [];

  phases.forEach((phase, index) => {
    // Check for missing titles in default language
    if (
      !phase.title ||
      !Object.values(phase.title).some((title) => title.trim())
    ) {
      titleErrorPhases.push(index);
    }

    // Check for missing tasks
    if (!phase.phaseTasks || phase.phaseTasks.length === 0) {
      taskErrorPhases.push(index);
    }
  });

  return {
    hasDateError: dateValidation.hasError,
    dateErrorPhases: dateValidation.errorPhases,
    hasTitleError: titleErrorPhases.length > 0,
    titleErrorPhases,
    hasTaskError: taskErrorPhases.length > 0,
    taskErrorPhases,
  };
}

type TherapyCourseEditorProps = {
  therapycoursetype: 'group' | 'individual' | null;
};
export default function TherapyCourseEditor(props: TherapyCourseEditorProps) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { therapyCourseId } = useParams<{ therapyCourseId: string }>();
  const firestore = useFirestore();
  const { email } = useFirebaseUser();

  const { data: portalUserData } = usePortalUser();

  const [startImmediately, setStartImmediately] = useState(false);
  const [therapycoursetype, setTherapyCourseType] = useState<
    'group' | 'individual'
  >(props.therapycoursetype !== null ? props.therapycoursetype : 'group');
  const {
    data: userProviderData,
    status: userProviderStatus,
    error: userProviderError,
  } = useUserProvider();

  const { data: patientCollectionData, error: patientCollectionError } =
    usePatientCollection();

  const [defaultLanguage, setDefaultLanguage] = useState(
    getCurrentLanguageCode()
  );

  const [title, setTitle] = useState<TranslatableField>({
    [defaultLanguage]: '',
  });
  const [language, setLanguage] = useState(defaultLanguage);

  const [description, setDescription] = useState<TranslatableField>({
    [defaultLanguage]: '',
  });
  const [defaultInterval, setDefaultInterval] = useState<number>(7);
  const [therapistName, setTherapistName] = useState('');
  const [phases, setPhases] = useState<TherapyCoursePhase[]>([]);
  const [patients, setPatients] = useState<DocumentReference<Patient>[]>([]);
  // const [tasks, setTasks] = useState<TaskPoolTask[]>([]);
  const [currentPhase, setCurrentPhase] = useState(0);

  // Calculate available therapy languages based on portal user settings and current languages
  const therapyLanguages = useMemo(() => {
    let therapyLanguagesTmp = [...(portalUserData?.therapyLanguages || [])];
    if (!therapyLanguagesTmp.includes(defaultLanguage)) {
      therapyLanguagesTmp.push(defaultLanguage);
    }
    if (!therapyLanguagesTmp.includes(language)) {
      therapyLanguagesTmp.push(language);
    }
    return therapyLanguagesTmp;
  }, [portalUserData?.therapyLanguages, defaultLanguage, language]);

  // Track only task-related changes in phases
  const phaseTaskIds = useMemo(() => {
    const ids = new Set<string>();
    phases.forEach((phase) => {
      phase.phaseTasks?.forEach((task) => {
        if (task.id) {
          ids.add(task.id);
        }
      });
    });
    return Array.from(ids);
  }, [
    JSON.stringify(
      phases.map((phase) => phase.phaseTasks?.map((task) => task.id))
    ),
  ]);

  const {
    status: taskPoolStatus,
    data: taskPoolData,
    error: taskPoolError,
  } = useTaskPoolCollection(null, therapyLanguages, phaseTaskIds);

  const {
    status: categoryStatus,
    data: categoryData,
    error: collectionError,
  } = useTaskPoolTaskCategoryCollection();

  const {
    status,
    error,
    data: course,
  } = useTherapyCourseDoc(
    therapyCourseId as string,
    props.therapycoursetype || 'group'
  );

  const [updating, setUpdating] = useState(false);
  const [updateSuccessful, setUpdateSuccessful] = useState(false);
  const [ongoingPhase, setOngoingPhase] = useState<number | null>(null);
  const [completed, setCompleted] = useState(false);
  const [completedDate, setCompletedDate] = useState<Timestamp | null>(null);
  const courseIsActive = course?.ongoingPhase !== null;
  const courseIsCompleted = course?.completed || false;

  // Replace the dateValidation state with phaseValidation
  const [phaseValidation, setPhaseValidation] = useState<
    ReturnType<typeof validatePhases>
  >({
    hasDateError: false,
    dateErrorPhases: [],
    hasTitleError: false,
    titleErrorPhases: [],
    hasTaskError: false,
    taskErrorPhases: [],
  });

  useEffect(() => {
    if (course && status === 'success') {
      setTitle(course.title);
      setDescription(course.description);
      setPhases(course.phases ? addMissingIdsToPhases(course.phases) : []);
      setPatients(course.patients || []);
      setDefaultInterval(course.defaultInterval || 7);
      setOngoingPhase(
        course.ongoingPhase === undefined ? null : course.ongoingPhase
      );
      setCurrentPhase(course.ongoingPhase || 0);
      setTherapyCourseType(course.type || 'group');
      setCompleted(course.completed || false);
      setCompletedDate(course.completedDate || null);
      setTherapistName(course.therapistName || '');
      setLanguage(course.defaultLanguage || getCurrentLanguageCode());
      setDefaultLanguage(course.defaultLanguage || getCurrentLanguageCode());
    }
  }, [status, course]);

  // const { updatePatientCourses, updateError, resetUpdateStatus } =
  //   useUpdatePatientCourses();

  // const { cleanPatientCourses } = useCleanPatientCourses();

  // Prepare data for updating the therapy course
  const updateData = useMemo(() => {
    if (!title || !description || !userProviderData?.id || !email) {
      return null;
    }

    // Get all languages that have content in either title or description
    let languages = Object.keys(title).filter((lang) => !!title[lang]);
    Object.keys(description).forEach((lang) => {
      if (!!description[lang] && !languages.includes(lang)) {
        languages.push(lang);
      }
    });

    // Update phases to include task order for each patient
    const updatedPhases = phases.map((phase) => ({
      ...phase,
      patients: phase.patients.map((patient) => ({
        ...patient,
      })),
    }));

    // Construct update object with all course data
    const updateData: Partial<TherapyCourse> = {
      title,
      description,
      phases: updatedPhases,
      languages,
      modified: Timestamp.now(),
      modifiedBy: email,
      patients,
      defaultInterval,
      type: therapycoursetype,
      ongoingPhase: startImmediately ? 0 : ongoingPhase,
      completed: startImmediately ? false : completed,
      completedDate: startImmediately ? null : completedDate,
      therapistName,
      defaultLanguage,
    };
    return updateData;
  }, [
    title,
    description,
    userProviderData?.id,
    email,
    phases,
    patients,
    defaultInterval,
    therapycoursetype,
    startImmediately,
    ongoingPhase,
    completed,
    completedDate,
    therapistName,
    defaultLanguage,
  ]);

  // Handle form submission for creating/updating therapy course
  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (
        !title ||
        !description ||
        !userProviderData?.id ||
        !email ||
        !updateData
      ) {
        return;
      }
      setUpdating(true);
      setUpdateSuccessful(false);
      let languages = Object.keys(title).filter((lang) => !!title[lang]);
      Object.keys(description).forEach((lang) => {
        if (!!description[lang] && !languages.includes(lang)) {
          languages.push(lang);
        }
      });

      // Update existing course or create new one
      if (therapyCourseId && therapyCourseId !== 'new') {
        // Update existing course
        const therapyCourseRef = doc(
          firestore,
          'ServiceProvider',
          userProviderData.id,
          'UserData',
          email,
          'TherapyCourse',
          therapyCourseId
        );
        updateDoc(therapyCourseRef, updateData)
          .then(async () => {
            // const therapyCourse = {
            //   ...updateData,
            //   id: therapyCourseId,
            // } as TherapyCourse;
            // console.log('updatePatientCourses', therapyCourse, patients);
            // updatePatientCourses(therapyCourse, patients).then(() => {
            //   console.log('cleanPatientCourses');
            //   // cleanPatientCourses();
            // });
            setUpdating(false);
            setUpdateSuccessful(true);
          })
          .catch((error) => {
            console.error('Error updating document: ', error);
            setUpdating(false);
          });
      } else {
        // Create new course
        const addData = { ...course, ...updateData };
        const TherapyCourseRef = collection(
          firestore,
          'ServiceProvider',
          userProviderData.id,
          'UserData',
          email,
          'TherapyCourse'
        );
        addDoc(TherapyCourseRef, addData)
          .then(async (newDocRef) => {
            // const therapyCourse = {
            //   ...addData,
            //   id: newDocRef.id,
            // } as TherapyCourse;
            // updatePatientCourses(therapyCourse, patients).then(() => {
            //   cleanPatientCourses();
            // });
            setUpdating(false);
            setUpdateSuccessful(true);
            navigate(`/therapycourses/${newDocRef.id}`);
          })
          .catch((error) => {
            console.error('Error adding document: ', error);
            setUpdating(false);
          });
      }
    },
    [
      title,
      description,
      userProviderData?.id,
      email,
      updateData,
      therapyCourseId,
      firestore,
      course,
      navigate,
    ]
  );

  const onChangeTherapistName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (therapistName !== value) {
        setTherapistName(value);
      }
    },
    [therapistName]
  );

  const onChangeDefaultInterval = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(e.target.value, 10);
      if (defaultInterval !== value) {
        setDefaultInterval(value);
      }
    },
    [defaultInterval]
  );

  // Update patients in all phases when patient list changes
  const updatePhasePatients = useCallback(
    (newPatients: DocumentReference<Patient, DocumentData>[]) => {
      setPhases((phases) => {
        return phases.map((phase) => {
          const newPhasePatients = newPatients.map((patient) => {
            const existingPatient = phase.patients.find(
              (p) => p.id === patient.id
            );
            return {
              id: patient.id,
              patient: patient,
              activeTasks: existingPatient?.activeTasks || {},
              taskOrder: existingPatient?.taskOrder || {},
            };
          });
          return {
            ...phase,
            patients: newPhasePatients,
          };
        });
      });
    },
    []
  );

  // const onChangePatients = useCallback(
  //   (event: React.SyntheticEvent, newValue: any) => {
  //     if (userProviderData?.id && email) {
  //       if (therapycoursetype === 'individual') {
  //         if (!newValue) {
  //           setPatients([]);
  //           return;
  //         }
  //         setPatients([
  //           doc(
  //             firestore,
  //             'ServiceProvider',
  //             userProviderData.id,
  //             'UserData',
  //             email,
  //             'Patient',
  //             newValue.id
  //           ) as DocumentReference<Patient>,
  //         ]);
  //         return;
  //       }
  //       const newPatients: DocumentReference<Patient, DocumentData>[] =
  //         newValue.map(
  //           (v: { id: string; label: string }) =>
  //             doc(
  //               firestore,
  //               'ServiceProvider',
  //               userProviderData.id,
  //               'UserData',
  //               email,
  //               'Patient',
  //               v.id
  //             ) as DocumentReference<Patient, DocumentData>
  //         );
  //       setPatients(newPatients);
  //       updatePhasePatients(newPatients);
  //     }
  //   },
  //   [
  //     userProviderData?.id,
  //     email,
  //     therapycoursetype,
  //     firestore,
  //     updatePhasePatients,
  //   ]
  // );

  const handleStartImmediately = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStartImmediately(event.target.checked);
  };

  const created = course?.created?.toDate();
  const modified = course?.modified?.toDate();

  const taskPoolOptions = useTaskPoolOptions(
    taskPoolData,
    categoryData,
    portalUserData
  );
  const titleExistsInPrimaryLanguage = !!title[defaultLanguage];

  const handleChangeTherapyCourseType = useCallback(
    (event: React.MouseEvent<HTMLElement>, newTherapyCourseType: string) => {
      if (newTherapyCourseType) {
        setTherapyCourseType(newTherapyCourseType as 'group' | 'individual');
      }
    },
    []
  );

  const changeTherapyCourseTypeDisabled = useMemo(() => {
    return (
      ongoingPhase !== null ||
      completed ||
      (therapycoursetype === 'group' && patients.length > 1)
    );
  }, [completed, ongoingPhase, patients.length, therapycoursetype]);

  const [enabled] = useStrictDroppable(status !== 'success');

  const [tabsKey, setTabsKey] = useState(0);

  // Handle drag and drop reordering of phases
  const onDragEnd = useCallback(
    (result: any) => {
      if (!result.destination) {
        return;
      }

      const scrollPosition = window.scrollY;

      requestAnimationFrame(() => {
        // Reorder phases array based on drag result
        const newPhases = Array.from(phases);
        const [reorderedItem] = newPhases.splice(result.source.index, 1);
        newPhases.splice(result.destination.index, 0, reorderedItem);

        setPhases(newPhases);
        setCurrentPhase(result.destination.index);
        setTabsKey((prevKey) => prevKey + 1);

        window.scrollTo(0, scrollPosition);
      });
    },
    [phases]
  );

  // Update useEffect to use new validation
  useEffect(() => {
    const validation = validatePhases(phases);
    setPhaseValidation(validation);
  }, [phases]);

  if (!userProviderData || !taskPoolData || !categoryData || !taskPoolOptions) {
    return <CircularProgress />;
  }
  return (
    <>
      {status === 'loading' && <CircularProgress sx={{ mt: 2 }} />}
      {error && <Alert severity="error">{error.message}</Alert>}
      {collectionError && (
        <Alert severity="error">{collectionError.message}</Alert>
      )}
      {/* {updateError && (
        <Alert severity="error" onClose={() => resetUpdateStatus()}>
          {updateError.message}
        </Alert>
      )} */}
      {taskPoolError && <Alert severity="error">{taskPoolError.message}</Alert>}
      {userProviderError && (
        <Alert severity="error">{userProviderError.message}</Alert>
      )}
      {patientCollectionError && (
        <Alert severity="error">{patientCollectionError.message}</Alert>
      )}
      {status === 'success' && (
        <>
          <Box
            sx={{
              mt: 2,
              mb: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h4" component="h1">
              {t('Therapy Course Editor')}
            </Typography>
            {therapyCourseId !== 'new' && (
              <MuiLink
                component={Link}
                to={`/therapycourseoverview/${therapyCourseId}`}
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  fontSize: '0.875rem',
                  ml: 2,
                }}
              >
                <LinkIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                {t('Session View')}
              </MuiLink>
            )}
          </Box>
          <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
            <Card sx={{ mt: 2 }} variant="outlined">
              <CardHeader
                title={t('Basics')}
                subheader={
                  courseIsActive
                    ? t('Active Therapy Course')
                    : t('Inactive Therapy Course')
                }
                action={
                  <LanguageSelector
                    language={language}
                    defaultLanguage={defaultLanguage}
                    setLanguage={setLanguage}
                    setDefaultLanguage={setDefaultLanguage}
                  />
                }
              />
              <CardContent>
                <ToggleButtonGroup
                  value={therapycoursetype || 'group'}
                  exclusive
                  color="primary"
                  onChange={handleChangeTherapyCourseType}
                  sx={{ marginBottom: '1em' }}
                >
                  <ToggleButton
                    value="group"
                    disabled={changeTherapyCourseTypeDisabled}
                  >
                    <Trans>Group Therapy</Trans>
                  </ToggleButton>
                  <ToggleButton
                    value="individual"
                    disabled={changeTherapyCourseTypeDisabled}
                  >
                    <Trans>Individual Therapy</Trans>
                  </ToggleButton>
                </ToggleButtonGroup>
                <TranslatableTextField
                  key={`course-title-${language}`}
                  name={`course-title-${language}`}
                  label={t('Therapy Course Name')}
                  helperText={
                    !titleExistsInPrimaryLanguage
                      ? t('Name is required in primary language')
                      : undefined
                  }
                  error={!titleExistsInPrimaryLanguage}
                  language={language}
                  type="title"
                  setValue={setTitle}
                  value={title}
                  placeholder={t('Enter Name')}
                />
                <TextField
                  key={`course-therapist-name`}
                  name="therapist-name"
                  label={t("Therapist's Name")}
                  placeholder={t('Enter Name')}
                  value={therapistName}
                  onChange={onChangeTherapistName}
                  fullWidth
                  sx={{ mt: 2 }}
                />
                <TranslatableEditor
                  key={`course-description-${language}`}
                  name={`course-description-${language}`}
                  placeholder={t('Description')}
                  value={description}
                  setValue={setDescription}
                  language={language}
                  type="description"
                />
                <Tooltip
                  title={t(
                    'The number of days that will be automatically added to the start date of each phase.'
                  )}
                >
                  <TextField
                    label={t('Default Interval (Days)')}
                    name="default-interval"
                    type="number"
                    value={defaultInterval}
                    onChange={onChangeDefaultInterval}
                    required
                    sx={{ mt: 2 }}
                  />
                </Tooltip>
              </CardContent>
            </Card>
            <Card sx={{ mt: 2 }} variant="outlined">
              <CardHeader
                title={t('Patients')}
                subheader={t('Select the patients for the group')}
              />
              <CardContent>
                <PatientManager
                  patients={patients}
                  setPatients={setPatients}
                  email={email}
                  isGroup={therapycoursetype === 'group'}
                  onPatientChange={updatePhasePatients}
                  disabled={courseIsCompleted}
                />
              </CardContent>
            </Card>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                backgroundColor: 'background.paper',
                mt: 2,
              }}
            >
              <DragDropContext onDragEnd={onDragEnd}>
                {enabled && (
                  <Droppable droppableId="phases" direction="horizontal">
                    {(provided) => (
                      <Tabs
                        key={tabsKey} // Force redraw after reordering
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        aria-label="sessions"
                        value={currentPhase}
                        variant="scrollable"
                        sx={{ overflowX: 'auto' }}
                      >
                        {phases.map((phase, index) => (
                          <Draggable
                            key={phase.id}
                            draggableId={phase.id}
                            index={index}
                          >
                            {(provided) => (
                              <Tab
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                sx={{
                                  fontWeight:
                                    ongoingPhase === index ? 'bold' : 'normal',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'flex-start',
                                  paddingLeft: 1,
                                  color:
                                    phaseValidation.dateErrorPhases.includes(
                                      index
                                    ) ||
                                    phaseValidation.titleErrorPhases.includes(
                                      index
                                    ) ||
                                    phaseValidation.taskErrorPhases.includes(
                                      index
                                    )
                                      ? 'error.main'
                                      : 'inherit',
                                  '& .MuiSvgIcon-root': {
                                    color:
                                      phaseValidation.dateErrorPhases.includes(
                                        index
                                      ) ||
                                      phaseValidation.titleErrorPhases.includes(
                                        index
                                      ) ||
                                      phaseValidation.taskErrorPhases.includes(
                                        index
                                      )
                                        ? 'error.main'
                                        : 'inherit',
                                  },
                                }}
                                label={
                                  <>
                                    <span
                                      {...provided.dragHandleProps}
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'grab',
                                      }}
                                      data-drag-handle
                                    >
                                      <DragIndicatorIcon />
                                    </span>
                                    {`${t('Phase')} ${index + 1}`}
                                    {(phaseValidation.dateErrorPhases.includes(
                                      index
                                    ) ||
                                      phaseValidation.titleErrorPhases.includes(
                                        index
                                      ) ||
                                      phaseValidation.taskErrorPhases.includes(
                                        index
                                      )) && (
                                      <Tooltip
                                        title={
                                          <>
                                            {phaseValidation.dateErrorPhases.includes(
                                              index
                                            ) && t('Erroneous date')}
                                            {phaseValidation.titleErrorPhases.includes(
                                              index
                                            ) && t('Missing title')}
                                            {phaseValidation.titleErrorPhases.includes(
                                              index
                                            ) &&
                                              phaseValidation.taskErrorPhases.includes(
                                                index
                                              ) &&
                                              ' & '}
                                            {phaseValidation.taskErrorPhases.includes(
                                              index
                                            ) && t('No tasks assigned')}
                                          </>
                                        }
                                      >
                                        <WarningIcon
                                          fontSize="small"
                                          sx={{ ml: 1 }}
                                        />
                                      </Tooltip>
                                    )}
                                  </>
                                }
                                {...a11yProps(index)}
                                onClick={(event) => {
                                  event.preventDefault();
                                  // Check if the click originated from the drag handle
                                  if (
                                    !(event.target as HTMLElement).closest(
                                      '[data-drag-handle]'
                                    )
                                  ) {
                                    setCurrentPhase(index);
                                  }
                                }}
                              />
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                        <Tab
                          label={t('Add Phase')}
                          {...a11yProps(phases.length)}
                          onClick={() => {
                            // if first phase, startDate is null, otherwise startDate is startDate of previous phase plus defaultInterval at defaultSessionTime
                            const previousPhase =
                              phases.length > 0
                                ? phases[phases.length - 1]
                                : null;
                            const startDate =
                              phases.length === 0
                                ? null
                                : previousPhase?.startDate
                                ? Timestamp.fromDate(
                                    dayjs(previousPhase!.startDate!.toDate())
                                      .add(defaultInterval, 'days')
                                      .set('hour', 0)
                                      .set('minute', 0)
                                      .toDate()
                                  )
                                : null;
                            // end date is startDate plus defaultInterval
                            const endDate = startDate
                              ? Timestamp.fromDate(
                                  dayjs(startDate!.toDate())
                                    .add(defaultInterval, 'days')
                                    .toDate()
                                )
                              : null;
                            const newId = uuid.v4() as string;

                            setPhases((phases) => [
                              ...phases,
                              {
                                id: newId,
                                title: { [defaultLanguage]: '' },
                                description: { [defaultLanguage]: '' },
                                startDate,
                                endDate,
                                order: phases.length,
                                patients: patients.map((patient) => ({
                                  id: patient.id,
                                  patient: patient,
                                  activeTasks: {},
                                })) as TherapyCoursePhasePatient[],
                                phaseTasks:
                                  [] as DocumentReference<TaskPoolTask>[],
                              },
                            ]);
                            setCurrentPhase(phases.length);
                          }}
                        />
                      </Tabs>
                    )}
                  </Droppable>
                )}
              </DragDropContext>
            </Box>

            <Card elevation={0}>
              <CardHeader
                title={
                  phases.length > 0
                    ? t('Phase') + ' ' + (currentPhase + 1)
                    : t('')
                }
                subheader={
                  ongoingPhase !== null
                    ? ongoingPhase === currentPhase
                      ? t('Ongoing')
                      : ongoingPhase < currentPhase
                      ? t('Upcoming phase')
                      : t('Completed phase')
                    : courseIsCompleted
                    ? t('Completed course')
                    : t('Not started course')
                }
                action={
                  <IconButton
                    disabled={
                      ongoingPhase === currentPhase || phases.length <= 1
                    }
                    onClick={() => {
                      // we need to move the ongoingPhase to the previous phase, if the phase being deleted
                      // is before the ongoingPhase, because it is an index, not an id
                      if (ongoingPhase && ongoingPhase > currentPhase) {
                        setOngoingPhase(ongoingPhase - 1);
                      }
                      // if phase being deleted is the last one, set currentPhase to last phase - 1
                      if (currentPhase === phases.length - 1) {
                        setCurrentPhase(phases.length - 2);
                      }
                      // finally, remove the phase from the phases array
                      setPhases((phases) =>
                        phases.filter((_, i) => i !== currentPhase)
                      );
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              />
              <CardContent>
                {phases[currentPhase] && (
                  <TherapyCourseEditorPhase
                    key={`tcep-${phases[currentPhase].id}`}
                    phases={phases}
                    currentPhase={currentPhase}
                    language={language}
                    setPhases={setPhases}
                    taskPoolOptions={taskPoolOptions || []}
                    patients={patients}
                    patientCollectionData={patientCollectionData}
                    ongoingPhase={ongoingPhase}
                  />
                )}
              </CardContent>
            </Card>
            {(phaseValidation.hasDateError ||
              phaseValidation.hasTitleError ||
              phaseValidation.hasTaskError) && (
              <Box sx={{ mt: 1 }}>
                {phaseValidation.hasDateError && (
                  <Alert severity="warning" sx={{ mb: 1 }}>
                    <Trans>
                      Warning: Some phases are not in chronological order.
                      Please check the dates of the highlighted phases.
                    </Trans>
                  </Alert>
                )}
                {phaseValidation.hasTitleError && (
                  <Alert severity="warning" sx={{ mb: 1 }}>
                    <Trans>
                      Warning: Some phases are missing titles. Please add titles
                      to all phases.
                    </Trans>
                  </Alert>
                )}
                {phaseValidation.hasTaskError && (
                  <Alert severity="warning" sx={{ mb: 1 }}>
                    <Trans>
                      Warning: Some phases have no tasks assigned. Please add at
                      least one task to each phase.
                    </Trans>
                  </Alert>
                )}
              </Box>
            )}
            {updateSuccessful && (
              <Alert
                severity="success"
                sx={{ mt: 2 }}
                onClose={() => setUpdateSuccessful(false)}
              >
                <Trans>Save successful</Trans>
              </Alert>
            )}
            {ongoingPhase === null && (
              <Card elevation={0} sx={{ mt: 2 }}>
                <CardHeader title={t('Options')} />
                <CardContent>
                  {completedDate && (
                    <TextField
                      name="completed-date"
                      label={t('Completed Date')}
                      placeholder={t('Choose Completed Date')}
                      value={completedDate.toDate().toLocaleDateString()}
                      disabled
                      fullWidth
                    />
                  )}
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={startImmediately}
                          onChange={handleStartImmediately}
                        />
                      }
                      label={
                        completed
                          ? t('Restart course')
                          : t('Start course immediately')
                      }
                    />
                  </FormGroup>
                </CardContent>
              </Card>
            )}
            <LoadingButton
              loading={updating}
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
              disabled={
                updating ||
                !titleExistsInPrimaryLanguage ||
                (completed && !startImmediately) ||
                phaseValidation.hasDateError ||
                phaseValidation.hasTitleError ||
                phaseValidation.hasTaskError
              }
            >
              {t('Save')}
            </LoadingButton>
            <Table
              sx={{ minWidth: 650, mt: 2 }}
              size="small"
              aria-label="info table"
              padding="none"
            >
              <TableBody
                sx={{
                  '& .MuiTableCell-root': {
                    borderBottom: 0,
                  },
                }}
              >
                {therapyCourseId !== 'new' && created && (
                  <TableRow>
                    <TableCell
                      sx={{ minWidth: 80, borderBottom: 0 }}
                      size="small"
                      variant="head"
                    >
                      {t('Created')}
                    </TableCell>
                    <TableCell
                      sx={{ minWidth: 130, width: '100%', borderBottom: 0 }}
                      size="small"
                    >
                      {created.toLocaleString()}
                    </TableCell>
                  </TableRow>
                )}
                {modified && (
                  <TableRow>
                    <TableCell
                      size="small"
                      variant="head"
                      sx={{ borderBottom: 0 }}
                    >
                      {t('Modified')}
                    </TableCell>
                    <TableCell sx={{ borderBottom: 0 }} size="small">
                      {modified.toLocaleString()}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </>
      )}
    </>
  );
}
