import { useCallback, useEffect, useState } from 'react';
import { useAuth, useSigninCheck } from 'reactfire';
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
} from 'firebase/auth';
import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
// import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Copyright from '../components/Copyright';
import SVGLogo from '../components/SVGLogo';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';

type TLoginForm = {
  email: string;
  password: string;
  rememberMe: boolean;
};

/**
 * The login page. This is the page that is displayed when a user is not logged in.
 *
 * @returns JSX.Element
 */
export default function Login() {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { status: authStatus, data: signInCheckResult } = useSigninCheck();
  const auth = useAuth();
  const [error, setError] = useState<Error | null>(null);
  const [loggingIn, setLoggingIn] = useState(false);

  const { register, handleSubmit } = useForm<TLoginForm>();

  // If the user is already logged in, redirect to the dashboard
  useEffect(() => {
    if (signInCheckResult?.signedIn) {
      navigate('/');
    }
  }, [navigate, signInCheckResult?.signedIn]);

  // Handle the form submission

  const onSubmit: SubmitHandler<TLoginForm> = useCallback(
    async (data) => {
      setLoggingIn(true);
      const { email, password, rememberMe } = data;
      const normalizedEmail = email.trim().toLowerCase();
      setError(null);
      setPersistence(
        auth,
        rememberMe ? browserLocalPersistence : browserSessionPersistence
      )
        .then(() => {
          signInWithEmailAndPassword(auth, normalizedEmail, password)
            .then((userCredential) => {
              // Signed in
              setLoggingIn(false);
            })
            .catch((error) => {
              setError(error);
              setLoggingIn(false);
            });
        })
        .catch((error) => {
          setError(error);
          setLoggingIn(false);
        });
    },
    [auth]
  );

  if (!signInCheckResult) {
    return <CircularProgress />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <SVGLogo
          style={{
            width: '334px',
            height: '58px',
            marginBottom: '1.125rem',
          }}
          fill={palette.primary.main}
        />
        <Avatar sx={{ m: 1, bgcolor: palette.secondary.main }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h5" sx={{ mt: 1 }} color="text.secondary">
          {t('Sign In')}
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            label={t('Email Address')}
            placeholder={t('Enter Email Address')}
            autoComplete="email"
            autoFocus
            {...register('email')}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label={t('Password')}
            placeholder={t('Enter Password')}
            type="password"
            autoComplete="current-password"
            {...register('password')}
          />
          <FormControlLabel
            control={
              <Checkbox
                value="rememberMe"
                color="primary"
                {...register('rememberMe')}
              />
            }
            label={t('Remember me')}
          />
          {error && <Alert severity="error">{t(error.message)}</Alert>}
          <LoadingButton
            type="submit"
            loading={authStatus === 'loading' || loggingIn}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t('Sign In')}
          </LoadingButton>
          <Grid container>
            <Grid item xs>
              <Link href="/forgotpassword" variant="body2">
                {t('Forgot password?')}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
