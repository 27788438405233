import {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import {
  TaskPoolTaskSection,
  TaskPoolTaskSectionType,
  TranslatableField,
} from '../firebase/firebaseModels';
import { CardHeader } from '@mui/material';
import { SaveFuncParams, SectionEditorRef } from '../pages/TaskPoolTaskEditor';
import TranslatableEditor from './TranslatableEditor';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import TranslatableTextField from './TranslatableTextField';

type Props = {
  disabled?: boolean;
  section: TaskPoolTaskSection;
  language: string;
  defaultLanguage: string;
  onDelete: () => void;
};

/**
 * Editing the TaskPoolTask Text section.
 */
const TaskPoolTaskTextSectionEditor = forwardRef<SectionEditorRef, Props>(
  (props: Props, reactRef) => {
    const { t } = useTranslation();
    const { disabled, section, language, defaultLanguage } = props;

    const [content, setContent] = useState<TranslatableField>({
      [defaultLanguage]: '',
    });

    const [title, setTitle] = useState<TranslatableField>({
      [defaultLanguage]: '',
    });

    useEffect(() => {
      if (section.content) {
        // Handle both string and TranslatableField types for content
        if (typeof section.content === 'string') {
          setContent({ [defaultLanguage]: section.content });
        } else {
          setContent(section.content);
        }
      }
      if (section.title) {
        setTitle(section.title);
      }
    }, [section, defaultLanguage]);

    const saveFunc = useCallback(async (): Promise<SaveFuncParams> => {
      const newSection = {
        ...section,
        content,
        title,
      };

      return { success: true, message: 'Text section saved', newSection };
    }, [section, content, title]);

    useImperativeHandle(reactRef, () => ({
      sectionType: 'text' as TaskPoolTaskSectionType,
      save: saveFunc,
    }));

    const onDeleteSection = useCallback(() => {
      props.onDelete();
    }, [props]);

    return (
      <Card variant="outlined">
        <CardHeader
          title={
            <Box display="flex" alignItems="center">
              <DragIndicatorIcon sx={{ mr: 1 }} />
              {t('Text Only')}
            </Box>
          }
          action={
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={onDeleteSection}
              disabled={disabled}
            >
              {t('Delete')}
            </Button>
          }
        />
        <CardContent>
          <TranslatableTextField
            key={`text-title-${language}-${section.id}`}
            name={`text-title-${language}-${section.id}`}
            disabled={disabled}
            label={t('Text Section Title')}
            language={language}
            type="title"
            setValue={setTitle}
            value={title}
            placeholder={t('Enter Title')}
          />
          <TranslatableEditor
            key={`text-content-${language}-${section.id}`}
            name={`text-content-${language}-${section.id}`}
            disabled={disabled}
            placeholder={t('Text Content')}
            value={content}
            setValue={setContent}
            language={language}
            type="content"
          />
        </CardContent>
      </Card>
    );
  }
);

export default TaskPoolTaskTextSectionEditor;
