import { useTranslation } from 'react-i18next';
import { Typography, Button } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import {
  StatisticDay,
  StatisticsRowDataType,
  statisticTableLabelsAndKeys,
} from './types';
import { calculateRowData } from './calculateRowData';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { StatisticsTableCell } from './StatisticsTableCell';
import Box from '@mui/material/Box';
import { GridToolbar } from '@mui/x-data-grid';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Patient } from '../../firebase/firebaseModels';
import { minutesToHoursAndMinutes } from '../../utils';
import { localeFormatTime } from '../../utils';
import { GridGetRowsToExportParams } from '@mui/x-data-grid';

type StatisticsTableTabProps = {
  statisticsSlice: StatisticDay[] | null;
  fromDateString?: string;
  toDateString?: string;
  maxDateAsStr: string;
  patient?: Patient;
};

export default function StatisticTableTab(props: StatisticsTableTabProps) {
  const { t, i18n } = useTranslation();
  const {
    statisticsSlice,
    fromDateString,
    toDateString,
    maxDateAsStr,
    patient,
  } = props;
  const [copied, setCopied] = useState(false);
  const [copiedHtml, setCopiedHtml] = useState(false);

  const statisticTableData: StatisticsRowDataType[] = useMemo(() => {
    if (!statisticsSlice) {
      return [];
    }
    return statisticTableLabelsAndKeys.map(
      ({ label, key, type, dismissZero, statisticsGroup }, idx) => {
        return {
          id: `statistic-${idx}`,
          label: t(label),
          statisticsGroup,
          type,
          rowNr: idx + 1,
          ...calculateRowData(
            type,
            statisticsSlice,
            key,
            maxDateAsStr,
            dismissZero
          ),
        };
      }
    );
  }, [maxDateAsStr, statisticsSlice, t]);

  const columns: GridColDef<StatisticsRowDataType>[] = useMemo(
    () =>
      [
        {
          field: 'rowNr',
          width: 70,
          headerName: t('Row #'),
        },
        {
          field: 'label',
          headerName: t('Label'),
          flex: 1,
        },
        {
          field: 'min',
          headerName: t('Min'),
          width: 90,
          renderCell: ({ row, colDef }) => {
            const { label, type, min, max, average, nValues } = row;
            return (
              <StatisticsTableCell
                key={`${row.id}-min`}
                type={type}
                label={label}
                index={0}
                min={min}
                max={max}
                average={average}
                nValues={nValues}
                colType="min"
                colDef={colDef}
              />
            );
          },
        },
        {
          field: 'max',
          headerName: t('Max'),
          width: 90,
          renderCell: ({ row, colDef }) => {
            const { label, type, min, max, average, nValues } = row;
            return (
              <StatisticsTableCell
                key={`${row.id}-max`}
                type={type}
                label={label}
                index={0}
                min={min}
                max={max}
                average={average}
                nValues={nValues}
                colType="max"
                colDef={colDef}
              />
            );
          },
        },
        {
          field: 'average',
          headerName: t('Average'),
          width: 90,
          renderCell: ({ row, colDef }) => {
            const { label, type, min, max, average, nValues } = row;
            return (
              <StatisticsTableCell
                key={`${row.id}-average`}
                type={type}
                label={label}
                index={0}
                min={min}
                max={max}
                average={average}
                nValues={nValues}
                colType="average"
                colDef={colDef}
              />
            );
          },
        },
        {
          field: 'nValues',
          headerName: t('N Values'),
          width: 90,
          renderCell: ({ value, colDef }) => (
            <Typography
              sx={{ width: colDef.computedWidth, display: 'inline-block' }}
            >
              {value ? `${value}` : t('No data')}
            </Typography>
          ),
        },
      ] as GridColDef<StatisticsRowDataType>[],
    [t]
  );

  const handleCopyStatistics = useCallback(() => {
    let text = '';

    if (patient) {
      text += `${patient.licenceCode}${
        patient.remark ? ` - ${patient.remark}` : ''
      }\n\n`;
    }

    if (fromDateString || toDateString) {
      text += `${t('Date Range')}: ${fromDateString || t('Start')} - ${
        toDateString || t('End')
      }\n\n`;
    }

    text += statisticTableData
      .map((row) => {
        const formatValue = (value: number | Date | null) => {
          if (value === null) return t('No data');

          switch (row.type) {
            case 'time':
              return localeFormatTime(i18n.language, value);
            case 'duration':
              return minutesToHoursAndMinutes(value as number);
            case 'percentage':
              return `${Math.round(value as number)}%`;
            default:
              return value?.toString() || t('No data');
          }
        };

        return (
          `${row.label}:\n` +
          `${t('Min')}: ${formatValue(row.min)}\n` +
          `${t('Max')}: ${formatValue(row.max)}\n` +
          `${t('Average')}: ${formatValue(row.average ?? null)}\n` +
          `${t('N Values')}: ${row.nValues ?? t('No data')}\n`
        );
      })
      .join('\n');

    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  }, [
    statisticTableData,
    t,
    i18n.language,
    fromDateString,
    toDateString,
    patient,
  ]);

  const handleCopyStatisticsAsHtml = useCallback(() => {
    let html = '';

    // Add patient info and date range if available, outside the table
    if (patient) {
      html += `<div style="margin-bottom: 8px;">${patient.licenceCode}${
        patient.remark ? ` - ${patient.remark}` : ''
      }</div>`;
    }
    if (fromDateString || toDateString) {
      html += `<div style="margin-bottom: 16px;">${t('Date Range')}: ${
        fromDateString || t('Start')
      } - ${toDateString || t('End')}</div>`;
    }

    html += '<table style="border-collapse: collapse; width: 100%;">';

    // Add header
    html += '<tr>';
    columns.forEach((col) => {
      html += `<th style="border: 1px solid #ddd; padding: 8px; text-align: left;">${col.headerName}</th>`;
    });
    html += '</tr>';

    // Add data rows
    statisticTableData.forEach((row) => {
      html += '<tr>';

      // Row number
      html += `<td style="border: 1px solid #ddd; padding: 8px;">${row.rowNr}</td>`;

      // Label
      html += `<td style="border: 1px solid #ddd; padding: 8px;">${row.label}</td>`;

      const formatValue = (value: number | Date | null) => {
        if (value === null) {
          return t('No data');
        }

        switch (row.type) {
          case 'time':
            return localeFormatTime(i18n.language, value);
          case 'duration':
            return minutesToHoursAndMinutes(value as number);
          case 'percentage':
            return `${Math.round(value as number)}%`;
          default:
            return value?.toString() || t('No data');
        }
      };

      // Min, Max, Average, N Values
      html += `<td style="border: 1px solid #ddd; padding: 8px;">${formatValue(
        row.min
      )}</td>`;
      html += `<td style="border: 1px solid #ddd; padding: 8px;">${formatValue(
        row.max
      )}</td>`;
      html += `<td style="border: 1px solid #ddd; padding: 8px;">${formatValue(
        row.average ?? null
      )}</td>`;
      html += `<td style="border: 1px solid #ddd; padding: 8px;">${
        row.nValues ?? t('No data')
      }</td>`;

      html += '</tr>';
    });

    html += '</table>';

    // Create a new ClipboardItem with both HTML and text formats
    const clipboardItem = new ClipboardItem({
      'text/html': new Blob([html], { type: 'text/html' }),
      'text/plain': new Blob([html], { type: 'text/plain' }),
    });

    // Use the new clipboard.write() API
    navigator.clipboard
      .write([clipboardItem])
      .then(() => {
        setCopiedHtml(true);
        setTimeout(() => setCopiedHtml(false), 2000);
      })
      .catch((err) => {
        console.error('Failed to copy HTML to clipboard:', err);
        // Fallback to the old method if the new API is not supported
        navigator.clipboard.writeText(html).then(() => {
          setCopiedHtml(true);
          setTimeout(() => setCopiedHtml(false), 2000);
        });
      });
  }, [
    statisticTableData,
    columns,
    t,
    i18n.language,
    fromDateString,
    toDateString,
    patient,
  ]);

  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      <Box
        sx={{
          mb: 3,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="subtitle1"
          component="h2"
          sx={{ color: 'text.secondary' }}
        >
          {t('Statistics')}
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            startIcon={copied ? '✓' : <ContentCopyIcon />}
            onClick={handleCopyStatistics}
            disabled={!statisticTableData.length}
            variant="outlined"
            size="small"
            color={copied ? 'success' : 'primary'}
          >
            {copied ? t('Copied!') : t('Copy as Simple Text')}
          </Button>
          <Button
            startIcon={copiedHtml ? '✓' : <ContentCopyIcon />}
            onClick={handleCopyStatisticsAsHtml}
            disabled={!statisticTableData.length}
            variant="outlined"
            size="small"
            color={copiedHtml ? 'success' : 'primary'}
          >
            {copiedHtml ? t('Copied!') : t('Copy as Rich Text')}
          </Button>
        </Box>
      </Box>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'rowNr', sort: 'asc' }],
          },
        }}
        autoHeight
        density="compact"
        columns={columns}
        rows={statisticTableData}
        hideFooter
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        rowSelection={false}
        sx={{
          width: '100%',
          '@media print': {
            '.MuiDataGrid-main': {
              color: 'rgba(0, 0, 0, 0.87)',
            },
          },
        }}
        slots={{
          toolbar: GridToolbar,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            printOptions: {
              disableToolbarButton: false,
              hideFooter: true,
              hideToolbar: true,
              getRowsToExport: (params: GridGetRowsToExportParams) => {
                return statisticTableData.map((row) => row.id);
              },
            },
          },
        }}
      />
    </Box>
  );
}
