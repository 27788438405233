import { useEffect, useState } from 'react';
import { useFirestore } from 'reactfire';
import {
  collection,
  onSnapshot,
  query,
  where,
  and,
  QueryCompositeFilterConstraint,
} from 'firebase/firestore';
import { TherapyCourse } from './firebaseModels';
import useUserProvider from './useUserProvider';
import { useFirebaseUser } from './useFirebaseUser';
import usePatientCollection from './usePatientCollection';

type TTherapyCourseDataOptions = {
  activeOnly: boolean;
};

type TTherapyCourseData = {
  status: 'loading' | 'success' | 'error';
  error: Error | null;
  data: TherapyCourse[] | null;
  options?: TTherapyCourseDataOptions;
};

export default function useTherapyCourseCollection(
  type: 'group' | 'individual' | 'all', // 'group' | 'individual' | null
  activeOnly = true,
  waitingOnly = false
): TTherapyCourseData {
  const firestore = useFirestore();
  const [error, setError] = useState<Error | null>(null);
  const { email } = useFirebaseUser();
  const { status: patientCollectionStatus, isPatientValidById } =
    usePatientCollection();

  const { data: userProviderData, status: userProviderStatus } =
    useUserProvider();

  const [status, setStatus] = useState<TTherapyCourseData['status']>('loading');
  const [data, setData] = useState<TTherapyCourseData['data']>(null);
  const [nTimeouts, setNTimeouts] = useState(0);
  useEffect(() => {
    if (
      !userProviderData?.id ||
      !email ||
      userProviderStatus !== 'success' ||
      patientCollectionStatus !== 'success'
    ) {
      return;
    }
    const collectionRef = collection(
      firestore,
      'ServiceProvider',
      userProviderData.id,
      'UserData',
      email,
      'TherapyCourse'
    );

    let queryConstraints: QueryCompositeFilterConstraint | null = null;

    switch (type) {
      case 'group':
        queryConstraints = activeOnly
          ? and(
              where('type', '==', 'group'),
              where('completed', '==', false),
              where('ongoingPhase', '>=', 0)
            )
          : waitingOnly
          ? and(
              where('type', '==', 'group'),
              where('completed', '==', false),
              where('ongoingPhase', '==', null)
            )
          : and(where('type', '==', 'group'));
        break;
      case 'individual':
        queryConstraints = activeOnly
          ? and(
              where('type', '==', 'individual'),
              where('completed', '==', false),
              where('ongoingPhase', '>=', 0)
            )
          : waitingOnly
          ? and(
              where('type', '==', 'individual'),
              where('completed', '==', false),
              where('ongoingPhase', '==', null)
            )
          : and(where('type', '==', 'individual'));

        break;
      default:
        queryConstraints = activeOnly
          ? and(
              where('completed', '==', false),
              where('ongoingPhase', '!=', null)
            )
          : waitingOnly
          ? and(
              where('completed', '==', false),
              where('ongoingPhase', '==', null)
            )
          : null;
    }

    let queryRef = queryConstraints
      ? query(collectionRef, queryConstraints)
      : query(collectionRef);

    const unsubscribe = onSnapshot(
      queryRef,
      (snapshot) => {
        const therapyCourseData: TherapyCourse[] = [];
        snapshot.forEach((doc) => {
          setNTimeouts(0);
          const docData = doc.data() as TherapyCourse;
          // const docType = docData.type || 'group';
          // if (type !== null && docType !== type) {
          //   return;
          // }
          if (docData.status === 'deleted') {
            return;
          }
          // filter out invalid patients
          const validPatients = docData.patients?.filter((patient) =>
            isPatientValidById(patient?.id)
          );
          // filter out patients whose
          const TherapyCourse = {
            id: doc.id,
            type: 'group',
            ongoingPhase: null,
            ...docData,
            patients: validPatients,
          } as TherapyCourse;
          therapyCourseData.push(TherapyCourse);
        });
        setData(therapyCourseData);
        setStatus('success');
      },
      (error) => {
        console.error('Error getting documents: ', error);
        setStatus('error');
        setError(error);

        // Incremental backoff for retrying
        const retryDelay = 1000 * (nTimeouts + 1);
        setTimeout(() => {
          console.log('useTherapyCourseCollection retrying after delay');
          setNTimeouts((prevTimeouts) => prevTimeouts + 1);
        }, retryDelay);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [
    email,
    firestore,
    activeOnly,
    type,
    userProviderData,
    userProviderStatus,
    setStatus,
    waitingOnly,
    nTimeouts,
    isPatientValidById,
    patientCollectionStatus,
  ]);

  return { status, data, error };
}
