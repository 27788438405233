import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import UserName from '../components/UserName';
import Copyright from '../components/Copyright';
import DrawerMenu from '../components/DrawerMenu';
import AppBar from '../components/AppBar';
import SVGLogo from '../components/SVGLogo';
import { Outlet, useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';
import BreadcrumbTrail from '../components/BreadcrumbTrail';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';

/**
 * The main dashboard page. This is the page that is displayed after a user logs in.
 *
 * @returns  JSX.Element
 */
export default function Dashboard() {
  // The drawer menu state (open/close)
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  // Handle the drawer menu toggle
  const toggleDrawer = useCallback(() => {
    setOpen(!open);
  }, [open]);

  // Handle the logout
  const handleLogout = useCallback(() => {
    navigate('/logout');
  }, [navigate]);

  // Add menu state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  // Add menu handlers
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '13rem',
              pointerEvents: 'none', // Prevents the logo from interfering with other elements
            }}
          >
            <SVGLogo
              style={{
                height: '1.125rem',
                width: '100%',
              }}
              fill="#fff"
            />
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <UserName sx={{ mr: 2 }} />

          <Button
            color="inherit"
            onClick={handleMenuClick}
            aria-controls={menuOpen ? 'help-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen ? 'true' : undefined}
            startIcon={<InfoIcon />}
            sx={{ mr: 1 }}
          >
            {t('Help')}
          </Button>
          <Menu
            id="help-menu"
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleMenuClose}
            MenuListProps={{
              'aria-labelledby': 'help-button',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem
              onClick={handleMenuClose}
              component="a"
              href={t('help-docs.user-guide-url')}
              target="_blank"
            >
              {t('help-docs.user-guide')}
            </MenuItem>
            <MenuItem
              onClick={handleMenuClose}
              component="a"
              href={t('help-docs.admin-guide-url')}
              target="_blank"
            >
              {t('help-docs.admin-guide')}
            </MenuItem>
          </Menu>

          <IconButton color="inherit" onClick={handleLogout}>
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DrawerMenu open={open} toggleDrawer={toggleDrawer} />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <BreadcrumbTrail />
          <Outlet />
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </Box>
  );
}
